import React, { useEffect, useState, useContext } from "react";
import { Select } from 'antd';
import Switch from "./AndOrToggleSwtich";
import BasedFilterWrapper from './BasedFilterWrapper';
import ToggleButton from "./ToggleAndOrSwitch";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import useScrollIntoView from './useScrollIntoView';

const options = [
  { value: "currentRatio", label: "Current Ratio", min: "", max: "", filterKey: "current_ratio" },
  { value: "quickRatio", label: "Quick Ratio", min: "", max: "", filterKey: "quick_ratio" },
  { value: "debtEquityRatio", label: "Debt Equity Ratio", min: "", max: "", filterKey: "debt_equity_ratio" },
  { value: "roe", label: "RoE", min: "", max: "", filterKey: "roe" },
  { value: "roa", label: "RoA", min: "", max: "", filterKey: "roa" },
  { value: "roce", label: "RoCE", min: "", max: "", filterKey: "roce" },
  { value: "assetTurnoverRatio", label: "Asset Turnover Ratio", min: "", max: "", filterKey: "asset_turnover_ratio" },
  { value: "operating_margin", label: "Operating Margin", min: "", max: "", filterKey: "operating_margin" },
  { value: "ebitdaMargin", label: "EBITDA Margin", min: "", max: "", filterKey: "ebitda_margin" },
  { value: "patMargin", label: "PAT Margin", min: "", max: "", filterKey: "pat_margin" },
  { value: "contribution Margin", label: "Contribution Margin", min: "", max: "", filterKey: "contribution_margin" },
];
/**
 * The RatiosBasedFilter component is a JavaScript function that renders a filter component for
 * refining search results based on ratios.
 * @returns The RatiosBasedFilter component is being returned.
 */
const RatiosBasedFilter = ({
  open,
  accordionClick,
  RatioYear,
  setRatioYear,
  RatioYearOptions,
  setRatioYearOptions,
  RatioAndOr,
  setRatioAndOr,
  customPathname,
  label = "RATIOS BASED",
}) => {
  const { refineSearchFilters } = useContext(PeFilterContext);
  const listItemRef = useScrollIntoView(open);

  /* The `useEffect` hook is used to perform side effects in a functional component. In this case, the
  `useEffect` hook is used to update the state variables `RatioYear`, `RatioYearOptions`, and
  `RatioAndOr` based on the values stored in the `refineSearchFilters` context. */
  useEffect(() => {
    const ratioYear = refineSearchFilters[customPathname]?.ratioYear?.at(0)?.value;
    const ratioOptions = refineSearchFilters[customPathname]?.ratioPercentage?.map(option => option.value);
    const ratioAnd = refineSearchFilters[customPathname]?.ratioOperator?.at(0)?.value ?? 'and'
    setRatioYear(ratioYear ?? "any")
    setRatioYearOptions(ratioOptions ?? []);
    setRatioAndOr(ratioAnd);
  }, [customPathname, refineSearchFilters, setRatioAndOr, setRatioYear, setRatioYearOptions])

  /**
   * The function `onRatioYearChange` sets the value of `RatioYear` to the provided `value`.
   * @param value - The value parameter is the new value that will be set for the ratio year.
   */
  const onRatioYearChange = (value) => {
    // setRatioYear(prev => prev === value ? null : value)
    setRatioYear(value)
    // setGrowthOptions([])
  }

  /**
   * The function `onRationOptionChange` updates the ratio year options based on the selected ratios.
   * @param e - The parameter "e" is an event object that represents the event that triggered the
   * change. It could be an input change event, a button click event, or any other type of event.
   * @param selectedRatios - The selectedRatios parameter is a variable that represents the selected
   * ratios in a form or input field. It could be an array, object, or any other data type depending on
   * how the ratios are being stored or retrieved.
   */
  const onRationOptionChange = (e, selectedRatios) => {
    setRatioYearOptions(selectedRatios)
  }


  /**
   * The function `onOptionsYearChange` is used to handle changes in the year options and update the
   * corresponding values in the `RatioYearOptions` array.
   * @param index - The index parameter represents the index of the element in the RatioYearOptions
   * array that needs to be updated.
   * @param optionKey - The `optionKey` parameter represents the key of the option being changed. It
   * can have two possible values: "min" or "max".
   * @param value - The `value` parameter represents the new value that is being entered or selected
   * for the options year.
   * @param key - The `key` parameter is not used in the provided code snippet. It is not clear what
   * its purpose is without further context.
   */
  const onOptionsYearChange = (index, optionKey, value, key) => {
    const validNumber = new RegExp(/^\d*\.?\d*$/);
    let processedValue = "";
    if (validNumber.test(value)) {
      processedValue = value;
    } else {
      processedValue = optionKey === "min" ? RatioYearOptions[index].min : RatioYearOptions[index].max;
    }
    setRatioYearOptions(prev => {
      let temp = [...prev];
      if (optionKey === "min") {
        temp[index].min = processedValue
      } else if (optionKey === "max") {
        temp[index].max = processedValue
      }
      return temp;
    })
  }

  return (

    <div className="border-b-[3px] border-[#E8E6E1] space-y-3">
      <li className={
        open
          ? "bg-white  py-1"
          : " py-1"
      }
      ref={listItemRef}
      >
        <BasedFilterWrapper
          isSelect={true}
          label={label}
          onClick={accordionClick}
          open={open}
        />
        <div
          className="transition-all max-h-0 duration-700  overflow-y-auto px-3 scrollbar-none"
          style={{ maxHeight: open ? "17rem" : "" }}
        >
          {open === true ?
            <>
              <div className="flex items-center my-2 gap-2">
                <label className="font-semibold text-[13px] text-[#333333]">Year :</label>
                <div className="flex gap-3">
                  <div className="flex ">
                    <input
                      type="radio"
                      className="cursor-pointer mr-1"
                      value="any"
                      id="any"
                      checked={RatioYear === "any"}
                      onClick={() => onRatioYearChange('any')}
                    />

                    <label htmlFor="any" className="text-xs cursor-pointer whitespace-nowrap">Any of</label>
                  </div>
                  <div className="flex ">
                    <input
                      type="radio"
                      value="all"
                      id="all"
                      className="cursor-pointer mr-1"
                      checked={RatioYear === "all"}
                      onClick={() => onRatioYearChange('all')}
                    />
                    <label htmlFor="all" className="text-xs cursor-pointer whitespace-nowrap">Across All</label>
                  </div>
                </div>
              </div>

              <div className="py-2">
                <Select
                  mode="multiple"
                  showSearch
                  options={options}
                  optionFilterProp='label'
                  value={RatioYearOptions}
                  onChange={onRationOptionChange}
                  style={{
                    width: '100%',
                    border: '1px solid #BC8B20',
                    borderRadius: '4px',
                    background: '#ffffff',
                    maxHeight: '60px',
                    overflowY: "auto"
                  }}
                  bordered={false}
                />
                <div className="">
                  {RatioYearOptions.length > 0 &&
                    <div className="flex justify-between my-3 gap-3">
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333]">Fields</label>
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333] ml-3">Greater than</label>
                      <label className="whitespace-nowrap font-semibold text-[13px] text-[#333333]">Less than</label>
                    </div>
                  }
                  {RatioYearOptions.map((option, i) => {
                    return (
                      <>
                        <div key={option} className="grid grid-cols-3 space-y-2 flex items-center break-words mb-1">
                          <div className=" text-[13px] text-[#333333] leading-[17px] font-sans_book_body">{option.label}</div>
                          <div className="px-1">

                            <input
                              className="border border-gray-300 rounded p-1 w-full"
                              type="text"
                              autoComplete="off"
                              required
                              value={option.min}
                              onChange={(e) => onOptionsYearChange(i, "min", e.target.value, option.value)}
                            />
                          </div>
                          <div className="px-1">
                            <input
                              className="border border-gray-300 rounded p-1 w-full"
                              type="text"
                              autoComplete="off"
                              required
                              value={option.max}
                              onChange={(e) =>
                                onOptionsYearChange(i, "max", e.target.value, option.value)
                              }
                            />
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
                <hr className="mt-2"></hr>
                <div className="mt-2">
                  <ToggleButton value={RatioAndOr === "or"} onLabel="AND" offLabel="OR" onToggle={(value) => setRatioAndOr(value)} />
                </div>
              </div>
            </> :

            <></>}

        </div>

      </li>

    </div>
  );
};
export default RatiosBasedFilter;

