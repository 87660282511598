import React, { useState, useMemo, useContext, useEffect } from "react";
import { useSearchParams, useParams, useLocation } from 'react-router-dom';
import { CompareCompanyContext } from '../../../Contexts/CompareCompanyContext'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { NavLink } from 'react-router-dom';
import compare from '../../../images/compare.png'
import ReactReadMoreReadLess from "react-read-more-read-less";
import { exportPdf, getInvestorCompanyDetails } from "../../../Config/config"

import InvestorProfileHighlights from './NewInvestorProfileHighlights'
import InvestorPortfolioCompanies from './NewInvPortfolioCompanies'
import InvestorPortfolioExits from './NewInvPortfolioExits'
import InvestorFunds from './NewInvestorFunds'
import InvestorNewsActivity from './NewInvNewsActivity'

import ComparePopUp from '../../../Pages/ComparePopUp'
import AddNote from "../../../Pages/addNote";
import AlertPopUp from "../../../Components/DetailPage/AlertPopUp";
import PENotesPopup from '../../../Components/NotesPopup/PENotesPopup'
import SendDealPopUp from "../../SendDealPopUp";
import { CO_INVESTOR_TAB } from "../../../Queries/Pe"
import PdfDownloader from "../../shared/PdfDownloader";
import Constants from "../../../Constants";
import { Tooltip } from "antd";
import axios from 'axios'
import { routeNames } from '../../../routeSegments';
import useModule from "../../../hooks/useModule";
import { UserContext } from "../../../Contexts/UserContext";
import Loader from "../../Loader";
import InvestorPortfolioIPO from "./NewInvPortfolioIPO";
import AddNotePopover from "../../shared/AddNotePopover";
import Tab from '../../shared/Tab';
import CoInvestorLayOut from "./CoInvestorLayOut";
import { debounce, delay } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { DataFoundContext } from "../../../Contexts/DataFoundContext";
import { TableDataContext } from "../../../Contexts/TableDataContext";
import NewLogo from "../../NewLogo";


/**
 * The `InverstorCompanyDetail` function is a React component that displays the details of an investor
 * company, including overview, investments, exits, IPOs, funds, co-investors, and similar companies.
 */
const InverstorCompanyDetail = (props) => {
    const overview = () => { document.getElementById("profile").scrollIntoView({ behavior: 'smooth' });; }
    const investments = () => { document.getElementById("investments").scrollIntoView({ behavior: 'smooth' });; }
    const exits = () => { document.getElementById("exits").scrollIntoView({ behavior: 'smooth' });; }
    const ipo = () => { document.getElementById("ipos").scrollIntoView({ behavior: 'smooth' });; }
    const funds = () => { document.getElementById("funds").scrollIntoView({ behavior: 'smooth' });; }
    const co_investor = () => { document.getElementById("co-inverstor").scrollIntoView({ behavior: 'smooth' });; }
    const similarCompanies = () => { document.getElementById("news").scrollIntoView(); }
    const [showModal, setShowModal] = useState(false);
    const [showAdd, setShowAdd] = useState(false)
    const [isActive, setIsActive] = useState(1);
    const location = useLocation();
    const [note, setNote] = useState(false)
    const [skipScroll, setSkipScroll] = useState(false)
    const {
    investorsExitsDataFound,
    setInvestorsExitsDataFound,
    investorsIPODataFound,
    setInvestorsIPODataFound,
    investorsFundsDataFound,
    setInvestorsFundsDataFound,
    newsAndActivityDataFound,
    setNewsAndActivityDataFound
  } = useContext(DataFoundContext);

    /* The code is using the `useInView` hook to track the visibility of certain elements on the
    page. It creates multiple instances of the hook, each with different options for the threshold,
    rootMargin, and skipScroll properties. The hook returns three values: the element reference, a
    boolean indicating whether the element is currently visible, and an entry object containing
    additional information about the visibility. These values are assigned to variables with
    descriptive names for each element being tracked. */
    const [overviewEl, isOverviewVisible, overviewEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [investmentEl, isInvestmentVisible, investmentEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [exitEl, isExitVisible, exitEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [ipoEl, isIpoVisible, ipoEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [fundEl, isFundVisible, fundEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [coInvestorEl, isCoInvestorVisible, coInvestorEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })
    const [newsEl, isnewsVisible, newsEntry] = useInView({ threshold: 0.5, rootMargin: "60px 0px 0px 0px", skip: skipScroll })

    const { setCompanyDetail, setCurrentPage } = useContext(CompareCompanyContext);


    const [profile, setProfile] = useState({});
    const [investmentPortfolio, setInvestmentPortfolio] = useState([])
    const [exitPortfolio, setexitPortfolio] = useState([]);
    const [ipoPortfolio, setIpoPortfolio] = useState([]);
    const [fundsRaising, setfundsRaising] = useState([]);
    const [newsletter, setNewsletter] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const { primaryMasterId ,isPE,isVC} = useModule();
    const { isStudent, isDirectoryUser } = useContext(UserContext);
    const [data, setData] = useState()

    const { investorId } = useParams();

    const getInvestorData = () => {
        setIsLoading(true);
        axios.get(`${getInvestorCompanyDetails}${investorId}/`).then(response => {
            if (response.status == 200) {
                setData(response.data)
                setProfile(response.data?.profile)
                setInvestmentPortfolio(response.data?.investments_through);
                
                if(response.data.exits_through.length !== 0 ) {setInvestorsExitsDataFound(true)}
                setexitPortfolio(response.data?.exits_through);
                
                if(response.data?.ipo_exits.length !== 0 ) {setInvestorsIPODataFound(true)}
                setIpoPortfolio(response.data?.ipo_exits)

                if(response.data.pe_fundraising.length !== 0) {setInvestorsFundsDataFound(true)}
                setfundsRaising(response.data.pe_fundraising);

                if(response.data.related_news.length !== 0) {setNewsAndActivityDataFound(true)}
                setNewsletter(response.data.related_news)

                setIsLoading(false);
            }
        }).catch(error => {
            setInvestorsExitsDataFound(false);
            setInvestorsIPODataFound(false);
            setInvestmentPortfolio([]);
            setexitPortfolio([]);
            setIpoPortfolio([]);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getInvestorData();
    return () => {
        setInvestorsExitsDataFound(false);
        setInvestorsIPODataFound(false);
        setInvestorsFundsDataFound(false);
        setNewsAndActivityDataFound(false);
    }
    }, [investorId])


    // const desc = profile?.description?.trim()  ?? "";
    const desc = profile?.description + "." ?? "";

    const descLen = desc?.length;


   /**
    * The function `onTabClick` is used to handle tab clicks and perform different actions based on the
    * selected tab index.
    * @param tabIndex - The `tabIndex` parameter represents the index of the tab that was clicked. It
    * is used to determine which tab was clicked and perform the corresponding action.
    */
    const onTabClick = (tabIndex) => {
        setSkipScroll(true);
        delay(()=>{
            setSkipScroll(false);
        },500)
        if (tabIndex === 1) {
            overview()
        } else if (tabIndex === 2) {
            investments()
        } else if (tabIndex === 3) {
            exits()
        } else if (tabIndex === 4) {
            ipo()
        } else if (tabIndex === 5) {
            funds()
        } else if (tabIndex === 6) {
            co_investor()
        } else if (tabIndex === 7) {
            similarCompanies()
        }

        setIsActive(tabIndex);
    }

    const setIsActiveOnScroll = useMemo(() => {
        return debounce(index => setIsActive(index), 100)
    }, [])

    useEffect(() => {

        if (isOverviewVisible) {
            setIsActiveOnScroll(1);
        } else if (isInvestmentVisible) {
            setIsActiveOnScroll(2);
        } else if (isExitVisible) {
            setIsActiveOnScroll(3);
        } else if (isIpoVisible) {
            setIsActiveOnScroll(4);
        } else if (isFundVisible) {
            setIsActiveOnScroll(5);
        } else if (isCoInvestorVisible) {
            setIsActiveOnScroll(6);
        } else if (isnewsVisible) {
            setIsActiveOnScroll(7);
        }
    }, [isOverviewVisible, isFundVisible, isIpoVisible, isInvestmentVisible, isExitVisible, isnewsVisible, isCoInvestorVisible, setIsActiveOnScroll])

    const [URLSearchParams] = useSearchParams({ section: 1 })
    const section = URLSearchParams.get('section');

    const subTypeMasterId =() =>{
        if(isPE){
            if(section == Constants.primaryMasterSubIds.pe_vc_investments_id){
                return Constants.subCategoryIds.pe_investor_investments_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_exits_id){
                return Constants.subCategoryIds.pe_investor_exits_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_ma_id){
                return Constants.subCategoryIds.pe_investor_exitsma_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_pm_id){
                return Constants.subCategoryIds.pe_investor_exitspm_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id){
                return Constants.subCategoryIds.pe_investor_backed_ipos_id
              }
            
        }
        else if(isVC){
            if(section == Constants.primaryMasterSubIds.pe_vc_investments_id){
                return Constants.subCategoryIds.vc_investor_investments_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_exits_id){
                return Constants.subCategoryIds.vc_investor_exits_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_ma_id){
                return Constants.subCategoryIds.vc_investor_exitsma_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_pm_id){
                return Constants.subCategoryIds.vc_investor_exitspm_id
            }else if(section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id){
                return Constants.subCategoryIds.vc_investor_backed_ipos_id
            }            

        }
    }
    
    const { tableInvestorId: invId } = useContext(TableDataContext);

    console.log("dd", invId);

    const currentIndex = invId?.indexOf(Number(investorId));

    console.log("curr", currentIndex);

    console.log(location.state, 'loc')

    function getLeftNavLinkRoute(id, page, inverstorId, index) {
        if (id === Constants.primaryMasterIds.vcId) {
            switch (page) {
            case 'investments':
                return routeNames.vcNewInvestorById(inverstorId[index - 1]);
            case 'exits':
                return routeNames.vcNewExitInvestorById(inverstorId[index - 1]);
            case 'ipos':
                return routeNames.vcNewIpoInvestorById(inverstorId[index - 1]);
            default:
                return null;
            }
        } else {
            switch (page) {
            case 'investments':
                return routeNames.peNewInvestorById(inverstorId[index - 1]);
            case 'exits':
                return routeNames.peNewExitInvestorById(inverstorId[index - 1]);
            case 'ipos':
                return routeNames.peNewIpoInvestorById(inverstorId[index - 1]);
            default:
                return null;
            }
        }
    }

    function getRightNavLinkRoute(id, page, inverstorId, index) {
        if (id === Constants.primaryMasterIds.vcId) {
            switch (page) {
            case 'investments':
                return routeNames.vcNewInvestorById(inverstorId[index + 1]);
            case 'exits':
                return routeNames.vcNewExitInvestorById(inverstorId[index + 1]);
            case 'ipos':
                return routeNames.vcNewIpoInvestorById(inverstorId[index + 1]);
            default:
                return null;
            }
        } else {
            switch (page) {
            case 'investments':
                return routeNames.peNewInvestorById(inverstorId[index + 1]);
            case 'exits':
                return routeNames.peNewExitInvestorById(inverstorId[index + 1]);
            case 'ipos':
                return routeNames.peNewIpoInvestorById(inverstorId[index + 1]);
            default:
                return null;
            }
        }
    }

    document.title = `${profile?.name ? profile.name + " - " : "" }PE-VC Investors - Venture Intelligence`;

    return (
        <> 
        <div className='overflow-y-auto details-screen'>
            <div className="bg-[#FFFFFF] relative">
                <nav className="flex p-1 lg:px-1 px-3 border-b  " aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-1">
                        <li className='flex items-center'>
                            <div className='flex items-center'>
                                <NavLink to={location.state?.prevPath != null
                                    ? location.state?.prevPath
                                    : primaryMasterId == Constants.primaryMasterIds.vcId ? routeNames.vc_investors_vc_investments
                                        : routeNames.pe_investors_pe_vc_investments}
                                    className="ml-2 text-[11pt] lg:text-[12px] text-[#666666] ">
                                    Investor</NavLink>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <span className=" text-[12px] text-[#666666] mr-1">/</span>
                                <span className=" text-[11pt] lg:text-[12px] text-[#666666] font-semibold"
                                    aria-current="page">{profile?.name}</span>
                            </div>
                        </li>
                    </ol>
                {invId && 
                    <>
                        <Tooltip title="Prev">
                            <NavLink
                            className="absolute top-2 right-8"
                            to={getLeftNavLinkRoute(primaryMasterId, props.page, invId, currentIndex )}
                            >
                            {invId.length === 0 || currentIndex === 0 ? null : <FaChevronLeft size={15} color='grey'/>}
                            </NavLink>
                        </Tooltip>
                        <Tooltip title="Next">
                            <NavLink
                            className="absolute top-2 right-4"
                            to={getRightNavLinkRoute(primaryMasterId, props.page, invId, currentIndex)}
                            >
                            {invId.length - 1 === currentIndex ? null : (
                                <FaChevronRight size={15} color='grey'/>
                            )}
                            </NavLink>
                        </Tooltip>
                    </>
                }
                </nav>
                {/* <!-- BarRaiser--> */}
                {isProfileLoading ?
                    <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center">
                        <Loader />
                    </div>
                    :
                    <div className="bg-[#FFFFFF] profile-detail">
                        <div className="pl-5 pr-3">
                            <div className="lg:hidden mt-3  grid  md:grid-cols-8 grid-cols-10  items-center mb-4">
                                <div className="md:col-span-6 col-span-5" title="Compare Company">
                                    <button
                                        className={
                                            showModal
                                                ? "bg-[#BC8B20] text-white text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                                : "bg-[#FFFFFF] text-[#666666] text-[14px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center"
                                        }
                                        type="button"
                                        onClick={() => {
                                            setShowModal(!showModal)
                                            setCompanyDetail([])
                                            setCurrentPage("investor")
                                        }
                                        }
                                    >
                                        <img
                                            className={
                                                showModal ? "pr-1 brightness-0 invert-[1]" : "pr-1"
                                            }
                                            src={compare}
                                            alt=""
                                        />
                                        <span>Compare</span>
                                    </button>
                                </div>
                                <div className="flex md:col-span-2 col-span-5 space-x-3">
                                    <div >
                                        <AlertPopUp cid={investorId} type_id={2} />
                                    </div>
                                    <div>
                                        <AddNotePopover
                                            title="Save List"
                                            companyId={investorId}
                                            companyName={profile?.investor_name ?? ""}
                                            type_id={Constants.moduleIds.investorsId}
                                            sub_type_master_id={subTypeMasterId()}
                                            IsDetailed={true}
                                        />
                                    </div>


                                    <div className="col-span-1">
                                        <PENotesPopup note={note} cid={investorId} type_id={2} />
                                    </div>
                                    <div className="col-span-1">
                                        <SendDealPopUp data={profile?.investor_name ?? ""} />
                                    </div>
                                    {isStudent == 'true' ? <></> :
                                        <div className="col-span-1">
                                            <PdfDownloader
                                                url={exportPdf}
                                                fileName={profile?.investor_name?.replaceAll(/\s/g, '')}
                                                data={{
                                                    "type_id": 2,
                                                    "entity_id": investorId,
                                                    "category": props.page,
                                                    "type": primaryMasterId
                                                }} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="pb-4 pt-6 bg-[#FFFFFF]">
                                <ul >
                                    <li className="w-full flex ">
                                        <div className=" rounded-md">
                                            <NewLogo
                                                name={profile.name}
                                                id={investorId}
                                                menu="investor"
                                                page="detail page"
                                            />
                                        </div>

                                        <div className="ml-3 flex flex-1 flex-col">
                                            <span className="text-[#333333] text-[22px] font-semibold  leading-[24px]" href="#"> {profile?.name} </span>
                                            <span className="text-[12pt] leading-[16pt] text-[#333333]  lg:text-[13px] lg:leading-[17px] font-sans_roman_body  ">{profile?.firm_type ? profile?.firm_type : ""}</span>
                                        </div>

                                        <div className="hidden lg:grid grid-cols-8 gap-2  h-10">
                                            <div className="col-span-3 mt-2">
                                                <Tooltip title="Compare Investor">
                                                    <button

                                                        className={
                                                            showModal
                                                                ? "bg-[#BC8B20] text-white text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#BC8B20] rounded-[4px] inline-flex items-center font-sans_book_body"
                                                                : "bg-[#FFFFFF] text-[#666666] text-[13px] py-1 lg:py-1 px-2 lg:px:4 border border-[#DDDDDD] rounded-[4px] inline-flex items-center font-sans_book_body"
                                                        }
                                                        type="button"
                                                        onClick={() => {
                                                            setShowModal(!showModal)
                                                            setCompanyDetail([])
                                                            setCurrentPage("investor")
                                                        }
                                                        }
                                                    >
                                                        <img
                                                            className={
                                                                showModal
                                                                    ? "brightness-0 invert-[1] w-[20px] h-[18px] pr-1"
                                                                    : "w-[20px] h-[18px] pr-1 blur-btn"
                                                            }
                                                            src={compare}
                                                            alt=""
                                                        />
                                                        <span>Compare</span>
                                                    </button>
                                                </Tooltip>
                                            </div>
                                            <div className="col-span-5 mt-[5px]">
                                                <div className="grid grid-cols-5 gap-1 flex items-center">
                                                    <div className="col-span-1 ">
                                                        <AlertPopUp cid={investorId} type_id={Constants.moduleIds.investorsId} page="detailpage" />{" "}
                                                    </div>

                                                    <div className="col-span-1">
                                                        <AddNotePopover
                                                            title="Save List"
                                                            companyId={investorId}
                                                            companyName={profile?.investor_name ?? ""}
                                                            type_id={Constants.moduleIds.investorsId}
                                                            sub_type_master_id={subTypeMasterId()}
                                                            IsDetailed={true} 
                                                        />
                                                    </div>
                                                    <div className="col-span-1 mt-1">
                                                        <PENotesPopup note={note} cid={investorId} type_id={Constants.moduleIds.investorsId} />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <SendDealPopUp data={profile?.investor_name ?? ""} />
                                                    </div>
                                                    {isStudent == 'true' ? <></> :
                                                        <div className="col-span-1">
                                                            <PdfDownloader
                                                                url={exportPdf}
                                                                fileName={profile?.name?.replaceAll(/\s/g, '')}
                                                                data={{
                                                                    "type_id": 2,
                                                                    "entity_id": investorId,
                                                                    "category": props.page,
                                                                    "type": primaryMasterId
                                                                }} 
                                                            />
                                                        </div>
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    {showAdd ? <AddNote id={1} /> : ""}
                                </ul>
                            </div>
                            
                            {profile?.invested_industries != "" && profile?.invested_industries != null ? <div>
                                <p className="text-[11pt] leading-[17pt] lg:block lg:leading-[16px] mb-2 text-[#333333]  lg:text-[14px] font-sans_book_body mr-3 ">
                                    Industries (Existing Investments): {profile?.invested_industries}
                                </p>
                            </div> : <></>}
                            <div className="bg-[#FFFFFF]">

                                {desc != "" && desc != null ? <div >
                                    <p className=" text-[11pt] leading-[18pt] py-2 lg:text-[14px] text-[#333333] lg:leading-[20px] font-sans_book_body">
                                        {/* {ProfileHighlightData?.Description ? */}
                                        <ReactReadMoreReadLess
                                            charLimit={descLen}
                                            readMoreText={"View More "}
                                            readLessText={"View Less "}
                                            readMoreClassName="read-more-less--more lg:[text-12px]"
                                            readLessClassName="read-more-less--less lg:[text-12px]"
                                        >
                                            {profile?.description ? desc : "--"}

                                        </ReactReadMoreReadLess>
                                        {/* : ""} */}
                                    </p>
                                </div> : <></>}


                            </div>
                        </div>
                    </div>
                }
                <div className="bg-[#EDEDED]">
                    <div className="flex flex-row overflow-x-auto scrollbar space-x-6 bg-white px-6 pt-1  border-b border-[#D7D7D7] cursor-pointer sticky top-0  custom-z-index scrollbar-remove detail-tabs">


                        <Tab active={isActive === 1} label={"Overview"} onClick={() => onTabClick(1)} />
                        <Tab active={isActive === 2} label={"Investments"} onClick={() => onTabClick(2)} />
                        {investorsExitsDataFound && <Tab active={isActive === 3} label={"Exits"} onClick={() => onTabClick(3)} />}
                        {investorsIPODataFound ? (<Tab active={isActive === 4} label={"IPO"} onClick={() => onTabClick(4)} />) : ("")}
                        {investorsFundsDataFound && <Tab active={isActive === 5} label={"Funds"} onClick={() => onTabClick(5)} />}
                        {!isDirectoryUser ? <Tab active={isActive === 6} label={"Co-Investors"} onClick={() => onTabClick(6)} /> : ''}
                        {newsAndActivityDataFound ? (<Tab active={isActive === 7} label={"Recent News & Activity"} onClick={() => onTabClick(7)} />) : ("")}

                    </div>


                    <div className="h-full space-y-5 details-page">
                        <section ref={overviewEl} className="scroll-mt-14" id="profile"><InvestorProfileHighlights data={data} isLoading={isLoading} profile={profile} page={props?.page} investorName={profile.name}/></section>
                        <section ref={investmentEl} className="scroll-mt-14" id="investments"><InvestorPortfolioCompanies isLoading={isLoading} portfolio={investmentPortfolio} investorName={profile.name} /></section>
                        <section ref={exitEl} className="scroll-mt-14" id="exits"><InvestorPortfolioExits data={data} isLoading={isLoading} portfolio={exitPortfolio} investorName={profile.name} /></section>
                        <section ref={ipoEl} className="scroll-mt-14" id="ipos"><InvestorPortfolioIPO isLoading={isLoading} portfolio={ipoPortfolio} investorName={profile.name} /></section>
                        <section ref={fundEl} className="scroll-mt-14" id="funds"><InvestorFunds funds={fundsRaising} investorName={profile.name} /></section>
                        {!isDirectoryUser ? <section ref={coInvestorEl} className="scroll-mt-14" id="co-inverstor"><CoInvestorLayOut CO_INVESTOR_TAB={CO_INVESTOR_TAB} /> </section> : ""}
                        <section ref={newsEl} className="scroll-mt-14" id="news"><InvestorNewsActivity isLoading={isLoading} news={newsletter}/></section>
                    </div>
                </div>
            </div>
        </div>
            {showModal ? (<ComparePopUp id={1} onClose={(e) => setShowModal(false)} />) : null}
        </>
    )

}

export default InverstorCompanyDetail