import React, { useContext, useState } from "react";
import { PeFilterContext, ReCompaniesMaxDealOptions, ReCompaniesMinDealOptions } from "../../Contexts/PeFilterContext";
import SectorFilter from "../../Components/RefineSearchComponents/FundSector";
import CompanyTypeFilter from "../../Components/RefineSearchComponents/CompanyTypeFilter";
import TypeFilter from "../../Components/RefineSearchComponents/ReTypeFilter";
import RegionFilter from "../../Components/RefineSearchComponents/RegionFilter";
import CityFilter from "../../Components/RefineSearchComponents/CityFilter";
import InvestorTypeFilter from "../../Components/RefineSearchComponents/InvestorTypeFilter";
import ExitStatusFilter from "../../Components/RefineSearchComponents/ExitStatusFilter";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import axios from "axios";
import { filterLocationUrl } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import AcquirerDealRangeFilter from "../RefineSearchComponents/AcquirerDealRangeFilter";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import Investor from "../RefineSearchComponents/Investor";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import Subsectors from "../RefineSearchComponents/Subsectors";
// import SubsectorFilter from '../RefineSearchComponents/SubSector';



/**
 * The `ReInvestmentRefineSearch` function is a React component that renders a form for refining search
 * filters for investment data.
 * @returns a JSX element, which represents the UI of the component.
 */
export default function ReInvestmentRefineSearch({ customPathname }) {
    const {
        onErrorHandler,dropdown,dbDropdown , setDbDropdown,SubSectorOptions, setSubSectorOptions,
        refineSearchFilters,SwitchState,setSwitchState,
        InvestorOptions , setInvestorOptions ,
        lAdvisorsOptions,setlAdvisorsOptions,tAdvisorsOptions , settAdvisorsOptions , TargetCompanyOptions , setTargetCompanyOptions ,
        setrefineSearchFilters,setMonthPickerValue } = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(32);
    const navigate = useNavigate();
    const { getToken } = useContext(UserContext);
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false


    //local updated options
    const [SectorOptions, setSectorOptions] = useState([])
    const [CompanyTypeOptions, setCompanyTypeOptions] = useState([])
    const [TypeOption, setTypeOption] = useState([]);
    const [RegionOptions, setRegionOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [InvestorTypeOptions, setInvestorTypeOptions] = useState([])
    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])
    const [ExitStatusOptions, setExitStatusOptions] = useState([])
    const [CountryOptions, setCountryOptions] = useState([])
    // const [SubSectorOptions, setSubSectorOptions] = useState([])




    /**
     * The function `applyFilter` updates the refine search filters based on the selected options.
     */
    const applyFilter = () => {
        const selectedFundSector = SectorOptions.filter(option => option.isChecked);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedType = TypeOption?.filter(option => option.isChecked);
        const selectedInvestorType = InvestorTypeOptions?.filter(option => option.isChecked);
        const selectedCompanyType = CompanyTypeOptions?.filter(option => option.isChecked);
        const selectedExitStatus = ExitStatusOptions?.filter(option => option.isChecked);
        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedOperators = SwitchState;
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedInvestor = InvestorOptions?.filter(option => option.isChecked); 
        const selectedSubsector = SubSectorOptions?.filter(option => option.isChecked);
        // console.log(selectedSubsector,'selectedSubsector');

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],

                fundSector: selectedFundSector,
                region: selectedRegion,
                city: selectedCity,
                type: selectedType,
                companyType: selectedCompanyType,
                investorType: selectedInvestorType,
                dealRange: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],
                exitStatus: selectedExitStatus,
                subSector: selectedSubsector,
                deals_operator : selectedOperators,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                investor_id : selectedInvestor ,
                target_company:selectedTargetCompany,
            },
        }))
    }

    /**
     * The function `resetFilter` resets a specific filter in the `refineSearchFilters` object by
     * setting its value to an empty object.
     */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setlAdvisorsOptions([]);
        settAdvisorsOptions([]);
        setSubSectorOptions([]);
        setTargetCompanyOptions([]);
        setInvestorOptions([]);
    }


    // console.log('refineSearchFilters[refineSearchFilters]', refineSearchFilters[customPathname]);


    // Submitting Refine Search Form 

   /**
    * The function `refineSearchFilter` is used to refine search filters, reset certain values, and
    * navigate to a custom pathname if necessary.
    * @param event - The event parameter is an object that represents the event that triggered the
    * function. It contains information about the event, such as the type of event, the target element,
    * and any additional data associated with the event. In this case, it is used to prevent the
    * default behavior of the event (e.g
    */
    const refineSearchFilter = (event) => {
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        setCurrentPage(1);
        applyFilter();
        setSelected(false)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }


  /**
   * The `onResetClick` function resets the current page, filters, and navigates to a custom pathname
   * if it is not already the current location.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is the click event that occurred when the user clicked on a reset
   * button or link.
   */
    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);        
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    /**
     * The function `accordionClick` sets the selected value to either 0 or the provided value.
     * @param val - The `val` parameter is a variable that represents the value that is being passed to
     * the `accordionClick` function.
     */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

  /**
   * The function `onRegionChange` takes in an array of options, filters the options based on a
   * condition, maps the filtered options to extract specific properties, and then makes a POST request
   * using axios to a specified URL with the extracted properties as parameters.
   * @param options - The `options` parameter is an array of objects. Each object represents an
   * industry and has the following properties:
   */
    const onRegionChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: ids,
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                // setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    useEffect(() => {
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];
        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: ids?.includes(option.id) })))
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds?.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])

    const toggleSwitchState = () => {
        setSwitchState(prevState => prevState === "and" ? "or" : "and");
    };

    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };

    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter" >


                <form onSubmit={refineSearchFilter} onReset={onResetClick} className="bg-[#FAF5EA] w-full">
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>

                            <SectorFilter
                                fundSectorOptions={SectorOptions}
                                setFundSectorOptions={setSectorOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(30)}
                                open={select === 30}
                            />

                            <Subsectors 
                                options={SubSectorOptions}
                                setOptions={setSubSectorOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(72)}
                                label="Subsector"
                                open={select === 72}
                            />

                            {/* {/* <SubsectorFilter
                                SubSectorOptions={SubSectorOptions}
                                setSubSectorOptions={setSubSectorOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(2)}
                                open={select === 2}
                                dataPath="re-investments"
                            /> */}

                            <RegionFilter
                                RegionOptions={RegionOptions}
                                setRegionOptions={setRegionOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(11)}
                                onChange={onRegionChange}
                                open={select === 11} />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(13)}
                                open={select === 13} />
                            <TypeFilter
                                TypeOption={TypeOption}
                                setTypeOption={setTypeOption}
                                customPathname={customPathname}
                                dataPath="re_investments"
                                accordionClick={() => accordionClick(39)}
                                open={select === 39} />

                            <CompanyTypeFilter
                                CompanyTypeOptions={CompanyTypeOptions}
                                setCompanyTypeOptions={setCompanyTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(5)}
                                open={select === 5} />

                            <InvestorTypeFilter
                                InvestorTypeOptions={InvestorTypeOptions}
                                setInvestorTypeOptions={setInvestorTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(14)}
                                open={select === 14} />
                            <AcquirerDealRangeFilter
                                MaxDealOptions={MaxDealRangeOptions}
                                setMaxDealOptions={setMaxDealRangeOptions}
                                MinDealOptions={MinDealRangeOptions}
                                setMinDealOptions={setMinDealRangeOptions}
                                MaxDealrange={ReCompaniesMaxDealOptions}
                                MinDealRange={ReCompaniesMinDealOptions}
                                label="Deal Range (US $M)"
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(15)}
                                open={select === 15}
                            />

                            <ExitStatusFilter
                                ExitStatusOptions={ExitStatusOptions}
                                setExitStatusOptions={setExitStatusOptions}
                                customPathname={customPathname}
                                dataPath="investments"
                                accordionClick={() => accordionClick(17)}
                                open={select === 17} />
                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                            <h1 className='font-bold text-[16px] ml-1'>{customPathname ? (customPathname?.includes("investors") ? "SHOW INVESTORS BY" : customPathname?.includes("companies") || customPathname?.includes("/re/company/re-investments") ? "SHOW DEALS BY" : "SHOW ADVISORS BY") : 'SHOW DEALS BY'}</h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>
                                {/* <div className="py-1">
                                    <ToggleButton value={SwitchState} onLabel="AND" offLabel="OR" onToggle={toggleSwitchState} />
                                </div> */}

                                <Investor 
                                    options={InvestorOptions}
                                    setOptions={setInvestorOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(32)}
                                    label="Investor"
                                    open={select === 32}
                                />

                                <TargetCompany 
                                    options={TargetCompanyOptions}
                                    setOptions={setTargetCompanyOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(31)}
                                    label="Company"
                                    open={select === 31}
                                />

                                <LAdvisor 
                                    options={lAdvisorsOptions}
                                    setOptions={setlAdvisorsOptions}
                                    customPathname={customPathname}
                                    label="Legal Advisors"
                                    accordionClick={() => accordionClick(21)}
                                    open={select === 21}
                                />

                                <TAdvisor 
                                    options={tAdvisorsOptions}
                                    setOptions={settAdvisorsOptions}
                                    label="Transaction Advisors"
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(29)}
                                    open={select === 29}
                                />

                        </ul>
                    </main>
                    <div className="px-3  py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                        <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                            Reset
                        </button>
                        <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[13px] text-[#FFFFFF] rounded-[3px] font-sans_medium_body leading-[17px] ">
                            Filter
                        </button>
                    </div>
                    {/* reset button */}
                </form>
            </div>
        </div>
    );
}

