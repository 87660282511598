import { React, useState, useMemo, useEffect, useContext } from 'react'
import { matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'
import moment from 'moment'
import { useParams } from 'react-router-dom'; 
import DealInfo from './DealInfo';
import InvestorInfo from './InvestorInfo';
import Advisor from './Advisor'
import routePathSegments, { routeNames } from '../../../routeSegments';
import { TableDataContext } from '../../../Contexts/TableDataContext'
import { redealPageProfile, getReRoundHierarchy, redealdetail } from '../../../Config/config'
import DealInfoExit from './DealInfoExit';
import InvestmentExit from './InvestmentExit';
import InvestorInfoExit from './InvestorInfoExit';
import DetailInformation from '../Detailpage/RECompanyDetailInformation';
import IpoDealInfo from './IpoDealInfo';
import IpoInvestorInfo from './IpoInvestorInfo';
import IpoInvestment from './IpoInvestment';
import OtherMaDealInfo from './OtherMaDealInfo';
import AcquirerInfo from './AcquirerInfo';
import OtherMaAdvisorInfo from './OtherMaAdvisorInfo';
import { Tooltip } from 'antd';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { PeFilterContext } from '../../../Contexts/PeFilterContext';


const CompanyDealDetail = ({ menu, category ,type }) => {


    const { activeTabDeal, setActiveTabDeal, setDealIdTab, allDealIds,tableCompanyId} = useContext(TableDataContext);
    const [companyDealDetailTab, setCompanyDealDetailTab] = useState();    
    const [roundsDetail, setRoundsDetail] = useState([]);
    const [selectedRoundType, setSelectedRoundType] = useState([]);
    const { companyId, dealId } = useParams();
    const [currentDealId, setCurrentDealId] = useState(dealId);
    const [cardData, setCardData] = useState({});
    const location = useLocation();
    const { dealsView } = useContext(PeFilterContext);
    let companyProfileId = parseInt(companyId)
    const navigate = useNavigate();
    const currentIndex = allDealIds?.indexOf(Number(dealId));


    /**
     * The function fetchProfileData makes a POST request to a specified URL with certain data and
     * headers, and then sets the cardData state with the response data if the response status is 200.
     */
    const fetchProfileData = (activeTabDeal, currentDealId) => {
        setCardData({})
        axios(`${redealPageProfile}`, {
            method: "POST",
            data: {
                "type": activeTabDeal,
                "company_id": companyProfileId,
                "deal_id": currentDealId
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status == "200") {
                    console.log(response.data, "dealCarddata ");
                    setCardData(response.data)
                }
            })
            .catch((error) => {
                console.log("API not working - dealCarddata", error);
            });
    }



    /**
     * The function fetchTableData makes a POST request to a specified URL with certain data and
     * headers, and then sets the state of a component with the response data if the request is
     * successful.
     */
    const fetchTableData = (activeTabDeal, currentDealId, dealId) => {
        axios(`${redealdetail}`, {
            method: "POST",
            data: {
                "type": activeTabDeal,
                "company_id": companyProfileId,
                "deal_id": currentDealId
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                // console.log(response.data, "dealCarddata ");
                if (response.status == "200") {
                    // console.log(response.data, "dealCarddata ");
                    setCompanyDealDetailTab(response.data)
                }
            })
            .catch((error) => {
                console.log("API not working - dealCarddata", error);
            });
    }




    const companyProfileTabDataDetail = useMemo(() => companyDealDetailTab, [companyDealDetailTab]);
    useEffect(() => {
        getRoundHierarchyData(companyProfileId, currentDealId, category);
    }, []);


    /**
     * The function `getRoundHierarchyData` makes a POST request to an API endpoint with specific data
     * and headers, and then sets various state variables based on the response.
     */
    const getRoundHierarchyData = (companyProfileId, dealId, category) => {
        setCardData({})
        setSelectedRoundType("")
        axios(`${getReRoundHierarchy}`, {
            method: "POST",
            data: {
                "category": category,
                "company_id": companyProfileId,
                "deal_id": dealId
            },
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.status == "200") {
                    console.log(response.data, "getRounds");
                    setRoundsDetail(response?.data)
                    setSelectedRoundType(response?.data?.active_tab)
                    setActiveTabDeal(response?.data?.active_tab)
                    setDealIdTab(dealId)
                    fetchProfileData(response?.data?.active_tab, dealId)
                    fetchTableData(response?.data?.active_tab, dealId)

                    // console.log(response?.data ,"haii")


                }
            })
            .catch((error) => {
                console.log("API not working - getRoundHierarchy", error);
            });
    };



    let currentBreadCrumbLabel = ""
    const reInvestmentMatch = matchPath(`${routeNames.reCompanyInvestmentDealById(companyId, dealId)}`, location.pathname)
    const reExitsMatch = matchPath(`${routeNames.reCompanyExitsDealById(companyId, dealId)}`, location.pathname)
    const reIPOMatch = matchPath(`${routeNames.reCompanyBackedIPOSDealById(companyId, dealId)}`, location.pathname)
    const reOtherMaMatch = matchPath(`${routeNames.reCompanyOtherMaDealById(companyId, dealId)}`, location.pathname)

    let urlLink;

    if (reInvestmentMatch != null) {
        currentBreadCrumbLabel = "PE-RE Investments";
        urlLink = routeNames.reCompanyInvestmentDealById
    } else if (reExitsMatch != null) {
        currentBreadCrumbLabel = "PE-RE Exits";
        urlLink = routeNames.reCompanyExitsDealById
    } else if (reOtherMaMatch != null) {
        currentBreadCrumbLabel = "OtherMa";
        urlLink = routeNames.reCompanyOtherMaDealById
    }
    else if (reIPOMatch != null) {
        currentBreadCrumbLabel = "PE-RE Backed IPOs";
        urlLink = routeNames.reCompanyBackedIPOSDealById
    }
    else {
        currentBreadCrumbLabel = "Deals";
    }
    const reMatch = matchPath(`${routePathSegments.re}/*`, location.pathname)

    /* The above code is a JavaScript React code snippet. It defines a constant variable
    `exportFileName` using the `useMemo` hook. */
    const exportFileName = useMemo(() => {
        const pathSegs = location.pathname.split('/')
        let exportFileName = `${cardData?.companyname}-inv`;

        if (pathSegs.includes(routePathSegments.re_exits)) {
            exportFileName = `${cardData?.companyname}-exit`;
        } else if (pathSegs.includes(routePathSegments.other_ma)) {
            exportFileName = `${cardData?.companyname}-otherma`;
        } else if (pathSegs.includes(routePathSegments.re_ipo)) {
            exportFileName = `${cardData?.companyname}-re-ipo`;
        } else {
            exportFileName = `${cardData?.companyname}-inv`;
        }
        return exportFileName;
    }, [companyDealDetailTab, location.pathname,cardData]);


 
    return (
        <>
            <div className="bg-[#FFFFFF] relative">
                <div className="col-span-5 md:col-span-4 font-[Sequel Sans] bg-[#FFFFFF]">
                    {/* bread crumb */}
                    <nav className="flex p-1 px-3 lg:px-2 border-b overflow-x-auto" aria-label="Breadcrumb">
                        <ol role="list" className="flex items-center space-x-1">
                            <li className='flex items-center'>
                                <div className='flex items-center'>
                                    <NavLink state={{ prevPath: location.state?.prevPath }} to={
                                        location.state?.prevPath != null
                                            ? location.state?.prevPath

                                            : routeNames.re_companies_re_investments} className="text-[11pt] ml-2 lg:text-[12px] font-sans_book_body text-[#666666]">
                                        Companies</NavLink>
                                </div>
                            </li>
                            <li className='flex items-center'>
                                <div className="flex items-center">
                                    <span className=" text-[11pt] lg:text-[12px] text-[#666666] mr-1">/</span>
                                    <div className='pt-[0.1rem] flex items-center whitespace-nowrap'>
                                        <NavLink state={{ prevPath: location.state?.prevPath }} to={

                                            routeNames.reCompanyFundById(companyId)} className="whitespace-nowrap mr-1 text-[11pt] lg:text-[12px] text-[#666666]">
                                            {cardData?.companyname}</NavLink>
                                    </div>
                                    <span className="text-[11pt] lg:text-[12px] text-[#666666] mr-1">/</span>
                                    <span className="text-[11pt] lg:text-[12px] text-[#666666] whitespace-nowrap font-semibold"
                                        aria-current="page">{currentBreadCrumbLabel}</span>
                                    {/* {// console.log('companyProfile1', companyProfileData['company'].companyname)} */}
                                </div>
                            </li>
                        </ol>
                    </nav>
                    {allDealIds &&  
                        <>
                            <Tooltip title="Prev">
                                <NavLink
                                className="absolute top-2 right-8"
                                to={urlLink(tableCompanyId[currentIndex - 1], allDealIds[currentIndex - 1])}
                                >
                                {tableCompanyId?.length === 0 || currentIndex === 0 || currentIndex === -1 ? null : <FaChevronLeft size={15} color='grey'/>}
                                </NavLink>
                            </Tooltip>
                            <Tooltip title="Next">
                                <NavLink
                                className="absolute top-2 right-4"
                                to={urlLink(tableCompanyId[currentIndex + 1], allDealIds[currentIndex + 1])}
                                >
                                {tableCompanyId?.length - 1 === currentIndex || currentIndex === -1 ? null : (
                                    <FaChevronRight size={15} color='grey'/>
                                )}
                                </NavLink>
                            </Tooltip>
                        </>
                        }
                    {/* <!-- KinaraCapital--> */}
                    <DetailInformation activeTab={selectedRoundType} menu="redealpage" data={cardData} id={companyId} page="redealPage" desc={companyProfileTabDataDetail} exportFileName={exportFileName} />


                    <div className=" flex flex-row mt-[0.7rem] overflow-x-auto scrollbar space-x-6 bg-white px-6 border-b border-[#D7D7D7] cursor-pointer">

                        {Object.keys(roundsDetail).length > 0 && Object.entries(roundsDetail).map(([key, value]) => {
                            return (
                                value != "Success" && key != "active_tab" && value?.length > 0 && value?.map((round, i) => {
                                    console.log(key, "getroundhier")
                                    console.log(value, "getroundhiervalue");
                                    return (
                                        Object.entries(round).map(([key1, value1]) => {
                                            console.log(key1, "getroundhier1")
                                            console.log(value1, "value1");
                                            return (
                                                <a className={"py-2 whitespace-nowrap text-[12pt] leading-[17pt] text-[#333333] lg:text-[13px] lg:leading-[17px] font-semibold" +
                                                    (value1?.hash_current_deal ?
                                                        " border-b-[3px] border-b-[#F1CA75]   group-hover:text-[#F1CA75]"
                                                        :
                                                        " opacity-[0.4]"
                                                    )
                                                }
                                                    onClick={() => {
                                                        getRoundHierarchyData(companyProfileId, value1?.hash_current_deal_id, category)
                                                        setCurrentDealId(value1?.hash_current_deal_id);
                                                        // setIsActive(i + 1);
                                                        setCompanyDealDetailTab("")
                                                        setRoundsDetail(companyProfileId, value1?.hash_current_deal_id)

                                                        // console.log(companyProfileId, value1?.hash_current_deal_id,"eee")
                                                    }}
                                                >
                                                    {key1}
                                                </a>
                                            )
                                        })
                                    )
                                })
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="bg-[#EDEDED]">
                <div className=" flex flex-row overflow-x-auto scrollbar bg-[#EDEDED] space-x-6 py-4 px-6 pt-4 cursor-pointer ">
                    {Object.keys(roundsDetail).length > 0 && Object.entries(roundsDetail).map(([key, value]) => {
                        return (
                            value != "Success" && key != "active_tab" && value?.length > 0 && value?.map((round, i) => {
                                console.log(round, "val?.hash_current_deal_id")
                                return (
                                    Object.entries(round).map(([key1, val]) => {
                                        // console.log(val, "valll")
                                        return (
                                            val?.hash_current_deal &&
                                            Object.entries(val).map(([key2, vall]) => {
                                                return (
                                                    vall?.deal_date_parsed &&
                                                    <a className={
                                                        "inline-block whitespace-nowrap px-3 py-1 text-[13px] cursor-pointer leading-[16px]  rounded-[13.5px] " +
                                                        (vall?.selected ?
                                                            "text-[#FFFFFF] bg-[#BC8B20] border border-[#BC8B20] pointer-events-none"
                                                            :
                                                            "text-[#BC8B20] border border-[#BC8B20]"
                                                        )
                                                    }
                                                        onClick={() => {
                                                            navigate(urlLink(companyProfileId, vall?.deal_id))
                                                            setCurrentDealId(vall?.deal_id);
                                                            // getRoundHierarchyData(companyProfileId, vall?.deal_id, category)
                                                            // setIsActive(i + 1);
                                                            setCompanyDealDetailTab("")
                                                            // console.log(companyProfileId, vall?.deal_id ,"fg")
                                                        }}
                                                    >
                                                        {moment(vall?.deal_date_parsed, "MMM YYYY").format("MMM YYYY")}
                                                    </a>
                                                )
                                            })


                                        )

                                    })

                                )
                            })
                        )
                    })
                    }

                </div>

                {selectedRoundType == "re-investments" ?
                    <div className="w-full lg:grid lg:grid-cols-8 gap-4 px-4 pt-0 bg-[#EDEDED] mb-3">
                        <div
                            className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
                            <div
                                className="w-full lg:grid lg:grid-rows-8 gap-5 mb-7">
                                <div
                                    className=" row-span-3  py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                    <DealInfo data={companyProfileTabDataDetail?.deal_info} />
                                </div>




                            </div>
                        </div>


                        <div className="w-full lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                            <div className="lg:grid lg:grid-rows-9 gap-5">

                                <div className="col-span-2 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                    <InvestorInfo data={companyProfileTabDataDetail?.investor_info} />
                                </div>

                                <div className="col-span-2 mb-4 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                    <Advisor data={companyProfileTabDataDetail?.advisor_info} />
                                </div>
                            </div>
                        </div>

                    </div>

                    :
                    selectedRoundType == "re-exits" ?
                        <div className="w-full lg:grid lg:grid-cols-8 gap-4 px-4 pt-0 bg-[#EDEDED] mb-3">
                            <div
                                className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
                                <div
                                    className="w-full lg:grid lg:grid-rows-8 gap-5">
                                    <div
                                        className=" row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                        <DealInfoExit data={companyProfileTabDataDetail?.deal_info} />
                                    </div>

                                </div>
                            </div>

                            <div className="w-full lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                                <div className="lg:grid lg:grid-rows-9 gap-5">

                                    <div className="col-span-2 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                        <InvestorInfoExit data={companyProfileTabDataDetail?.investor_info} />
                                    </div>
                                    {companyProfileTabDataDetail?.investment_details?.trim()?.length === 0 ?
                                            <></> :
                                    <div className="col-span-2 mb-3 pt-4 pb-2 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                        <InvestmentExit data={companyProfileTabDataDetail?.investment_details} />
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        selectedRoundType == "re-ipo" ?
                            <div className="w-full mb-[2rem] lg:grid lg:grid-cols-8 gap-4 px-4 pt-0 bg-[#EDEDED]">
                                <div
                                    className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
                                    <div
                                        className="w-full lg:grid lg:grid-rows-8 gap-5">
                                        <div
                                            className=" row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                            <IpoDealInfo data={companyProfileTabDataDetail?.deal_info} />
                                        </div>

                                    </div>
                                </div>

                                <div className="w-full lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                                    <div className="lg:grid lg:grid-rows-9 gap-5">

                                        <div className="col-span-2 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                            <IpoInvestorInfo data={companyProfileTabDataDetail?.investor_info} advisorInfo={companyProfileTabDataDetail?.advisor_info}/>
                                        </div>
                                        {companyProfileTabDataDetail?.investment_details?.trim().length === 0 ?
                                            <></> :
                                            <div className="col-span-2 mb-3 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                <IpoInvestment data={companyProfileTabDataDetail?.investment_details} />
                                            </div>

                                        }

                                    </div>
                                </div>
                            </div>
                            :
                            selectedRoundType == "other-manda" ?
                                <div className="w-full lg:grid lg:grid-cols-8 gap-4 px-4  bg-[#EDEDED]">
                                    <div
                                        className=" mt-3 lg:mt-0 rounded-[4px] lg:rounded lg:col-span-4 ">
                                        <div
                                            className="w-full lg:grid lg:grid-rows-8 gap-5">
                                            <div
                                                className=" row-span-3 py-4 bg-[#FFFFFF] shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                <OtherMaDealInfo data={companyProfileTabDataDetail?.deal_info} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="w-full lg:col-span-4 mt-5 lg:mt-0 overflow-x-auto space-x-5 scrollbar">
                                        <div className="lg:grid lg:grid-rows-9 gap-5">

                                            <div className="col-span-2 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                {console.log(companyProfileTabDataDetail,'companyProfileTabDataDetail')}
                                                <AcquirerInfo data={companyProfileTabDataDetail?.acquirer_info} />
                                            </div>

                                            <div className="col-span-2 mb-3 pt-4 bg-[#FFFFFF] mt-5 lg:mt-0 shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] rounded-[4px]">
                                                <OtherMaAdvisorInfo data={companyProfileTabDataDetail?.advisor_info} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                }
            </div>
            {/* {showModal ? (<ComparePopUp />) : null} */}
        </>
    )

}
export default CompanyDealDetail
