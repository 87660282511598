import { React, useState, useMemo, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { routeNames } from '../../../../routeSegments';
import { FundingContext } from '../../../../Contexts/FundingDataProvider';
import moment from "moment";
import OverflowToolTip from '../../../shared/OverflowToolTip';
import Paginate from '../../../Paginate';
import Constants from '../../../../Constants';
import { usePagination, useSortBy, useTable } from 'react-table';
import SortAscendingIcon from 'mdi-react/SortAscendingIcon';
import SortDescendingIcon from 'mdi-react/SortDescendingIcon';
import SortIcon from 'mdi-react/SortIcon';
import LockIcon from 'mdi-react/LockIcon';
import ConfirmPopup from '../../../../Utils/ConfirmPopup';


/* The above code is defining a React component called `FundingTable`. This component is responsible
for rendering a table with funding data. */
const FundingTable = ({ menu, data, companyFundingId }) => {
    const { setreData } = useContext(FundingContext);

    const location = useLocation();

    const [alert, setalert] = useState({ message: "", show: false });

    /**
     * The function `onConfirmation` sets the state of `alert` to hide the alert message.
     */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

    /**
     * The function `onBlurColumnClick` sets an alert message to be displayed with a subscription
     * product message.
     */
    const onBlurColumnClick = (cell) => {

        setalert({ show: true, message: Constants.subscribeProductMsg, isAlertOnly: true, closeBtnName: "OK" })
    }


    /* The above code is defining a custom sorting function called `dealDateSort` using the `useMemo`
    hook in React. This sorting function is used to compare two rows (`rowA` and `rowB`) based on a
    specific column (`columnId`) containing date values. */
    const dealDateSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
        }
    }, [])

    /* The above code is defining a custom sorting function called `investorSort` using the `useMemo`
    hook in React. This sorting function takes in two rows (`rowA` and `rowB`), a column ID, and a
    boolean flag `desc` indicating whether the sorting should be in descending order. */
    const investorSort = useMemo(() => {
        return (rowA, rowB, columnId, desc) => {
            const defaultVal = desc ? '--' : 'A';

            const valueA = rowA?.values[columnId].map(data => data.investor_name).join("")
            const valueB = rowB?.values[columnId].map(data => data.investor_name).join("")

            return (valueA ?? defaultVal).localeCompare(valueB ?? defaultVal);
        }
    }, [])

    /* The above code is defining an array of columns for a table in a React component. Each column has
    properties such as id, Header (displayed header text), accessor (data accessor), className (CSS
    class for the column), headerspanClassName (CSS class for the header span), headerClassName (CSS
    class for the header), sortType (custom sort function for the column), and Cell (custom cell
    rendering function). The columns are created using the useMemo hook, which memoizes the columns
    array so that it is only recalculated when the dependencies (menu and companyFundingId) change.
    The code */
    const columns = useMemo(() => {
        let blurColumns;

        const allowedModulesStr = localStorage.getItem(Constants.localStorageKeys.allowedModules);
        const allowedModulesList = JSON.parse(allowedModulesStr) ?? [];
        // console.log("allowedModulesListallowedModulesList", allowedModulesList);

        if (allowedModulesList.includes("re")) {
            blurColumns = false;
        } else {
            blurColumns = true;
        }

const addCommasToNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  

        return [
            {
                id: "type",
                Header: "Type",
                accessor: "type",
                className: "clickable w-[10%]",
                // headerspanClassName: "w-[10%]",
                headerClassName: "w-[10%]",

                // sortType: customStringSortMemo,

            },
            {
                Header: "City",
                accessor: "city",
                className: "w-[11%]",
                // headerspanClassName: "ml-auto ",
                headerClassName: "w-[11%]",


            },
            {
                Header: "Investors",
                accessor: "investors",
                className: "clickable w-[5%]",
                headerspanClassName: " ",
                headerClassName: "w-[15%]",
                sortType: investorSort,
                Cell: (cell) => {
                    if (cell.value == Constants.restrictedPlaceholder) {
                        return cell.value
                    }

                    return <OverflowToolTip component = { cell.value?.map((inv, index) => (
                        <Link onClick={(e) => {
                            if (menu === "re") {
                                if (!allowedModulesList.includes("re")) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }
                        }} rel="noopener noreferrer" className='hover:text-[#BC8B20]'
                            state={{ cameFrom: location.pathname }} to={routeNames.reInvestorById(inv.investor_id)
                            }>
                            {`${inv.investor_name}${index == cell.value?.length - 1 ? "" : ", "}`}
                            {/* {inv.investor_name ?? "--"} */}
                        </Link>))
                    }/>


                },

            },
            {
                id: "dealDate",
                Header: "Deal Date",
                accessor: "dealDate",
                className: "w-[4%]",
                headerspanClassName: "",
                headerClassName: "w-[4%]",
                Cell: (cell) => {
                    if (cell.value == Constants.restrictedPlaceholder) {
                        return cell.value
                    }
                    return <Link
                    target='_blank'
                        onClick={(e) => {
                            if (menu === "re") {
                                if (!allowedModulesList.includes("re")) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onBlurColumnClick();
                                }
                            }
                        }}
                        state={{ prevPath: location.state?.prevPath ?? "" }}
                        to={routeNames.reCompanyInvestmentDealById(companyFundingId, cell.value.id)
                        }>
                        {moment(cell.value.date , 'MMM YYYY').format('MMM YYYY') ?? "--"}
                    </Link>
                },
                sortType: dealDateSort

            },
            {
                Header: "Exit Status",
                accessor: "exitstatus",
                className: "w-[3%]",
                // headerspanClassName: "ml-auto ",
                headerClassName: "w-[3%]",


            },

            {
                Header: "Deal Amount (US$ M)",
                accessor: "dealAmountInUS",
                className: "text-right pr-[2.5rem] w-[5%] 2xl:pr-[2.7rem] lg:pr-[2.75rem]",
                headerspanClassName: "ml-auto ",
                headerClassName: "w-[5%]",
                Cell: (cell) => {
                    return addCommasToNumber(cell?.value)
                },


            },



        ];
    }, [menu, companyFundingId])

    /* The above code is a JavaScript React code snippet. It is using the `useMemo` hook to create a
    memoized version of the `rowData` variable. */
    const rowData = useMemo(() => {
        let rows = [];
      
        data?.forEach((investment, i) => {
            rows.push({
                type: investment?.real_estate_type ?? [],
                dealDate: investment?.Deal_Date == Constants.restrictedPlaceholder ? investment?.Deal_Date : { date: investment?.Deal_Date, id: investment.Deal_Id },
                dealAmountInUS: investment.Deal_Amount == 0 ? "" : investment.Deal_Amount,
                investors: investment?.Investors ?? [],
                exitstatus: investment.Exit_Status == 0 ? "" : investment.Exit_Status,
                city: investment.City ?? []

            })
        })


        setreData(rows.length == 0 ? null : rows)

        return rows;
    }, [data])

    /* The above code is using the `useMemo` hook in React to create an initial sort configuration. It
    is creating an array with a single object that has two properties: `id` and `desc`. The `id`
    property is set to 'dealDate' and the `desc` property is set to `true`. This configuration is
    used for sorting data, with the `dealDate` property being sorted in descending order. The empty
    array `[]` passed as the second argument to `useMemo` ensures that the initial sort
    configuration is only created once, when the component is first rendered. */
    const initialSort = useMemo(() => [
        {
            id: 'dealDate',
            desc: true
        }
    ], []);

    /* The above code is using the `useTable` hook from the React-Table library to create a table
    instance. */
    const tableInstance = useTable({
        columns,
        data: rowData,
        disableSortRemove: true,
        initialState: {
            pageSize: Constants.noOfRowPerPage,
            pageIndex: 0,
            sortBy: initialSort,
        },
    },
        useSortBy,
        usePagination,
    );

    /* The above code is using destructuring assignment to extract specific properties and methods from
    the `tableInstance` object. These extracted properties and methods are then being used in a
    JavaScript React component. */
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        gotoPage,
        state: { pageIndex, pageSize }
    } = tableInstance;


    return (

        <div className="space-y-6">
            <div className="bg-white">
                <>

                    <div className='overflow-x-auto scrollbar relative'>
                    { rows?.length > 0 ? 
                        <table className=" datatable w-full" {...getTableProps()}>

                            <thead className="text-[12pt] leading-[15pt] bg-[#EDEDED] lg:text-[13px] lg:leading-[15px] text-[#666666] font-sans_semi_bold py-4 px-0 text-center ">
                                {
                                    headerGroups.map(headerGroup => (

                                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                                            {
                                                headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps([
                                                        {
                                                            className: `py-3.5 px-5 whitespace-nowrap ${column.headerClassName}`,
                                                            style: column.headerStyle,
                                                        },
                                                        column.getSortByToggleProps(),
                                                    ])} title="">
                                                        <div className="flex">
                                                            <span
                                                                {...column.getHeaderProps([
                                                                    {
                                                                        className: `mr-1 ${column.headerspanClassName}`,
                                                                    },

                                                                ])}
                                                            >{column.render('Header')}</span>
                                                            {/* <span className='mr-1'>{column.render('Header')}</span> */}
                                                            {column.disableSortBy ? "" : column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <SortAscendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                    : <SortDescendingIcon size={16} className="mt-[0.15rem] lg:mt-0" />
                                                                : <SortIcon size={16} className="mt-[0.15rem] lg:mt-0" />}
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>

                            <tbody {...getTableBodyProps()} className="relative text-[12pt] leading-[16pt] lg:text-[14px] font-sans_book_body text-left">

                                {page.length == 0
                                    ? <tr>
                                        <td colspan="8">
                                            <div className="h-[4rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] text-center">
                                                No data found
                                            </div>
                                        </td>
                                    </tr>
                                    : page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()} className="border-b">
                                                {

                                                    row.cells.map(cell => {
                                                        console.log("cell.column.renderCol", cell.value);
                                                        if (cell.column.blurColumns || cell.value == Constants.restrictedPlaceholder) {
                                                            return (<td onClick={onBlurColumnClick} className=' relative whitespace-nowrap cursor-pointer' {...cell.getCellProps()}>
                                                                <div className='blur-sm bg-[#C9C9C9] select-none w-full h-full px-5 py-4'>{Constants.restrictedPlaceholder}</div>
                                                                <div className='absolute h-full w-full flex items-center justify-center top-0 bottom-0'>
                                                                    <LockIcon size={16} />
                                                                </div>
                                                            </td>)
                                                        }

                                                        return (
                                                            <td colSpan={cell.column.colspan} rowSpan={cell.column.rowspan} {...cell.getCellProps([
                                                                {
                                                                    className: `data-td-animate whitespace-nowrap px-5 py-4 ${cell.column.className}`,
                                                                    style: cell.column.style,
                                                                },
                                                            ])}>
                                                                {
                                                                    cell.value ? cell.render('Cell') : " "
                                                                }
                                                            </td>


                                                        )
                                                    })}
                                            </tr>

                                        )
                                    })}
                            </tbody>
                        </table>
                        :
                        <div className="h-[3rem] mx-auto  lg:text-[15px] text-[15px] text-[#333333] relative mt-[1rem] text-center">
                            No data found
                        </div>
                        }
                    </div>
                    {rows?.length <= Constants.noOfRowPerPage
                        ? <></>
                        : <Paginate
                            currentPage={pageIndex + 1}
                            totalCount={rows?.length}
                            pageSize={pageSize}
                            onPageChange={(page) => {
                                gotoPage(page - 1);
                            }}
                        />}
                    {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                </>
            </div>
        </div>
    )
}

export default FundingTable