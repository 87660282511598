import React, { useContext,  useState, useRef } from 'react'
import { useForm } from "react-hook-form";
import { NavLink} from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext'
import Logo from '../images/vi-logo.png';
import Laptop from '../images/laptop.png';
import RingTop from '../images/ring1.png';
import RingBottom from '../images/ring3.png';
import {ToastContainer} from 'react-toastify';
import { useSearchParams } from 'react-router-dom'
import { Eye, EyeOff } from 'lucide-react';
/**
 * The `ResetPassword` function is a React component that renders a form for resetting a user's
 * password and handles the form submission.
 * @returns The component `ResetPassword` is being returned.
 */
const ResetPassword = () => {
    
    const { isLogging, resetPassword } = useContext(UserContext);
    const [loginErrorMsg, setLoginErrorMsg] = useState();    
    const [searchParams, setSearchParams] = useSearchParams()
    const email = searchParams.get('email')
    const token = searchParams.get('token')
    const [newPasswordValue,setNewPasswordValue] = useState("")
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isReadyToLogin,setIsReadyToLogin] = useState(false)

    const { register, handleSubmit, watch,formState: { errors }} = useForm({ mode: "onChange",
    defaultValues: {            
        newPassword: "",
        confirmPassword: ""
    }});
    const password = useRef({});
    password.current = watch("newPassword", "");

    /**
     * The function `onSubmitForResetPW` is used to reset a user's password and set a flag to indicate
     * that the user is ready to login.
     */
    const onSubmitForResetPW = async formData => {
        resetPassword(email,formData.newPassword,formData.confirmPassword,token).then((data) =>{
            if (data.success == true) {
                // toast.success(data?.message)
                setIsReadyToLogin(true)
        }
        })
    }
   
    return (
        <div>
            <div className="inset-0 h-full w-full object-cover">
                <div className="h-max ">
                    {/* <!-- left side--> */}
                    <div className="grid grid-cols-2">
                        <div className=" lg:col-span-1 hidden lg:block h-screen  lg:bg-[#DBB25B]">
                            <div className="relative">
                                <img src={RingTop} className="absolute w-[130px] ml-[62%] xl:ml-[65.25%] xl:mt-[10%] mt-[9%]" alt="" />
                                <img src={RingBottom} className="absolute mt-[48%] ml-[12.5%] w-[60px]" alt="" />
                                <img src={Laptop} className="absolute m-[16%] " alt="" />
                            </div>
                        </div>
                        {/* <!--right side--> */}                        
                            <div className="lg:col-span-1 col-span-2 mt-[3rem] px-6 md:px-12 lg:px-22 xl:px-24">
                                <div className="mx-auto w-full max-w-screen-sm lg:w-96 lg:px-8">
                                    <div className="2xl:mt-[2rem]">
                                        <img className="w-60 mx-auto" src={Logo} alt="vi" />
                                        <h2 className="mt-6 2xl:mt-[2rem] text-[24px] lg:text-[16px] text-center tracking-[-0.31pt] font-sans_book_body font-semibold leading-[26.4px] text-[#333333]">Reset Password</h2>
                                    </div>                                   
                                    {/* <!--form start--> */}
                                    <div className="mt-8">
                                        <div className="mt-6">
                                            {isReadyToLogin ? 
                                            <div className='bg-[#F2EDE1] mx-auto h-[3rem] w-fit'>
                                            <p className='py-3 px-4 whitespace-nowrap font-sans_light_body text-[13px] lg:text-[12px] text-[#333333] leading-[24px] '> Your password has been reset. Please <NavLink to="/login" className="underline text-[#D7A945]">Click Here </NavLink>to Login</p>
                                            </div>
                                            :
                                            <form action="#" method="POST" className="space-y-6" onSubmit={e => e.preventDefault()}>                                               
                                            <div className="space-y-1">   
                                                <div className="flex items-center mb-2 justify-between">
                                                    <label htmlFor="newPassword" className="block font-sans_book_body  text-[14px] text-[#2B2A29] tracking-[-0.3px] leading-[17px]"> New Password </label>
                                                </div>                                                 
                                                <div className="relative">
                                                    <input  
                                                        {...register("newPassword", {
                                                            required: "Please enter your password",
                                                            onChange: (e) => {
                                                                setLoginErrorMsg(null);
                                                            },
                                                            minLength: {
                                                                value: 5,
                                                                message: "Min length should be greater than 5"
                                                            }
                                                        })}
                                                        id="newPassword"
                                                        name="newPassword"
                                                        type={showNewPassword ? "text" : "password"}
                                                        autoComplete="off"
                                                        className="form-input shadow-sm  bg-[#FDF7E9] focus:outline-none rounded-[5px] focus:ring-[#BC8B20] border border-[#BC8B20] py-1.5 px-2 w-full block pr-10"
                                                    />
                                                    <button 
                                                        type="button"
                                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                    >
                                                        {!showNewPassword ? (
                                                            <EyeOff size={20} className="text-gray-500" />
                                                        ) : (
                                                            <Eye size={20} className="text-gray-500" />
                                                        )}
                                                    </button>
                                                </div>
                                                <p className="text-[red] text-[12px]">{errors.newPassword?.message}</p>
                                            </div>

                                            <div className="space-y-1">
                                                <div className="flex items-center mb-2 justify-between">
                                                    <label htmlFor="confirmPassword" className="block font-sans_book_body  text-[14px] text-[#2B2A29] tracking-[-0.3px] leading-[17px]"> Re-enter Password </label>
                                                </div>                                                    
                                                <div className="relative">
                                                    <input                                                              
                                                        {...register("confirmPassword", {
                                                            required: "Please enter your confirm password",
                                                            validate: value =>
                                                                value === password?.current || "The passwords do not match"
                                                        })}
                                                        id="confirmPassword"
                                                        name="confirmPassword" 
                                                        type={showConfirmPassword ? "text" : "password"}
                                                        autoComplete="off"
                                                        className="form-input shadow-sm  bg-[#FDF7E9] focus:outline-none rounded-[5px] focus:ring-[#BC8B20] border border-[#BC8B20] py-1.5 px-2 w-full block pr-10"
                                                    />
                                                    <button 
                                                        type="button"
                                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                                    >
                                                        {!showConfirmPassword ? (
                                                            <EyeOff size={20} className="text-gray-500" />
                                                        ) : (
                                                            <Eye size={20} className="text-gray-500" />
                                                        )}
                                                    </button>
                                                </div>
                                                <p className="text-[red] text-[12px] leading-[23px]">{errors.confirmPassword?.message}</p>
                                            </div>

                                            {loginErrorMsg && <p className="text-[red] text-[16px] leading-[23px]">{loginErrorMsg}</p>}
                                            <div>
                                                <button 
                                                    type="submit"
                                                    onClick={handleSubmit(onSubmitForResetPW)}
                                                    disabled={isLogging}
                                                    className="w-full flex justify-center text-left py-2.5 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] tracking-[-0.3px] leading-[17px] bg-[#A5711A] hover:bg-[#BC8B20]  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                                                >
                                                    {isLogging ? "Submitting..." : "Submit"}
                                                </button>
                                            </div>
                                        </form>
                                            }
                                            {/* <!--form end--> */}
                                        </div>
                                    </div>                                   
                                </div>
                            </div>

                    </div>


                </div>
            </div>
            {/* {logoutPopupData.show ? <LogoutPopup {...logoutPopupData} /> : <></>} */}
            <ToastContainer
                position={"top-center"}
                autoClose={3000}
                toastClassName="bg-white shadow-lg rounded-lg flex items-center"
                bodyClassName="text-sm font-medium text-gray-900"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            // className='bg-success text-white'
            />
        </div>
    )
}

export default ResetPassword
