import React, { useState, useMemo, useEffect, useContext } from "react";
import { useSearchParams, Routes, Route, useParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import sortingimg from '../../../images/up-down-arrow.png'
import moment from "moment";
import DataTable from "../../DataTables/DataTable";
import { getInvestorNewsUrl } from "../../../Config/config";
import axios from "axios";
import useModule from "../../../hooks/useModule";
import { UserContext } from "../../../Contexts/UserContext";
import { DataFoundContext } from "../../../Contexts/DataFoundContext";

const InvestorNewsActivity = ({ isLoading, news }) => {
  
  const { newsAndActivityDataFound } =
    useContext(DataFoundContext);


  /* The `customStringSortMemo` constant is using the `useMemo` hook to memoize a sorting function for
  string values in a data table. */
  const customStringSortMemo = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      const defaultVal = desc ? '--' : 'A';
      return (rowA?.values[columnId]?.heading ?? defaultVal).localeCompare(rowB?.values[columnId]?.heading ?? defaultVal);
    }
  }, []);
  /* The `dealDateSort` constant is using the `useMemo` hook to memoize a sorting function for date
  values in a data table. */
  const dealDateSort = useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
      return moment.utc(rowA?.values[columnId].date).diff(moment.utc(rowB?.values[columnId].date))
    }
  }, [])
  /* The `const columns` variable is an array of objects that define the columns for a data table. Each
  object represents a column and contains properties such as `id`, `Header`, `accessor`,
  `className`, `Cell`, and `sortType`. */
  const columns = useMemo(() => [
    {
      id: 'news',
      Header: "News",
      accessor: "news",
      headerClassName: "w-[10%]",
      className: "text-[#333333] text-[12pt] px-5 py-4 flex-wrap lg:text-[14px] lg:leading-[16px]",
      Cell: ({ value }) => {
        return <a href={value?.link} target="_blank" rel="noreferrer" className="flex w-[15rem] lg:w-auto">
          {value?.heading}
        </a>
      },
      sortType: customStringSortMemo,
    },
    {
      id: 'releaseDate',
      Header: "Release Date",
      accessor: "releaseDate",
      className: "text-[#333333] pr-[3.2rem] md:pr-[3.5rem] py-4 float-center lg:float-right whitespace-nowrap lg:text-[14px] lg:leading-[16px] text-right",
      headerClassName: "text-center float-right flex lg:px-10 px-8 py-3.5",
      Cell: ({ value }) => {
        return moment(value?.date).format('MMM DD, YYYY')
      },
      sortType: dealDateSort,
    }
  ], [])

  /* The `rowData` constant is using the `useMemo` hook to memoize a function that transforms the
  `news` data into a format that can be used by the `DataTable` component. */
  const rowData = useMemo(() => {
    let rows = [];

    if (news) {
      news?.forEach(news => {
        rows.push({
          news: { heading: news?.title, link: news?.news_link },
          // releaseDate: news?.release_date
          releaseDate: { date: news?.published_at, news: news?.title }

        })
      });
    }

    return rows;

  }, [news])
  /* The `initialSort` constant is using the `useMemo` hook to memoize an array that specifies the
  initial sorting configuration for the data table. It contains an object with two properties: `id`
  and `desc`. */
  const initialSort = useMemo(() => [
    {
      id: "releaseDate",
      desc: true
    }
  ], []);

  return (
  <>
    {newsAndActivityDataFound ? (
    <div className="px-4 bg-[#EDEDED] w-[100%] pb-5">
      <div className=" mt-4 bg-white shadow-[0_2px_6px_0_rgba(0,0,0,0.08)] overflow-hidden sm:rounded-lg">
        <div className="pt-4 px-5">
          <p className=" text-[14pt] leading-[19pt] mb-3 lg:text-[17px] lg:leading-[22px] font-semibold text-[#333333]">
            Recent News & Activities
          </p>
        </div>
        <DataTable showLoader={isLoading} columns={columns} data={rowData} sortBy={initialSort} />
      </div>
    </div>
    ) : (
        ""
      )}
  </>
  );
};

export default InvestorNewsActivity;