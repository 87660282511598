import React from 'react'
import Layout from '../../Components/InvestorLayout'
import InvestorCompanyDetail from '../../Components/Investor/InvestorDetailPage/NewInvestorCompanyDetail'
import RefineSearch from '../../Components/RefineSearchComponent'
import InvestorCard from '../../Components/Investor/InvestorDetailPage/InvestorDetailCard'
import { INVESTOR_COMPANY_PROFILE, INVESTOR_CARD_QUERY } from '../../Queries/Pe'
import InvestorRefineSearch from '../../Components/RefineSearch/InvestorRefineSearch'
import { routeNames } from '../../routeSegments'
import useModule from '../../hooks/useModule'
import Constants from '../../Constants'

/**
 * The function `InvestorDetailPage` is a React component that renders  investor cards, company details,
 *  and a refine search
 * @returns a JSX element
 */
function InverstorDetailPage() {

    const {primaryMasterId} = useModule();


    return ( 
        <div>
            <Layout>
            <div className="flex h-screen flex-col">
                    {/* <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/> */}
                    <InvestorCard page="investments"/>
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-[#EDEDED]">
                            {/* <CompanyDetail COMPANY_PROFILE={COMPANY_PROFILE}  /> */}
                            <InvestorCompanyDetail page="investments" type="PE-VC Investment" menu="vc"/>
                            
                        {/* <Outlet /> */}
                        {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="hidden lg:order-first lg:block lg:flex-shrink-0">
                            <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                    <InvestorRefineSearch
                                        customPathname={primaryMasterId === Constants.primaryMasterIds.peId
                                            ? routeNames.pe_investors_pe_vc_investments
                                            : routeNames.vc_investors_vc_investments} />
                                </RefineSearch>
                            </div>
                        </aside>
                    </div>                  
                </div>
            </Layout>
        </div>
    )
}

export default InverstorDetailPage