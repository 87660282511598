import { React, useContext, useEffect, useState, } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import IndustryFilter from "../../Components/RefineSearchComponents/IndustryFilter";
import SectorFilter from "../../Components/RefineSearchComponents/SectorFilter";
import YearFoundedFilter from "../../Components/RefineSearchComponents/YearFoundedFilter";
import CountryFilter from "../../Components/RefineSearchComponents/CountryFilter";
import RegionFilter from "../../Components/RefineSearchComponents/RegionFilter";
import StateFilter from "../../Components/RefineSearchComponents/StateFilter";
import ExcludeDefunctFilter from "../RefineSearchComponents/ExcludeDefunctFilter";
import FirmTypeFilter from "../RefineSearchComponents/FirmTypeFilter";
import VcFundedFilter from "../RefineSearchComponents/VcFundedFilter";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import IncubationStatusFilter from '../RefineSearchComponents/IncubationStatusFilter'
import { useLocation,  useNavigate } from "react-router";
import { filterLocationUrl, filterSectorByIndutry, tagList } from "../../Config/config";
import axios from "axios";
import { UserContext } from "../../Contexts/UserContext";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";
import DefinitionModal from "../TagDefinitionModal";
import { Tooltip } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import Investor from "../RefineSearchComponents/Investor";
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import CityFilter from "../RefineSearchComponents/CityFilter";
/**
 * The function `IncubationRefineSearch` is a React component that renders a form for refining search
 * filters in an incubation context.
 * @returns The function `IncubationRefineSearch` returns JSX elements.
 */
function IncubationRefineSearch({ customPathname }) {
    const {
        setSectors,Sectors,lAdvisorsOptions,setlAdvisorsOptions,tAdvisorsOptions , settAdvisorsOptions ,dbDropdown , setDbDropdown ,checkToggleBtn,setToggleDealsBtn,
        onErrorHandler,SwitchState,setSwitchState,dropdown,
        InvestorOptions , setInvestorOptions ,TargetCompanyOptions , setTargetCompanyOptions ,AllCities,
        setrefineSearchFilters,
        refineSearchFilters ,setMonthPickerValue} = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(32);
    const navigate = useNavigate();
    // const isMatch = useMatch({ path: routeNames.pe_companies_incubation })
    const { getToken } = useContext(UserContext);
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false


    //local updated options
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [EndYearOptions, setEndYearOptions] = useState([]);
    const [StartYearOptions, setStartYearOptions] = useState([])
    const [CountryOptions, setCountryOptions] = useState([])
    const [RegionOptions, setRegionOptions] = useState([])
    const [StateOptions, setStateOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [TagSwitchState, setTagSwitchState] = useState(true)
    const [TagsOptions, setTagsOptions] = useState([])
    const [FirmTypeOptions, setFirmTypeOptions] = useState([])
    const [VCFundedOptions, setVCFundedOptions] = useState([])
    const [IncubationStatusOptions, setIncubationStatusOptions] = useState([])
    const [isExclude, setisExclude] = useState(false)

    // useEffect(() => {
    //     const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

    //     if (ids.length > 0) {
    //         axios.post(filterSectorByIndutry, {
    //             "industry": ids,
    //             category: "investments"
    //         }, {
    //             headers: {
    //                 "Authorization": getToken()
    //             }
    //         }).then(response => {
    //             if (response.status === 200) {
    //                 setSectors(response.data?.data ?? [])
    //             } else {
    //                 setSectors([])
    //             }
    //         }).catch(onErrorHandler)
    //     } else {
    //         setSectors([])
    //     }

    // }, [IndustriesOptions])


  /**
   * The `applyFilter` function updates the refine search filters based on the selected options.
   */
    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = Sectors.filter(option => option.isChecked);
        const selectedStartYear = StartYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedEndYear = EndYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedCountries = CountryOptions?.filter(option => option.isChecked);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedState = StateOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedFirm = FirmTypeOptions?.filter(option => option.isChecked);
        const selectedVc = VCFundedOptions?.filter(option => option.isChecked);
        const selectedStatus = IncubationStatusOptions?.filter(option => option.isChecked);
        const selectedOperators = SwitchState;
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedInvestor = InvestorOptions?.filter(option => option.isChecked); 
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                yearFounded: selectedEndYear != null && selectedStartYear != null ? [selectedStartYear, selectedEndYear] : [],
                countries: selectedCountries,
                region: selectedRegion,
                state: selectedState,
                city: selectedCity,
                firmType: selectedFirm,
                operator: TagSwitchState ? 1 : 0,
                deals_operator : selectedOperators,
                investor_id : selectedInvestor ,
                target_company:selectedTargetCompany,
                tags: TagsOptions,
                vcFunded: selectedVc,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                incubationStatus: selectedStatus,
                excludeDefunctCos: isExclude ? [{ id: 1, name: "Exclude Defunct Cos", value: isExclude }] : [],
            },
        }))
        checkToggleBtn();
    }

  /**
   * The function `resetFilter` resets a specific filter in the `refineSearchFilters` object by setting
   * its value to an empty object.
   */
    const resetFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setTargetCompanyOptions([]);
        setInvestorOptions([]);
        setlAdvisorsOptions([]);
        settAdvisorsOptions([]);
        setSectors([]);
        setToggleDealsBtn(true);
    }
    useEffect(()=>{
        setToggleDealsBtn(true);
    },[customPathname])



    // Submitting Refine Search Form 
  /**
   * The function `refineSearchFilter` updates search filters, resets the month picker value, sets the
   * current page to 1, applies the filter, navigates to a custom pathname if necessary, and sets the
   * selected state to false.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It can contain information about the event, such as the target element, the type of
   * event, and any additional data associated with the event. In this case, it is used to prevent the
   * default behavior of a form submission,
   */
    const refineSearchFilter = (event) => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null) {
        //     navigate(routeNames.pe_companies_incubation);
        // }
        applyFilter();
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
        setSelected(false)

    }

    // useEffect(() => {
    //     setFirmType(firmTypeResponseData?.incubation ?? [])
    // }, [firmTypeResponseData?.incubation, setFirmType])


  /**
   * The function `onResetClick` resets the current page, filters, and navigates to a custom pathname
   * if it is different from the current location.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is the click event that occurred when the user clicked on a reset
   * button.
   */
    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null) {
        //     navigate(routeNames.pe_companies_incubation);
        // }
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

  /**
   * The function `accordionClick` sets the selected value to 0 if it is equal to `select`, otherwise
   * it sets it to the provided value.
   * @param val - The `val` parameter is a value that is passed to the `accordionClick` function. It is
   * used to determine whether the accordion should be selected or deselected.
   */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

  /**
   * The `onCountryChange` function takes in a list of options, filters them based on whether they are
   * checked, and then makes a POST request to a specified URL with the filtered options as parameters.
   * @param options - The `options` parameter is an array of objects. Each object represents an
   * industry and has the following properties:
   */
    const onCountryChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: ids,
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setRegionOptions(response.data?.region)
                setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

  /**
   * The function `onRegionChange` takes in an array of options, filters the options based on a
   * condition, maps the filtered options to extract specific properties, and then makes a POST request
   * using axios to a specified URL with the extracted properties as parameters.
   * @param options - The `options` parameter is an array of objects. Each object represents an
   * industry and has the following properties:
   */
    const onRegionChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: ids,
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status == 200) {
                setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    /**
   * The `onStateChange` function sends a POST request to a specified URL with selected country,
   * region, and state options, and updates the city options based on the response.
   * @param options - The `options` parameter is an array of objects that represent industries. Each
   * object has the following properties:
   */
    const onStateChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: selectedRegion,
            state: ids,
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    useEffect(() => {
        const countryIds = refineSearchFilters[customPathname]?.countries?.map(value => value.id) ?? [];
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const stateIds = refineSearchFilters[customPathname]?.state?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];
        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status == 200) {
                setCountryOptions(response.data?.countries?.map(option => ({ ...option, isChecked: countryIds.includes(option.id) })))
                setRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
                setStateOptions(response.data.state?.map(option => ({ ...option, isChecked: stateIds.includes(option.id) })))
                setCityOptions(AllCities?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])

        const getSearchTags = async (searchText, type) => {
        try {
        const response = await axios.post(tagList, {
            "tag": searchText,
            "type"    : type

        }, {
            headers: {
            "Authorization": getToken()
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
        } catch (error) {
            return [];
        }
    }


    const fetchData = async () => {
        const tags = await getSearchTags("ALL", "noncompetitor");
        const competitorTags = await getSearchTags("ALL", "competitor")
        console.log(tags, competitorTags, 'promi')
        openTagModal(tags, competitorTags)
    };
    
    const  [showDefinitionModal, setShowDefinitionModal] = useState(false);
    const [defValues, setDefValues] = useState({})
    console.log(defValues, 'showValues');

    const openTagModal = ( non_comp, comp) => {
        setShowDefinitionModal(true);
        setDefValues({
            non_comp: non_comp,
            comp: comp
        })
    }

    const defCloseHandler = () =>{
        setShowDefinitionModal(false);
        setDefValues({})
    }
    
    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };

    return (
        <div className="h-screen">
            <div className={isFromGlobalSearch() ?"flex flex-col overflow-y-auto no-scrollbar h-[72%]":"flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter"}>
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                        <div className="flex flex-col">
                            <div className="flex justify-between px-3 items-center pt-0">
                    <div className="flex items-end gap-2">
                                    <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>                        
                        <span className="cursor-pointer" onClick={() => {fetchData()}
                        }>
                            <Tooltip title='Tag List'>
                                    <FaInfoCircle size={12} color="grey"/>
                            </Tooltip>
                        </span>
                    </div>
                                <Switch
                                    SwitchState={TagSwitchState}
                                    setSwitchState={setTagSwitchState}
                                    customPathname={customPathname} />
                            </div>

                            <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                                <div className="bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                                    <label className=" flex  rounded ">
                                        <Tags
                                            TagsOptions={TagsOptions}
                                            customPathname={customPathname}
                                            setTagsOptions={setTagsOptions}
                                            getSearchTags={getSearchTags} />
                                    </label>
                                </div>
                            </div>
                        </div>
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                open={select === 1} />
                            <SectorFilter
                                setOptions={setSectors}
                                options={Sectors}
                                label="Sector"
                                accordionClick={() => accordionClick(2)}
                                customPathname={customPathname}
                                open={select === 2} />
                            <YearFoundedFilter
                                EndYearOptions={EndYearOptions}
                                customPathname={customPathname}
                                setEndYearOptions={setEndYearOptions}
                                StartYearOptions={StartYearOptions}
                                setStartYearOptions={setStartYearOptions} />
                            <IncubationStatusFilter
                                IncubationStatusOptions={IncubationStatusOptions}
                                setIncubationStatusOptions={setIncubationStatusOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(26)}
                                open={select === 26} />
                            <ExcludeDefunctFilter
                                isExclude={isExclude}
                                setisExclude={setisExclude}
                                customPathname={customPathname} />
                            <FirmTypeFilter
                                FirmTypeOptions={FirmTypeOptions}
                                setFirmTypeOptions={setFirmTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(16)}
                                dataPath="incubation"
                                open={select === 16} />
                            <VcFundedFilter
                                VCFundedOptions={VCFundedOptions}
                                setVCFundedOptions={setVCFundedOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(4)}
                                open={select === 4}
                                heading="Angel/VC Funded" />
                            <CountryFilter
                                CountryOptions={CountryOptions}
                                setCountryOptions={setCountryOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(10)}
                                onChange={onCountryChange}
                                open={select === 10} />
                            <RegionFilter
                                RegionOptions={RegionOptions}
                                setRegionOptions={setRegionOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(11)}
                                onChange={onRegionChange}
                                open={select === 11} />
                            <StateFilter
                                StateOptions={StateOptions}
                                setStateOptions={setStateOptions}
                                customPathname={customPathname}
                                onChange={onStateChange}
                                accordionClick={() => accordionClick(12)}
                                open={select === 12} />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(13)}
                                open={select === 13} />

                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                            <h1 className='font-bold text-[16px] ml-1 '>{customPathname.includes("investors") ? "SHOW INVESTORS BY" : customPathname.includes("companies") ? "SHOW DEALS BY" : "SHOW ADVISORS BY"}</h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>

                                <Investor 
                                    options={InvestorOptions}
                                    setOptions={setInvestorOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(32)}
                                    label="Incubator"
                                    open={select === 32}
                                />

                                <TargetCompany 
                                    options={TargetCompanyOptions}
                                    setOptions={setTargetCompanyOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(31)}
                                    label="Company"
                                    open={select === 31}
                                />

                                {/* <LAdvisor 
                                    options={lAdvisorsOptions}
                                    setOptions={setlAdvisorsOptions}
                                    customPathname={customPathname}
                                    label="Legal Advisors"
                                    accordionClick={() => accordionClick(21)}
                                    open={select === 21}
                                />

                                <TAdvisor 
                                    options={tAdvisorsOptions}
                                    setOptions={settAdvisorsOptions}
                                    label="Transaction Advisors"
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(29)}
                                    open={select === 29}
                                /> */}

                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[64px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button onClick={(select)} type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
            {showDefinitionModal && <DefinitionModal values={defValues} onClose={defCloseHandler}/>}
        </div>
    )
}

export default IncubationRefineSearch