import React, { useState, useContext, useEffect } from "react";
import MainMenu from '../../Pages/RE/ReMainMenu'
import Card from '../../Components/RE/ReListCard/AdvisorListCard'
import FilterTag from '../../Components/Tag'
import Table from '../../Components/REAdvisor/TableComponent'
import RefineSearch from '../../Components/RefineSearchComponent'
import ReLayout from '../../Components/ReLayout'
import Constants from '../../Constants'
import { useLocation } from "react-router";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import xls from "../../images/xls/png/Group 47.png";
import line from "../../images/line.png";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
import SavedFilterContent from "../../Components/SavedFilter";
import ExportPopup from "../../Components/ExportPopup";
import { reAdvisorOtherMAExportData } from "../../Components/ExportPopup/ExportData"
import { reAdvisorListExport } from "../../Config/config";
import ReInvestorExitRefineSearch from "../../Components/RefineSearch/ReInvestorExitRefineSearch";
import { UserContext } from "../../Contexts/UserContext";
import { TableDataContext } from "../../Contexts/TableDataContext";

/**
 * The `REAngelInvestment` function is a React component that renders a page for displaying real estate
 * angel investment data, including filters, a table, and a refine search sidebar.
 * @returns a JSX element.
 */
function REAngelInvestment(props) {    
    const [showExcelPopup,setshowExcelPopup] =useState()
    const {save, setSave, refineSearchFilters ,groupId} = useContext(PeFilterContext);
    const {globalSearchResult} = useContext(TableDataContext);
    const [openTab, setOpenTab] = React.useState(1);
     const savedList = () => {
        setSave(!save)
    }
    useEffect(() => {
        setOpenTab(props.active)
    }, [openTab])

    const {isTrialExport,isStudent} = useContext(UserContext)

    const { pathname } = useLocation();

    /**
     * The function checks if there are any global search filters applied 
     */
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    document.title = "RE Legal Advisors PE-RE Other M&A - Venture Intelligence";

    return (
        <ReLayout>
            <div className="flex min-h-screen h-screen flex-col">
                { isFromGlobalSearch() ? <></> :<Card />}
                <div className="flex min-h-0 flex-1 overflow-hidden">
                    {/* Primary column */}
                    <main className={`min-w-0 flex-1 md:border-t border-gray-200 flex `}>
                        <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                            <FilterTag />
                            <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem]">
                                <div className="scrollbar ">
                                    <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between  ">
                                        <MainMenu active={3} menu="l-advisor" primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                            subTypeMasterId={Constants.primaryMasterSubIds.re_advisor_otherma_id}
                                            typeId={Constants.moduleIds.l_advisor_id} />
                                        
                                        <div className="flex justify-between items-center px-5 lg:px-2 py-2">
                                                {!isFromGlobalSearch() && <label htmlFor="" className="text-[#666666]  ">
                                                    <div className="flex">
                                                        <MonthPickerComponent />
                                                    </div>
                                                </label>}
                                                <div className="flex items-center">
                                                {isTrialExport == 'false' || isStudent == 'true' ? <></> : <>
                                                <div className=" px-4">
                                                        <button>
                                                            <img
                                                                src={xls}
                                                                className="h-[24px] mt-1 max-w-[21px]"
                                                                alt=""
                                                                onClick={() => setshowExcelPopup(!showExcelPopup)}
                                                            />
                                                            {showExcelPopup && (
                                                                <ExportPopup 
                                                                modules="advisor"
                                                                module="advisors"
                                                                fileName={Constants.exportNames.re_advisor_ipos}
                                                                url={reAdvisorListExport}
                                                                onClose={() => setshowExcelPopup(false)} 
                                                                exportOptions={reAdvisorOtherMAExportData} 
                                                                advisor_type="L"
                                                                type="other-manda"  />
                                                            )}
                                                        </button>
                                                </div>
                                                <a>
                                                    <img className="h-[24px] max-w-[2px] mt-1 " src={line} />
                                                </a>

                                                </>}

                                                <a className="pl-4 group cursor-pointer">
                                                        <div className="cursor-pointer" onClick={savedList}>
                                                            <img src={save ? FileIcon : FileIconBlur}
                                                                className="h-[24px]  max-w-[21px]"
                                                                alt="" />
                                                        </div>
                                                </a> 
                                            </div>
                                            </div>
                                        
                                    </div>
                                    {save ? <SavedFilterContent
                                        primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.re_advisor_otherma_id}
                                        typeId={Constants.moduleIds.l_advisor_id}
                                        show={save} /> : ''}
                                </div>
                            </div >
                            <div className='overflow-y-auto'>
                                <Table menu="re-advisor-otherma" advisor_type="L" sub_type_master_id={Constants.subCategoryIds.re_advisor_otherma_id}/>
                            </div>
                            {/* <Outlet /> */}
                            {/* Your content */}
                        </section>

                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="order-first block flex-shrink-0">
                            <div className="relative flex h-[100%]  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                <ReInvestorExitRefineSearch customPathname={pathname} adviosrType="L" dataPath={props?.dataPath}/>
                                </RefineSearch>
                            </div>
                        </aside>
                    </main>
                </div>
            </div>
        </ReLayout>
    )
}

export default REAngelInvestment
