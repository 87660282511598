import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import routePathSegments, { routeNames } from '../../../routeSegments'
import useModule from '../../../hooks/useModule'
import InvestorCo_Investors from './InvestorCo_Investors'

/**
 * The CoInvestorLayOut function renders a component that displays co-investors and provides a link to
 * view all co-investors.
 * @returns The CoInvestorLayOut component is returning JSX elements.
 */
const CoInvestorLayOut = ({CO_INVESTOR_TAB,investorName}) => {
    const { investorId } = useParams()
    const { primaryMasterId } = useModule()

    return (
        <div className="px-4 bg-[#EDEDED] w-[100%]">
            <div className=" mt-4 bg-white border  overflow-hidden sm:rounded-lg">
                <div className="pt-4">
                    <div className="flex flex-col justify-between mb-3">
                        <h3 className="sm:px-6 mb-2 text-[14pt] leading-[19pt] text-[#333333] lg:text-[18px] lg:leading-[22px] font-semibold">Co-investors</h3>
                        <InvestorCo_Investors investorId={investorId} primaryMasterId={primaryMasterId} page="investor"/>
                        {/* <NavLink to={`/${primaryMasterId == 1 ? 'pe' : 'vc' }/co-investors`} state={{investorId:investorId,typeId:primaryMasterId}} className="font-sans_medium_body text-base text-[#A3751F] text-[13.5px] font-bold px-2">View All</NavLink> */}
                        <div className='flex justify-center items-center'>
                            <NavLink target='_blank' to={primaryMasterId == 1 ? routeNames.peCoInvestorById(investorId): routeNames.vcCoInvestorById(investorId)} state={{investorId:investorId,typeId:primaryMasterId}} className=" ml-8 mt-2 text-right cursor-pointer bg-[#BC8B20] text-white rounded px-4 py-1">View More</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CoInvestorLayOut
