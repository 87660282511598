import { React, useContext, useState, } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import IndustryFilter from "../../Components/RefineSearchComponents/IndustryFilter";
import SectorFilter from "../../Components/RefineSearchComponents/SectorFilter";
import StageFilter from "../../Components/RefineSearchComponents/StageFilter";
import RoundFilter from "../../Components/RefineSearchComponents/RoundFilter";
import CompanyTypeFilter from "../../Components/RefineSearchComponents/CompanyTypeFilter";
import YearFoundedFilter from "../../Components/RefineSearchComponents/YearFoundedFilter";
import DealTypeFilter from "../../Components/RefineSearchComponents/DealTypeFilter";
import SyndicationFilter from "../../Components/RefineSearchComponents/SyndicationFilter";
import DealsInvolvingFilter from "../../Components/RefineSearchComponents/DealsInvolvingFilter";
import CountryFilter from "../../Components/RefineSearchComponents/CountryFilter";
import RegionFilter from "../../Components/RefineSearchComponents/RegionFilter";
import StateFilter from "../../Components/RefineSearchComponents/StateFilter";
import CityFilter from "../../Components/RefineSearchComponents/CityFilter";
import InvestorTypeFilter from "../../Components/RefineSearchComponents/InvestorTypeFilter";
import InvestorHeadquartersFilter from "../../Components/RefineSearchComponents/InvestorHeadquartersFilter";
import DealRangeFilter from "../../Components/RefineSearchComponents/DealRangeFilter";
import ExitStatusFilter from "../../Components/RefineSearchComponents/ExitStatusFilter";
import ValuationFilter from "../../Components/RefineSearchComponents/ValuationFilter";
import FirmTypeFilter from "../RefineSearchComponents/FirmTypeFilter";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import axios from "axios";
import { filterLocationUrl, filterSectorByIndutry, tagList, tagSearchUrl } from "../../Config/config";
import { UserContext } from "../../Contexts/UserContext";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";
import { FaInfoCircle } from "react-icons/fa"
import { Modal, Tooltip } from "antd";
import DefinitionModal from "../TagDefinitionModal";
import useModule from "../../hooks/useModule";
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import Investor from "../RefineSearchComponents/Investor";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

/**
 * The component renders a form for refining search filters.
 * @returns a JSX element.
 */
export default function InvestmentRefineSearch({ customPathname, menu }) {
    const {
        dbDropdown , setDbDropdown ,isMobile,
        onErrorHandler,open,setOpen,
        refineSearchFilters,SwitchState,setSwitchState,dropdown,Sectors, setSectors,
        InvestorOptions , setInvestorOptions ,dealsView,checkToggleBtn,setToggleDealsBtn,
        lAdvisorsOptions,setlAdvisorsOptions,tAdvisorsOptions , settAdvisorsOptions , TargetCompanyOptions , setTargetCompanyOptions ,AllCities,
        setrefineSearchFilters ,setMonthPickerValue ,HeadquarterCityOptions,setHeadquarterCityOptions,selectedInvestorHeadquartersCity,setSelectedInvestorHeadquartersCity} = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(32);
    const navigate = useNavigate();
    const { getToken } = useContext(UserContext);
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false
    const isFromGroupList =()=> refineSearchFilters[location.pathname]?.myList?.length > 0 ? true : false


    //local updated options
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [StageOptions, setStageOptions] = useState([])
    const [RoundOptions, setRoundOptions] = useState([])
    const [CompanyTypeOptions, setCompanyTypeOptions] = useState([])
    const [EndYearOptions, setEndYearOptions] = useState([]);
    const [StartYearOptions, setStartYearOptions] = useState([])
    const [DealTypeOption, setDealTypeOption] = useState([]);
    const [SyndicationOptions, setSyndicationOptions] = useState([])
    const [DealsInvolvingOptions, setDealsInvolvingOptions] = useState([])
    const [CountryOptions, setCountryOptions] = useState([])
    const [RegionOptions, setRegionOptions] = useState([])
    const [StateOptions, setStateOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [InvestorTypeOptions, setInvestorTypeOptions] = useState([])
    const [HeadquartersOptions, setHeadquartersOptions] = useState([])
    const [FirmTypeOptions, setFirmTypeOptions] = useState([])
    const [DealRange, setDealRange] = useState({ from: "", to: "" })
    const [ExitStatusOptions, setExitStatusOptions] = useState([])
    const [ValuationOptions, setValuationOptions] = useState([])
    const [TagSwitchState, setTagSwitchState] = useState(true)
    const [TagsOptions, setTagsOptions] = useState([])


    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = Sectors.filter(option => option.isChecked);
        const selectedStages = StageOptions.filter(option => option.isChecked);
        const selectedRounds = RoundOptions.filter(option => option.isChecked);
        const selectedCompanyType = CompanyTypeOptions?.filter(option => option.isChecked);
        const selectedStartYear = StartYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedEndYear = EndYearOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedDealType = DealTypeOption?.filter(option => option.isChecked);
        const selectedSynd = SyndicationOptions?.filter(option => option.isChecked);
        const selectedDealInvolving = DealsInvolvingOptions?.filter(option => option.isChecked);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked);
        const selectedState = StateOptions?.filter(option => option.isChecked);
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedInvestorType = InvestorTypeOptions?.filter(option => option.isChecked);
        const selectedHeadCity = HeadquarterCityOptions?.filter(option => option.isChecked);
        const selectedHead = HeadquartersOptions?.filter(option => option.isChecked);
        const selectedHeadNames = selectedHeadCity.map(option => option.name);
        const selectedFirm = FirmTypeOptions?.filter(option => option.isChecked);
        const selectedExitStatus = ExitStatusOptions?.filter(option => option.isChecked);
        const selectedValuations = ValuationOptions?.filter(option => option.isChecked);
        const selectedOperators = SwitchState;
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedInvestor = InvestorOptions?.filter(option => option.isChecked); 
        
        checkToggleBtn();
        setSelectedInvestorHeadquartersCity(HeadquarterCityOptions?.filter(option => option.isChecked));
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                stage: selectedStages,
                round: selectedRounds,
                companyType: selectedCompanyType,
                yearFounded: selectedEndYear != null && selectedStartYear != null ? [selectedStartYear, selectedEndYear] : [],
                dealType: selectedDealType,
                syndication: selectedSynd,
                dealsInvolving: selectedDealInvolving,
                countries: selectedCountries,
                region: selectedRegion,
                investor_headquaters_city: selectedHeadCity,
                state: selectedState,
                city: selectedCity,
                investorType: selectedInvestorType,
                investorHeadquarters: selectedHead,
                firmType: selectedFirm,
                dealRange: DealRange?.from != "" && DealRange?.to != "" ? [{ id: 0, name: DealRange?.from }, { id: 1, name: DealRange?.to }] : [],
                exitStatus: selectedExitStatus,
                valuations: selectedValuations,
                operator: TagSwitchState ? 1 : 0,
                deals_operator : selectedOperators,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                investor_id : selectedInvestor ,
                target_company:selectedTargetCompany,
                tags: TagsOptions,
                // myList:isFromGroupList()? Object.keys(refineSearchFilters[location.pathname] ?? {}).map((filter) => {
                //     let tagList = [];
                //     tagList = refineSearchFilters[location.pathname][filter];
                //     return [{id:0,name:tagList?.name, group_id:tagList?.group_id}]

                // }):null

            },
        }))
    }

    const resetDealsByFilter = () => {
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setHeadquarterCityOptions(HeadquarterCityOptions.map(option => {
            if (option.isChecked) {
                return { ...option, isChecked: false };
            }
            return option;
        }));
        setlAdvisorsOptions([]);
        settAdvisorsOptions([]);
        setTargetCompanyOptions([]);
        setInvestorOptions([]);
        setSectors([]);
        setToggleDealsBtn(true);
    }

    useEffect(()=>{
        setToggleDealsBtn(true);
    },[customPathname])



 console.log(InvestorOptions , "jjjij")
    // Submitting Refine Search Form 
    const refineSearchFilter = (event) => {
        if(isMobile){
            setOpen(!open);
        }
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null || isCompanyMatch != null) {
        //     navigate(routeNames.pe_companies_pe_vc_investments);
        // }
        applyFilter();
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
        setSelected(false)

    }

    const onResetClick = (event) => {

        event.preventDefault();
        setCurrentPage(1);
        // if (isMatch == null || isCompanyMatch != null) {
        //     navigate(routeNames.pe_companies_pe_vc_investments);
        // }
        resetDealsByFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    const accordionClick = (val) => {
        if (val == select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    const onCountryChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: ids,
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status == 200) {
                setRegionOptions(response.data?.region)
                setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    const onRegionChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: ids,
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status == 200) {
                setStateOptions(response.data.state)
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }

    const onStateChange = (options) => {
        const ids = options.filter(industry => industry.isChecked)?.map(option => option.id);
        const selectedCountries = CountryOptions?.filter(option => option.isChecked)?.map(option => option.id);
        const selectedRegion = RegionOptions?.filter(option => option.isChecked)?.map(option => option.id);
        axios.post(filterLocationUrl, {
            country: selectedCountries,
            region: selectedRegion,
            state: ids,
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status == 200) {
                setCityOptions(response.data?.city)
            }
        }).catch(onErrorHandler)
    }


    // useEffect(() => {
    //     const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

    //     if (ids.length > 0) {
    //         axios.post(filterSectorByIndutry, {
    //             "industry": ids,
    //             category: "investments"
    //         }, {
    //             headers: {
    //                 "Authorization": getToken()
    //             }
    //         }).then(response => {
    //             if (response.status == 200) {
    //                 setSectors(response.data?.data ?? [])
    //             } else {
    //                 setSectors([])
    //             }
    //         }).catch(onErrorHandler)
    //     } else {
    //         setSectors([])
    //     }

    // }, [IndustriesOptions])

console.log(AllCities,"khhgjh")
    useEffect(() => {
        const countryIds = refineSearchFilters[customPathname]?.countries?.map(value => value.id) ?? [];
        const ids = refineSearchFilters[customPathname]?.region?.map(value => value.id) ?? [];
        const stateIds = refineSearchFilters[customPathname]?.state?.map(value => value.id) ?? [];
        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];
        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status == 200) {
                setCountryOptions(response.data?.countries?.map(option => ({ ...option, isChecked: countryIds.includes(option.id) })))
                setRegionOptions(response.data?.region?.map(option => ({ ...option, isChecked: ids.includes(option.id) })))
                setStateOptions(response.data.state?.map(option => ({ ...option, isChecked: stateIds.includes(option.id) })))
                setCityOptions(AllCities?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters,AllCities])

    const {primaryMasterId} = useModule();

    const getSearchTags = async (searchText, type) => {
        try {
        const response = await axios.post(tagList, {
            "tag": searchText,
            "type"    : type

        }, {
            headers: {
            "Authorization": getToken()
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
        } catch (error) {
            return [];
        }
    }


    const fetchData = async () => {
        const tags = await getSearchTags("ALL", "noncompetitor");
        const competitorTags = await getSearchTags("ALL", "competitor")
        console.log(tags, competitorTags, 'promi')
        openTagModal(tags, competitorTags)
    };
    
    const  [showDefinitionModal, setShowDefinitionModal] = useState(false);
    const [defValues, setDefValues] = useState({})
    console.log(defValues, 'showValues');

    const openTagModal = ( non_comp, comp) => {
        setShowDefinitionModal(true);
        setDefValues({
            non_comp: non_comp,
            comp: comp
        })
    }

    const defCloseHandler = () =>{
        setShowDefinitionModal(false);
        setDefValues({})
    }

    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };
    return (
        <div className="h-screen">
            <div className= {isFromGlobalSearch() ?"flex flex-col overflow-y-auto no-scrollbar h-[80%]" : "flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter"}>
                
                <form onSubmit={refineSearchFilter} onReset={onResetClick} className="bg-[#FAF5EA] w-full">
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                            <div className="flex flex-col">

                                <div className="flex justify-between px-3 items-center pt-0">
                                    <div className="flex items-end gap-2">
                                                        <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>                        
                                        <span className="cursor-pointer" onClick={() => {fetchData()}
                                        }>
                                            <Tooltip title='Tag List'>
                                                    <FaInfoCircle size={12} color="grey"/>
                                            </Tooltip>
                                        </span>
                                    </div>
                                    <Switch
                                        SwitchState={TagSwitchState}
                                        setSwitchState={setTagSwitchState}
                                        customPathname={customPathname} />
                                </div>

                                <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                                    <div className="bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                                        <label className=" flex  rounded ">
                                            <Tags
                                                TagsOptions={TagsOptions}
                                                customPathname={customPathname}
                                                setTagsOptions={setTagsOptions} 
                                                getSearchTags={getSearchTags}/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                open={select === 1} />
                            <SectorFilter
                                setOptions={setSectors}
                                options={Sectors}
                                label="Sector"
                                accordionClick={() => accordionClick(2)}
                                customPathname={customPathname}
                                open={select === 2} />
                            <StageFilter
                                StageOptions={StageOptions}
                                customPathname={customPathname}
                                setStageOptions={setStageOptions}
                                accordionClick={() => accordionClick(3)}
                                open={select === 3} 
                                menu={menu}
                            />
                            <RoundFilter
                                RoundOptions={RoundOptions}
                                setRoundOptions={setRoundOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(4)}
                                open={select === 4} 
                                menu={menu}
                                />
                            <CompanyTypeFilter
                                CompanyTypeOptions={CompanyTypeOptions}
                                setCompanyTypeOptions={setCompanyTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(5)}
                                open={select === 5} />
                            <YearFoundedFilter
                                EndYearOptions={EndYearOptions}
                                customPathname={customPathname}
                                setEndYearOptions={setEndYearOptions}
                                StartYearOptions={StartYearOptions}
                                setStartYearOptions={setStartYearOptions} />
                            <DealTypeFilter
                                DealTypeOption={DealTypeOption}
                                setDealTypeOption={setDealTypeOption}
                                customPathname={customPathname}
                                dataPath="investments"
                                accordionClick={() => accordionClick(7)}
                                open={select === 7} />
                            <SyndicationFilter
                                SyndicationOptions={SyndicationOptions}
                                setSyndicationOptions={setSyndicationOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(8)}
                                open={select === 8} />
                            <DealsInvolvingFilter
                                DealsInvolvingOptions={DealsInvolvingOptions}
                                setDealsInvolvingOptions={setDealsInvolvingOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(9)}
                                open={select === 9} />
                            <CountryFilter
                                CountryOptions={CountryOptions}
                                setCountryOptions={setCountryOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(10)}
                                onChange={onCountryChange}
                                open={select === 10} />
                            <RegionFilter
                                RegionOptions={RegionOptions}
                                setRegionOptions={setRegionOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(11)}
                                onChange={onRegionChange}
                                open={select === 11} />
                            <StateFilter
                                StateOptions={StateOptions}
                                setStateOptions={setStateOptions}
                                customPathname={customPathname}
                                onChange={onStateChange}
                                accordionClick={() => accordionClick(12)}
                                open={select === 12} />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(13)}
                                open={select === 13} />
                            <InvestorTypeFilter
                                InvestorTypeOptions={InvestorTypeOptions}
                                setInvestorTypeOptions={setInvestorTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(14)}
                                open={select === 14} />
                            <InvestorHeadquartersFilter
                                HeadquartersOptions={HeadquartersOptions}
                                setHeadquartersOptions={setHeadquartersOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(15)}
                                setHeadquarterCityOptions={setHeadquarterCityOptions}
                                HeadquarterCityOptions={HeadquarterCityOptions}
                                open={select === 15} />
                            <FirmTypeFilter
                                FirmTypeOptions={FirmTypeOptions}
                                setFirmTypeOptions={setFirmTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(16)}
                                dataPath="investments"
                                open={select === 16} />
                            <DealRangeFilter
                                DealRange={DealRange}
                                setDealRange={setDealRange}
                                customPathname={customPathname} />
                            <ExitStatusFilter
                                ExitStatusOptions={ExitStatusOptions}
                                setExitStatusOptions={setExitStatusOptions}
                                customPathname={customPathname}
                                dataPath="investments"
                                accordionClick={() => accordionClick(17)}
                                open={select === 17} />
                            <ValuationFilter
                                ValuationOptions={ValuationOptions}
                                setValuationOptions={setValuationOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(18)}
                                open={select === 18} />

                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                                <h1 className='font-bold text-[16px] ml-1'>
                                    {customPathname.includes("investors")
                                        ? "SHOW INVESTORS BY"
                                        : customPathname.includes("companies")
                                        ? dealsView
                                        ? "SHOW DEALS BY"
                                        : "SHOW DEALS BY"
                                        : "SHOW ADVISORS BY"}
                                </h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>

                                <Investor 
                                    options={InvestorOptions}
                                    setOptions={setInvestorOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(32)}
                                    label="Investor"
                                    open={select === 32}
                                />

                                <TargetCompany 
                                    options={TargetCompanyOptions}
                                    setOptions={setTargetCompanyOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(31)}
                                    label="Company"
                                    open={select === 31}
                                />

                                <LAdvisor 
                                    options={lAdvisorsOptions}
                                    setOptions={setlAdvisorsOptions}
                                    customPathname={customPathname}
                                    label="Legal Advisors"
                                    accordionClick={() => accordionClick(21)}
                                    open={select === 21}
                                />

                                <TAdvisor 
                                    options={tAdvisorsOptions}
                                    setOptions={settAdvisorsOptions}
                                    label="Transaction Advisors"
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(29)}
                                    open={select === 29}
                                />
                        </ul>
                    </main>
                    <div className="px-3  py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                        <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                            Reset
                        </button>
                        <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[13px] text-[#FFFFFF] rounded-[3px] font-sans_medium_body leading-[17px] ">
                            Filter
                        </button>
                    </div>
                    {/* reset button */}
                </form>
            </div>
            {showDefinitionModal && <DefinitionModal values={defValues} onClose={defCloseHandler}/>}
        </div>
    );
}

