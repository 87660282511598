import React, { useState, useEffect, useMemo, useContext } from "react";
import { useSearchParams, Routes, Route, useParams, Link, NavLink, useLocation, matchPath } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import xls from '../../../images/download-file-icon.png'
import sorting from '../../../images/up-down-arrow.png'
import companyLogo1 from '../../../images/mindtree-logo copy/png/mindtree-logo copy.png'
import companyLogo2 from '../../../images/mindtree-logo copy/png/mindtree-logo copy.png'
import companyLogo3 from '../../../images/logo2/png/logo2.png'
import ExcelPopup from '../../../Components/ExcelPopup'
import InvestorTimeline from './InvestorTimeline'
import sampleJson from './inv.json'
import CompanyLogo from '../../../Components/CompanyLogo';
import RandomLogo from '../../RandomLogo';
import { getCoinvestorApi, getCoInvestorUrl } from "../../../Config/config";
import axios from 'axios'
import moment from "moment";
import DataTable from '../../../Components/DataTables/DataTable';
import ExcelDownloader from "../../shared/ExcelDownloader";
import { exportExcel } from "../../../Config/config";
import { routeNames } from "../../../routeSegments";
import routePathSegments from "../../../routeSegments";
import OverflowToolTip from "../../shared/OverflowToolTip";
import AvatarGroup from "./AvatarGroup";
import { RaiseRequestContext } from '../../../Contexts/RaiseRequestContext'
import ChatPopup from "../../../Components/ChatPopup";
import Paginate from '../../../Components/Paginate';
import { UserContext } from "../../../Contexts/UserContext";
import useModule from "../../../hooks/useModule";
import Loader from "../../Loader";
import Constants from "../../../Constants";
import ConfirmPopup from "../../../Utils/ConfirmPopup";

const InvestorCo_Investors = ({ page }) => {
    // console.log("coInvestor2", CO_INVESTOR_TAB)
    const [coInvestor, setCoInvestor] = useState([]);
    const [showXlDownload, setshowXlDownload] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [activePage, setActivePage] = useState(1)
    const location = useLocation();
    const { investorId } = useParams();
    const { getToken, isStudent } = useContext(UserContext);
    const { primaryMasterId } = useModule();
    const [alert, setalert] = useState({ message: "", show: false });

    let patternInfoLength;

   /**
    * The function `getCoInvestorData` makes a POST request to a specified URL with an investor ID and
    * type, and sets the response data as the co-investor data, and also sets a flag to show or hide a
    * download button based on the length of the data.
    */
    const getCoInvestorData = () => {
        setIsLoading(true);
        axios.post(getCoInvestorUrl, {
            "investor_id": investorId,
            "type": primaryMasterId
        }, {
            headers: {
                'Authorization': getToken(),
            }
        }).then(response => {
            if (response.status === 200) {
                setCoInvestor(response.data.data);
                setshowXlDownload(response.data?.data?.length!==0)
                setIsLoading(false)
            }
        }).catch(error => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getCoInvestorData();
    }, [investorId])

    const CoInvestors = useMemo(() => coInvestor, [coInvestor])

   /**
    * The function `onConfirmation` sets the `show` property of an `alert` object to `false` and clears
    * the `message` property.
    * @param flag - The flag parameter is a boolean value that determines whether to show or hide the
    * alert.
    */
    const onConfirmation = (flag) => {
        setalert({ show: false, message: "" })
    }

   /**
    * The function `onDisabledLinkClick` sets an alert message indicating that the user does not have
    * permission to perform the action.
    */
    const onDisabledLinkClick = () => {
        setalert({ show: true, message: Constants.youDontHavePermissionMsg, isAlertOnly: true, closeBtnName: "OK" })
    }


    const investorName = useMemo(() => {
        return CoInvestors?.at(0) ? CoInvestors[0]?.investor_info?.at(0)?.name ?? "" : "";
    }, [CoInvestors])


    const isVcCompanyMatch = matchPath({ path: `${routePathSegments.vc}/*` }, location.state?.prevPath ?? "")
    return (
        <>
            {page == "investor" ? <div>
                <div className="w-full overflow-x-auto h-[70%]">
                        {!isLoading
                            ? CoInvestors?.length > 0
                                ? <table className="w-full overflow-x-auto scrollbar">
                                    <thead className=" text-[12pt] leading-[15pt] bg-[#EBEBEB] lg:text-[13px] text-[#666666] lg:leading-[16px] font-bold py-4 px-0 text-center sticky top-0 z-10">
                                        <th className="w-[15%] py-3.5 pr-3 text-left pl-4 ">
                                            <div className="flex">
                                                <span>Company </span>
                                                {/* <img alt="" className="pl-1 block h-4 py-0.5" src={sorting} /> */}
                                            </div>
                                        </th>
                                        <th className="w-[18%] py-3.5 pr-3 pl-4 ">
                                            <div className="flex">
                                                <span>Prior to </span>
                                                {/* <img alt="" className="pl-1 block h-4 py-0.5" src={sorting} /> */}
                                            </div>
                                        </th>
                                        <th className="w-[39%] py-3.5 ">
                                            <div className="flex items-center">
                                                <span className="whitespace-nowrap">Along with</span>
                                                {/* <img alt="" className="pl-1 block h-4 py-0.5" src={sorting} /> */}
                                            </div>
                                        </th>
                                        <th className="w-[18%] py-3.5 pr-3 text-left pl-4 ">
                                            <div className="flex">
                                                <span>After</span>
                                                {/* <img alt="" className="pl-1 block h-4 py-0.5" src={sorting} /> */}
                                            </div>
                                        </th>



                                    </thead>

                                    <tbody className="text-[12pt] lg:text-[15px] text-[#333333] leading-[15pt] lg:leading-[18px] font-sans_book_body text-left ">
                                        {CoInvestors.slice((activePage - 1) * 2, ((activePage - 1) * 2) + 2)?.map((data, i) => {
                                            return (
                                                <tr className="border-b">
                                                    <td className="lg:px-6 px-3.5 py-4 ">
                                                        {<NavLink onClick={(e) => {
                                                            if (!data.redirection) {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                onDisabledLinkClick();
                                                            }
                                                        }} to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peCompanyFundById(data.comapny_id) : routeNames.vcCompanyFundById(data.comapny_id)} >
                                                            <span style={{ display: "flex" }} className="lg:grid grid-cols-4 gap-1 whitespace-nowrap leading-[2rem] font-sans_book_body items-center card-animate">
                                                                {data?.company_logo_path == "default.png" || data?.company_logo_path == "/default.png" || !data?.company_logo_path ?
                                                                    <RandomLogo name={data?.company_name} page="company page" className="col-span-3" />
                                                                    :
                                                                    <CompanyLogo name={data?.company_name} id={data.comapny_id} page="company page" logo={data?.company_logo_path} className="col-span-3" />

                                                                }
                                                                <OverflowToolTip component={
                                                                    <span>{data?.company_name}</span>} />
                                                            </span>
                                                        </NavLink>}
                                                    </td>
                                                    <td className="px-5">
                                                        {data?.prior?.length > 0
                                                            ? <OverflowToolTip className="sm:max-w-[14rem] xl:max-w-[17rem] 2xl:max-w-[22rem]" component={
                                                                data?.prior?.map((prior, index) => (<Link onClick={(e) => {
                                                                    if (!data.redirection) {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        onDisabledLinkClick();
                                                                    }
                                                                }} className='hover:text-[#BC8B20]' state={{ prevPath: location.pathname }} to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peInvestorById(prior.id) : routeNames.vcInvestorById(prior.id)}>
                                                                    {`${prior.name}${index === data?.prior?.length - 1 ? "" : ", "}`}
                                                                </Link>))

                                                            } />
                                                            : <div className="group block flex-shrink-0">
                                                                <div className="flex items-center">
                                                                    <div className="">
                                                                        <p className="text-[12pt] lg:text-[15px] text-[#333333] leading-[15pt] lg:leading-[18px] font-sans_book_body text-left">No Investments</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    </td>
                                                    {/* { data?.along_with?.length >0 ?
                                                    data?.along_with.map((along, i) => {
                                                        patternInfoLength = along?.pattern_info?.length
                                                    })
                                                    :
                                                    data?.investor_info.map((along, i) => {
                                                        patternInfoLength = along?.pattern_info?.length
                                                    })
                                                    } */}
                                                    {data?.along_with.map((along, i) => {
                                                        patternInfoLength = along?.pattern_info?.length
                                                    })}
                                                    <td className={"py-4  " + (patternInfoLength >= 6 ? 'overflow-y-hidden pl-0' : '')
                                                    }>
                                                        {/* <InvestorTimeline data={data?.along_with?.length >0 ? data?.along_with : data?.investor_info} SelfInvestor={data?.along_with?.length <=0} onDisabledLinkClick={onDisabledLinkClick} redirection={data.redirection} /> */}
                                                        <InvestorTimeline data={data?.along_with??[]} SelfInvestor={data?.along_with?.length <=0} onDisabledLinkClick={onDisabledLinkClick} redirection={data.redirection} />
                                                    </td>
                                                    <td className="px-5">
                                                        {data?.after.length > 0
                                                            ? <OverflowToolTip component={
                                                                data?.after?.map((after, index) => (<Link onClick={(e) => {
                                                                    if (!data.redirection) {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        onDisabledLinkClick();
                                                                    }
                                                                }} className='hover:text-[#BC8B20]' state={{ prevPath: location.pathname }} to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peInvestorById(after.id) : routeNames.vcInvestorById(after.id)}>
                                                                    {`${after.name}${index === data?.after?.length - 1 ? "" : ", "}`}
                                                                </Link>))

                                                            } />
                                                            : <div className="group block flex-shrink-0">
                                                                <div className="flex items-center">
                                                                    <div className="">
                                                                        <p className="text-[12pt] lg:text-[15px] text-[#333333] leading-[15pt] lg:leading-[18px] font-sans_book_body text-left">No Investments</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody> 
                                </table>
                                : <div className=" text-[12pt] leading-[15pt] bg-[#EBEBEB] lg:text-[13px] text-[#666666] lg:leading-[16px] font-bold py-1 px-0 text-center"> 
                                    <div className="flex justify-between">                                                                              
                                        <th className="text-left py-3.5 pl-6">
                                            <div className="flex">
                                                <span>Company </span>
                                            </div>
                                        </th>
                                        <th className="text-left py-3.5 pl-4">
                                            <div className="flex">
                                                <span>Prior to </span>
                                            </div>
                                        </th>
                                        <th className="text-left py-3.5 pl-4">
                                            <div className="flex items-center">
                                                <span>Along with</span>
                                            </div>
                                        </th>
                                        <th className="text-left pr-6 py-3.5">
                                            <div className="flex">
                                                <span>After</span>
                                            </div>
                                        </th>
                                    </div>
                                    <div className='w-full text-[#333333] h-[5rem] bg-white flex items-center justify-center font-sans_book_body  mx-auto text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative  text-center '>
                                    <p>No data found</p>
                                </div>
                                </div>
                            : <div className='w-full h-[17rem]  bg-white flex items-center justify-center '>
                                <Loader />
                            </div>
                        }

                    </div>
            </div> :
            <div className="px-4 bg-[#EDEDED] w-[100%] h-screen overflow-y-hidden">
                <div className=" mt-[3.5rem] bg-white border  overflow-hidden sm:rounded-lg h-full">
                    <div className="px-4 pt-4 sm:px-6">
                        <div className="flex justify-between mb-3">
                            <h3 className=" text-[14pt] leading-[19pt] text-[#333333] lg:text-[18px] lg:leading-[22px] font-semibold">Co-investors {investorName ? `of ${investorName}`:""}</h3>
                            {/* {(isStudent == 'false'  && showXlDownload) && <ExcelDownloader
                                url={exportExcel}
                                data={{
                                    "type":primaryMasterId,
                                    "export_from": "detail",
                                    "module_type":"investors",
                                    "category": "incubation",
                                    "section":"co_investors",
                                "investor_id": [investorId]
                                }} />} */}
                        </div>
                    </div>
                    <div className="w-full overflow-x-auto h-[70%]">
                        {!isLoading
                            ? CoInvestors?.length > 0
                                ? <table className="w-full overflow-x-auto scrollbar">
                                    <thead className=" text-[12pt] leading-[15pt] bg-[#EBEBEB] lg:text-[13px] text-[#666666] lg:leading-[16px] font-bold py-4 px-0 text-center sticky top-0 z-10">
                                        <th className="w-[15%] py-3.5 pr-3 text-left pl-4 ">
                                            <div className="flex">
                                                <span>Company </span>
                                                {/* <img alt="" className="pl-1 block h-4 py-0.5" src={sorting} /> */}
                                            </div>
                                        </th>
                                        <th className="w-[18%] py-3.5 pr-3 pl-4 ">
                                            <div className="flex">
                                                <span>Prior to </span>
                                                {/* <img alt="" className="pl-1 block h-4 py-0.5" src={sorting} /> */}
                                            </div>
                                        </th>
                                        <th className="w-[39%] py-3.5 ">
                                            <div className="flex items-center">
                                                <span className="whitespace-nowrap">Along with</span>
                                                {/* <img alt="" className="pl-1 block h-4 py-0.5" src={sorting} /> */}
                                            </div>
                                        </th>
                                        <th className="w-[18%] py-3.5 pr-3 text-left pl-4 ">
                                            <div className="flex">
                                                <span>After</span>
                                                {/* <img alt="" className="pl-1 block h-4 py-0.5" src={sorting} /> */}
                                            </div>
                                        </th>



                                    </thead>

                                    <tbody className="text-[12pt] lg:text-[15px] text-[#333333] leading-[15pt] lg:leading-[18px] font-sans_book_body text-left ">
                                        {CoInvestors.slice((activePage - 1) * 10, ((activePage - 1) * 10) + 10)?.map((data, i) => {
                                            return (
                                                <tr className="border-b">
                                                    <td className="lg:px-6 px-3.5 py-4 ">
                                                        {<NavLink onClick={(e) => {
                                                            if (!data.redirection) {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                onDisabledLinkClick();
                                                            }
                                                        }} to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peCompanyFundById(data.comapny_id) : routeNames.vcCompanyFundById(data.comapny_id)} >
                                                            <span style={{ display: "flex" }} className="lg:grid grid-cols-4 gap-1 whitespace-nowrap leading-[2rem] font-sans_book_body items-center card-animate">
                                                                {data?.company_logo_path == "default.png" || data?.company_logo_path == "/default.png" || !data?.company_logo_path ?
                                                                    <RandomLogo name={data?.company_name} page="company page" className="col-span-3" />
                                                                    :
                                                                    <CompanyLogo name={data?.company_name} id={data.comapny_id} page="company page" logo={data?.company_logo_path} className="col-span-3" />

                                                                }
                                                                <OverflowToolTip component={
                                                                    <span>{data?.company_name}</span>} />
                                                            </span>
                                                        </NavLink>}
                                                    </td>
                                                    <td className="px-5">
                                                        {data?.prior?.length > 0
                                                            ? <OverflowToolTip className="sm:max-w-[14rem] xl:max-w-[17rem] 2xl:max-w-[22rem]" component={
                                                                data?.prior?.map((prior, index) => (<Link onClick={(e) => {
                                                                    if (!data.redirection) {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        onDisabledLinkClick();
                                                                    }
                                                                }} className='hover:text-[#BC8B20]' state={{ prevPath: location.pathname }} to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peInvestorById(prior.id) : routeNames.vcInvestorById(prior.id)}>
                                                                    {`${prior.name}${index === data?.prior?.length - 1 ? "" : ", "}`}
                                                                </Link>))

                                                            } />
                                                            : <div className="group block flex-shrink-0">
                                                                <div className="flex items-center">
                                                                    <div className="">
                                                                        <p className="text-[12pt] lg:text-[15px] text-[#333333] leading-[15pt] lg:leading-[18px] font-sans_book_body text-left">No Investments</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    </td>
                                                    {/* { data?.along_with?.length >0 ?
                                                    data?.along_with.map((along, i) => {
                                                        patternInfoLength = along?.pattern_info?.length
                                                    })
                                                    :
                                                    data?.investor_info.map((along, i) => {
                                                        patternInfoLength = along?.pattern_info?.length
                                                    })
                                                    } */}
                                                    {data?.along_with.map((along, i) => {
                                                        patternInfoLength = along?.pattern_info?.length
                                                    })}
                                                    <td className={"py-4  " + (patternInfoLength >= 6 ? 'overflow-y-hidden pl-0' : '')
                                                    }>
                                                        {/* <InvestorTimeline data={data?.along_with?.length >0 ? data?.along_with : data?.investor_info} SelfInvestor={data?.along_with?.length <=0} onDisabledLinkClick={onDisabledLinkClick} redirection={data.redirection} /> */}
                                                        <InvestorTimeline data={data?.along_with??[]} SelfInvestor={data?.along_with?.length <=0} onDisabledLinkClick={onDisabledLinkClick} redirection={data.redirection} />
                                                    </td>
                                                    <td className="px-5">
                                                        {data?.after.length > 0
                                                            ? <OverflowToolTip component={
                                                                data?.after?.map((after, index) => (<Link onClick={(e) => {
                                                                    if (!data.redirection) {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        onDisabledLinkClick();
                                                                    }
                                                                }} className='hover:text-[#BC8B20]' state={{ prevPath: location.pathname }} to={primaryMasterId == Constants.primaryMasterIds.peId ? routeNames.peInvestorById(after.id) : routeNames.vcInvestorById(after.id)}>
                                                                    {`${after.name}${index === data?.after?.length - 1 ? "" : ", "}`}
                                                                </Link>))

                                                            } />
                                                            : <div className="group block flex-shrink-0">
                                                                <div className="flex items-center">
                                                                    <div className="">
                                                                        <p className="text-[12pt] lg:text-[15px] text-[#333333] leading-[15pt] lg:leading-[18px] font-sans_book_body text-left">No Investments</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody> 
                                </table>
                                : <div className=" text-[12pt] leading-[15pt] bg-[#EBEBEB] lg:text-[13px] text-[#666666] lg:leading-[16px] font-bold py-1 px-0 text-center"> 
                                    <div className="flex justify-between">                                                                              
                                        <th className="text-left py-3.5 pl-6">
                                            <div className="flex">
                                                <span>Company </span>
                                            </div>
                                        </th>
                                        <th className="text-left py-3.5 pl-4">
                                            <div className="flex">
                                                <span>Prior to </span>
                                            </div>
                                        </th>
                                        <th className="text-left py-3.5 pl-4">
                                            <div className="flex items-center">
                                                <span>Along with</span>
                                            </div>
                                        </th>
                                        <th className="text-left pr-6 py-3.5">
                                            <div className="flex">
                                                <span>After</span>
                                            </div>
                                        </th>
                                    </div>
                                    <div className='w-full text-[#333333] h-[5rem] bg-white flex items-center justify-center font-sans_book_body  mx-auto text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative  text-center '>
                                    <p>No data found</p>
                                </div>
                                </div>
                            : <div className='w-full h-[17rem]  bg-white flex items-center justify-center '>
                                <Loader />
                            </div>
                        }

                    </div>
                    <div>
                        {CoInvestors?.length > 0 ? (
                            <Paginate
                                className="pagination-bar"
                                currentPage={activePage}
                                totalCount={CoInvestors?.length}
                                pageSize={20}
                                onPageChange={(page) => setActivePage(page)}
                            />

                        ) : (
                            ""
                        )}
                    </div>
                </div>
                {<ConfirmPopup {...alert} onChange={onConfirmation} />}
                {/* {open && <ChatPopup page="pe" component="coInvestor" data={false} /> } */}
            </div>}
        </>
    );
};

export default InvestorCo_Investors;
