export const data1 = {
    "options": [
        {
            "id": 1,
            "name": "Company Profile",
            "isChecked": false,
            "hasMore": false
        },
        {
            "id": 2,
            "name": "Company Profile  + PE-VC Investments",
            "isChecked": false,
            "hasMore": true,
            "options": [
                {
                    "id": 100,
                    "name": "All",
                    "value": "all",
                    "isChecked": false,
                    "hasMore": false
                },
                {
                    "id": 1,
                    "name": "Profile",
                    "value": "profile",
                    "isChecked": false,
                    "hasMore": true,
                    "options": [
                        {
                            "id": 100,
                            "name": "All",
                            "value": "all",
                            "isChecked": false,
                            "hasMore": false
                        },
                        {
                            "id": 1,
                            "name": "Industry",
                            "value": "Industry",
                            "isChecked": false,
                            "hasMore": false
                        },
                        {
                            "id": 2,
                            "name": "Sector",
                            "value": "Sector",
                            "isChecked": false,
                            "hasMore": false,
                        },
                        {
                            "id": 3,
                            "name": "CIN",
                            "value": "CIN No",
                            "isChecked": false,
                            "hasMore": false
                        },
                        {
                            "id": 4,
                            "name": "Location",
                            "value": "Country",
                            "isChecked": false,
                            "hasMore": false
                        },
                        {
                            "id": 5,
                            "name": "Website",
                            "value": "Website",
                            "isChecked": false,
                            "hasMore": false
                        },
                        {
                            "id": 6,
                            "name": "Type",
                            "value": "Company Type",
                            "isChecked": false,
                            "hasMore": false
                        },
                        {
                            "id": 7,
                            "name": "Top Management",
                            "value": "Top Management",
                            "isChecked": false,
                            "hasMore": false
                        },
                        {
                            "id": 8,
                            "name": "Contact",
                            "value": "Contact",
                            "isChecked": false,
                            "hasMore": false
                        },
                        {
                            "id": 9,
                            "name": "Investor Board Member",
                            "value": "Investor Board Member",
                            "isChecked": false,
                            "hasMore": false
                        }
                    ]
                },
                {
                    "id": 2,
                    "name": "Deal Info",
                    "isChecked": false,
                    "hasMore": true,
                    "options": [
                        {
                            "id": 100,
                            "name": "All",
                            "value": "all",
                            "isChecked": false
                        },
                        {
                            "id": 1,
                            "name": "Amount ($ M)",
                            "value": "Amount(US\\$M)",
                            "isChecked": false
                        },
                        {
                            "id": 2,
                            "name": "Amount (₹ Cr)",
                            "value": "Amount(INR Cr)",
                            "isChecked": false
                        },
                        {
                            "id": 3,
                            "name": "Exit Status",
                            "value": "Exit Status",
                            "isChecked": false
                        },
                        {
                            "id": 4,
                            "name": "Date",
                            "value": "Date",
                            "isChecked": false
                        },
                        {
                            "id": 5,
                            "name": "Stake",
                            "value": "Stake (%)",
                            "isChecked": false
                        },
                        {
                            "id": 6,
                            "name": "Stage",
                            "value": "Stage",
                            "isChecked": false
                        },
                        {
                            "id": 7,
                            "name": "Round",
                            "value": "Round",
                            "isChecked": false
                        },
                        {
                            "id": 8,
                            "name": "News Link",
                            "value": "Link",
                            "isChecked": false
                        },
                        {
                            "id": 9,
                            "name": "Price Per Share",
                            "value": "Price Per Share",
                            "isChecked": false
                        },
                        {
                            "id": 10,
                            "name": "BV Per Share",
                            "value": "Book Value Per Share",
                            "isChecked": false
                        },
                        {
                            "id": 11,
                            "name": "Price To Book",
                            "value": "Price to Book",
                            "isChecked": false
                        }
                    ]

                },
                {
                    "id": 3,
                    "name": "Valuation Info",
                    "isChecked": false
                },
                {
                    "id": 4,
                    "name": "Investor Info",
                    "isChecked": false
                },
                {
                    "id": 5,
                    "name": "Financials",
                    "isChecked": false
                },
                {
                    "id": 6,
                    "name": "Advisor-Company",
                    "isChecked": false
                },
                {
                    "id": 7,
                    "name": "Advisor-Investor",
                    "isChecked": false
                }
            ]
        }
    ]
};

const data = {
    category: {
        profile: "profile",
        investment: "investments",
        exits: "exits",
        ipos: "ipos",
        angel: "angel",
        incubation: "incubation",
       

    },
    categoryKeys: {
        investment: "inv_deal_columns",
        exits: "exit_deal_columns",
        ipos: "ipo_deal_columns",
        angel: "angel_deal_columns",
        incubation: "incb_deal_columns",
        profile: "profile",
       
    },
    initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,


        },
        {
            "id": "2",
            "name": "PE-VC Investments",
            "fileName": "PE-VC Investments",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,

        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,

        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "profile",
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "ipos",

        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],
    "pevc": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevc",
            "category": "investment",
        },
        {
            "id": 21,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevcProfile",
            "category": "investment",
            "parent": "pevc",
        },
        {
            "id": 22,
            "name": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevcDealinfo",
            "category": "investment",
            "parent": "pevc",

        },
        {
            "id": 23,
            "name": "Valuation Info",
            "category": "investment",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevcValuationinfo",
            "parent": "pevc",
        },
        {
            "id": 24,
            "name": "Investor Info",
            "category": "investment",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevcInvestorinfo",
            "parent": "pevc",
        },
        {
            "id": 25,
            "name": "Financials",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevcFinancialinfo",
            "category": "investment",
            "parent": "pevc",
        },
        {
            "id": 26,
            "name": "Advisor-Company",
            "value": "Advisor-Company",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "pevcAdvisorCompany",
            "category": "investment",
            "parent": "pevc",
        },
        {
            "id": 27,
            "name": "Advisor-Investors",
            "value": "Advisor-Investors",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "pevcAdvisorInvestor",
            "category": "investment",
            "parent": "pevc",
        },
        {
            "id": 28,
            "name": "More Info (Deal Structure)",
            "value": "More Info",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "pevcMoreInfo",
            "category": "investment",
            "parent": "pevc",
        }
    ],
    "pevcProfile": [


        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        {
            "id": 217,
            "name": "Company Name",
            "value": "Company Name",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        {
            "id": 219,
            "name": "Registered Entity Name",
            "value": "Registered Entity Name",
            "isChecked": true,
            "parent": "pevcProfile",
            "category": "investment",
        },
        {
            "id": 211,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        {
            "id": 212,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        {
            "id": 213,
            "name": "CIN",
            "value": "CIN",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        {
            "id": 214,
            "name": "Location (City, State, Region, Country)",
            "value": "Location",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        {
            "id": 215,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        {
            "id": 216,
            "name": "Type",
            "value": "Company Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        // {
        //     "id": 217,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pevcProfile",
        //     "category": "investment",
        // },
        {
            "id": 218,
            "name": "Contact (Email and Phone)",
            "value": "Contact",
            "isChecked": true,
            "hasMore": false,
            "parent": "pevcProfile",
            "category": "investment",
        },
        // {
        //     "id": 219,
        //     "name": "Investor Board Member",
        //     "value": "Investor Board Member",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pevcProfile",
        //     "category": "investment",
        // },
    ],
    "pevcDealinfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "pevcDealinfo",
        },
        {
            "id": 221,
            "name": "Amount ($ M)",
            "value": "Amount(US\$M)",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 222,
            "name": "Amount (₹ Cr)",
            "value": "Amount(INR Cr)",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 223,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 224,
            "name": "Date",
            "value": "Date",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 225,
            "name": "Stake",
            "value": "Stake (%)",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 226,
            "name": "Stage",
            "value": "Stage",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 227,
            "name": "Round",
            "value": "Round",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 2270,
            "name": "Round(By Series)",
            "value": "Round(By Series)",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 228,
            "name": "News Link",
            "value": "Link",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 229,
            "name": "Price Per Share",
            "value": "Price Per Share",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 2210,
            "name": "BV Per Share",
            "value": "Book Value Per Share",
            "isChecked": true,
            "parent": "pevcDealinfo",
            "category": "investment",
        },
        {
            "id": 2211,
            "name": "Price To Book",
            "value": "Price to Book",
            "category": "investment",
            "isChecked": true,
            "parent": "pevcDealinfo",
        },
        {
            "id": 2212,
            "name": "Equity/Debt",
            "value": "Equity/Debt",
            "category": "investment",
            "isChecked": true,
            "parent": "pevcDealinfo",
        },
        {
            "id": 2213,
            "name": "Valuation",
            "value": "Valuation",
            "category": "investment",
            "isChecked": true,
            "parent": "pevcDealinfo",
        },
        {
            "id": 2214,
            "name": "Year Founded",
            "value": "Year Founded",
            "category": "investment",
            "isChecked": true,
            "parent": "pevcDealinfo",
        },
    ],
    "pevcValuationinfo": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 231,
            name: "Pre Money Valuation (INR Cr)",
            value: "Pre Money Valuation (INR Cr)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 232,
            name: "Revenue Multiple (Pre)",
            value: "Revenue Multiple (Pre)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 233,
            name: "EBITDA Multiple (Pre)",
            value: "EBITDA Multiple (Pre)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 234,
            name: "PAT Multiple (Pre)",
            value: "PAT Multiple (Pre)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 235,
            name: "Post Money Valuation (INR Cr)",
            value: "Post Money Valuation (INR Cr)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 236,
            name: "Revenue Multiple (Post)",
            value: "Revenue Multiple (Post)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 237,
            name: "EBITDA Multiple (Post)",
            value: "EBITDA Multiple (Post)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        }, {
            id: 238,
            name: "PAT Multiple (Post)",
            value: "PAT Multiple (Post)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 239,
            name: "Enterprise Valuation (INR Cr)",
            value: "Enterprise Valuation (INR Cr)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 2310,
            name: "Revenue Multiple (EV)",
            value: "Revenue Multiple (EV)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 2311,
            name: "EBITDA Multiple (EV)",
            value: "EBITDA Multiple (EV)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },
        {
            id: 2312,
            name: "PAT Multiple (EV)",
            value: "PAT Multiple (EV)",
            isChecked: true,
            "parent": "pevcValuationinfo",
            "category": "investment",
        },

    ],
    "pevcInvestorinfo": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "pevcInvestorinfo",
            "category": "investment",
        },
        {
            id: 241,
            name: "Investor Name",
            value: "Investors",
            isChecked: true,
            parent: "pevcInvestorinfo",
            "category": "investment",
        },
        {
            id: 242,
            name: "Investor Type",
            value: "Investor Type",
            isChecked: true,
            parent: "pevcInvestorinfo",
            "category": "investment",
        }
    ],
    "pevcFinancialinfo": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "pevcFinancialinfo",
            "category": "investment",
        },
        {
            id: 251,
            name: " Financial Year",
            value: " Financial Year",
            isChecked: true,
            parent: "pevcFinancialinfo",
            "category": "investment",
        },
        {
            id: 252,
            name: "Revenue  (INR Cr)",
            value: "Revenue (INR Cr)",
            isChecked: true,
            parent: "pevcFinancialinfo",
            "category": "investment",
        },
        {
            id: 253,
            name: "EBITDA  (INR Cr)",
            value: "EBITDA (INR Cr)",
            isChecked: true,
            parent: "pevcFinancialinfo",
            "category": "investment",
        },
        {
            id: 254,
            name: "PAT  (INR Cr)",
            value: "PAT (INR Cr)",
            isChecked: true,
            parent: "pevcFinancialinfo",
            "category": "investment",
        },
        {
            id: 255,
            name: "Total Debt  (INR Cr)",
            value: "Total Debt (INR Cr)",
            isChecked: true,
            parent: "pevcFinancialinfo",
            "category": "investment",
        },
        {
            id: 256,
            name: "Cash & Cash Equ.  (INR Cr)",
            value: "Cash & Cash Equ. (INR Cr)",
            isChecked: true,
            parent: "pevcFinancialinfo",
            "category": "investment",
        }
    ],
    "pevcAdvisorCompany": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "pevcAdvisorCompany",
            "category": "investment",
        },
        {
            id: 261,
            name: "Advisor Name",
            value: "Advisor-Company",
            isChecked: true,
            parent: "pevcAdvisorCompany",
            "category": "investment",
        }
    ],
    "pevcAdvisorInvestor": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "pevcAdvisorInvestor",
            "category": "investment",
        },
        {
            id: 271,
            name: "Advisor Name",
            value: "Advisor-Investors",
            isChecked: true,
            parent: "pevcAdvisorInvestor",
            "category": "investment",
        }
    ],


    exit: [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "exit",
            "category": "exits",
        },
        {
            "id": 2111,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "exitProfile",
            "parent": "exit",
            "category": "exits",
        },
        {
            "id": 2112,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "exitDealInfo",
            "parent": "exit",
            "category": "exits",
        },
        {
            "id": 2113,
            "name": "Exit Details (Returns)",
            "value": "Exit Details (Returns)",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "exitDetailReturns",
            "parent": "exit",
            "category": "exits",
        },
        // {
        //     "id": 2112,
        //     "name": "Investor Info",
        //     "value": "Investor Info",
        //     "isChecked": true,
        //     "hasMore": true,
        //     "filterKey": "exitInvestorInfo",
        //     "parent": "exit",
        // },
        {
            "id": 2114,
            "name": "Valualtion Info",
            "value": "Valualtion Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "exitValuationInfo",
            "parent": "exit",
            "category": "exits",
        },


        {
            "id": 2115,
            "name": "More Info",
            "value": "More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "exitMoreInfo",
            "parent": "exit",
            "category": "exits",
        },

        {
            "id": 2116,
            "name": "Financials",
            "value": "Financials",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "exitFinancials",
            "parent": "exit",
            "category": "exits",
        },
        {
            "id": 2117,
            "name": "Advisor-Buyer",
            "value": "Advisor-Buyer",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "exitAdvisorBuyer",
            "parent": "exit",
            "category": "exits",
        },
        {
            "id": 2118,
            "name": "Advisor-Seller",
            "value": "Advisor-Seller",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "exitAdvisorSeller",
            "parent": "exit",
            "category": "exits",
        },
        {
            "id": 2119,
            "name": "Year Founded",
            "value": "Year Founded",
            "isChecked": true,
            "hasMore": false,
            "parent": "exit",
            "category": "exits",
        },
        {
            "id": 2220,
            "name": "Investor Type",
            "value": "Investor Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "exit",
            "category": "exits",
        },
    ],


    exitProfile: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitProfile",
            "category": "exits",
        },
        {
            id: 2751,
            name: "Company Name",
            value: "Portfolio Company",
            isChecked: true,
            parent: "exitProfile",
            "category": "exits",
        },
        {
            "id": 2758,
            "name": "Registered Entity Name",
            "value": "Registered Entity Name",
            "isChecked": true,
            "parent": "exitProfile",
            "category": "exits",
        },
        {
            id: 2754,
            name: "Industry",
            value: "Industry",
            isChecked: true,
            parent: "exitProfile",
            "category": "exits",
        },
        {
            id: 2755,
            name: "Sector",
            value: "Sector_Business Description",
            isChecked: true,
            parent: "exitProfile",
            "category": "exits",
        },
        {
            id: 2752,
            name: "CIN",
            value: "CIN",
            isChecked: true,
            parent: "exitProfile",
            "category": "exits",
        },
        {
            id: 2753,
            name: "Location (City, State, Region, Country)",
            value: "Location",
            isChecked: true,
            parent: "exitProfile",
            "category": "exits",
        },

        {
            id: 2756,
            name: "Website",
            value: "Website",
            isChecked: true,
            parent: "exitProfile",
            "category": "exits",
        },
        {
            id: 2757,
            name: "Type (Listed or Unlisted)",
            value: "Company Type",
            isChecked: true,
            parent: "exitProfile",
            "category": "exits",
        },
        // {
        //     id: 2758,
        //     name: "Top Management",
        //     value: "Top Management",
        //     isChecked: true,
        //     parent: "exitProfile",
        //     "category": "exits",
        // },
        // {
        //     id: 2759,
        //     name: "Investor Board Member",
        //     value: "Investor Board Member",
        //     isChecked: true,
        //     parent: "exitProfile",
        //     "category": "exits",
        // },
        // {
        //     id: 2760,
        //     name: "Contact (Email and Phone)",
        //     value: "Contact",
        //     isChecked: true,
        //     parent: "exitProfile",
        //     "category": "exits",
        // },

    ],
    exitDetailReturns: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitDetailReturns",
            "category": "exits",
        },
        {
            id: 2781,
            name: "Exiting Investors (Return Multiple, IRR)",
            value: "Exiting Investors",
            isChecked: true,
            parent: "exitDetailReturns",
            "category": "exits",
        },
        {
            id: 2782,
            name: "Return Multiple",
            value: "Return Multiple",
            isChecked: true,
            parent: "exitDetailReturns",
            "category": "exits",
        },
        {
            id: 2783,
            name: "IRR (%)",
            value: "IRR (%)",
            isChecked: true,
            parent: "exitDetailReturns",
            "category": "exits",
        },
    ],
    exitInvestorInfo: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitInvestorInfo",
            "category": "exits",
        },
        {
            id: 2851,
            name: "Investor Type",
            value: "Investor Type",
            isChecked: true,
            parent: "exitInvestorInfo",
            "category": "exits",
        },
    ],
    exitDealInfo: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2856,
            name: " Deal Size (US $M)",
            value: "Deal Amount (US\$M)",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2852,
            name: "Exit Status",
            value: "Exit Status",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2855,
            name: "Deal Period",
            value: "Deal Date",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2854,
            name: "Acquirer",
            value: "Acquirer",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2851,
            name: "Stake Sold",
            value: "Stake Sold(%)",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },


        {
            id: 2853,
            name: "Deal Type",
            value: "Deal Type",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2861,
            name: "Type",
            value: "Type",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },


        {
            id: 2857,
            name: "News Link",
            value: "Link",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2860,
            name: "price per share",
            value: "Price Per Share",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2859,
            name: "BV Per Share",
            value: "Book Value Per Share",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2955,
            name: "Country",
            value: "Country",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2958,
            name: "Region",
            value: "Region",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2959,
            name: "State",
            value: "State",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2960,
            name: "City",
            value: "City",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        {
            id: 2858,
            name: "Price to book",
            value: "Price to Book",
            isChecked: true,
            parent: "exitDealInfo",
            "category": "exits",
        },
        


    ],
    exitAdvisorSeller: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitAdvisorSeller",
            "category": "exits",
        },
        {
            id: 2851,
            name: "Advisor-Seller",
            value: "Advisor-Seller",
            isChecked: true,
            parent: "exitAdvisorSeller",
            "category": "exits",
        },
    ],
    exitAdvisorBuyer: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitAdvisorBuyer",
            "category": "exits",
        },
        {
            id: 3851,
            name: "Advisor-Buyer",
            value: "Advisor-Buyer",
            isChecked: true,
            parent: "exitAdvisorBuyer",
            "category": "exits",
        },
    ],
    exitMoreInfo: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitMoreInfo",
            "category": "exits",
        },
        {
            id: 3952,
            name: "Investment Details",
            value: "Investment Details",
            isChecked: true,
            parent: "exitMoreInfo",
            "category": "exits",
        },
        {
            id: 3951,
            name: "Addln Info",
            value: "Addln Info",
            isChecked: true,
            parent: "exitMoreInfo",
            "category": "exits",
        },

        {
            id: 3953,
            name: "More Info(Returns)",
            value: "More Info(Returns)",
            isChecked: true,
            parent: "exitMoreInfo",
            "category": "exits",
        },
    ],
    exitValuationInfo: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitValuationInfo",
            "category": "exits",
        },
        {
            id: 4151,
            name: "Company Valuation (INR Cr)",
            value: "Company Valuation (INR Cr)",
            isChecked: true,
            parent: "exitValuationInfo",
            "category": "exits",
        },
        {
            id: 4152,
            name: "Revenue Multiple",
            value: "Revenue Multiple",
            isChecked: true,
            parent: "exitValuationInfo",
            "category": "exits",
        },
        {
            id: 4153,
            name: "EBITDA Multiple",
            value: "EBITDA Multiple",
            isChecked: true,
            parent: "exitValuationInfo",
            "category": "exits",
        },
        {
            id: 4154,
            name: "PAT Multiple",
            value: "PAT Multiple",
            isChecked: true,
            parent: "exitValuationInfo",
            "category": "exits",
        },
        {
            id: 4155,
            name: "Valuation (More Info)",
            value: "Valuation (More Info)",
            isChecked: true,
            parent: "exitValuationInfo",
            "category": "exits",
        },
    ],
    exitFinancials: [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "exitFinancials",
            "category": "exits",
        },
        {
            id: 4251,
            name: "Revenue (INR Cr)",
            value: "Revenue (INR Cr)",
            isChecked: true,
            parent: "exitFinancials",
            "category": "exits",
        },
        {
            id: 4252,
            name: "EBITDA (INR Cr)",
            value: "EBITDA (INR Cr)",
            isChecked: true,
            parent: "exitFinancials",
            "category": "exits",
        },
        {
            id: 4253,
            name: "PAT (INR Cr)",
            value: "PAT (INR Cr)",
            isChecked: true,
            parent: "exitFinancials",
            "category": "exits",
        },
    ]
};

const vcInvestmentExportData = {
    ...data,
    initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": false,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,
        },
        {
            "id": "2",
            "name": "VC Investments",
            "isChecked": true,
            "fileName": "VC Investments",
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "withDetails": true,
            "hasMore": true,
            "parent": "initial",
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "withDetails": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "ipos"
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel"
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],
}

const exitExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "PE-VC Exits",
            "fileName": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "initial",
            "category": "exits",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],
}

const vcExitExportData = {
    ...vcInvestmentExportData, initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,
        },
        {
            "id": "2",
            "name": "VC Exits",
            "fileName": "Company Profile_VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "initial",
            "category": "exits",
            "isTransaction": false,
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],
}



const backedIPOExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false
        },
        {
            "id": "2",
            "name": "PE-VC Backed IPOs",
            "fileName": "PE-VC Backed IPOs",
            "isChecked": true,
            "filterKey": "",
            "category": "ipos",
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": false

        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": true
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "category": "angel",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "category": "incubation",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
    ],
}

const vcbackedIPOExportData = {
    ...vcInvestmentExportData, initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,
        },
        {
            "id": "2",
            "name": "VC Backed IPOs",
            "fileName": "VC Backed IPOs",
            "isChecked": true,
            "filterKey": "",
            "category": "ipos",
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "all",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],
}


const angelInvestmentExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "Angel Investments",
            "fileName": "Company Profile_Angel Investments",
            "isChecked": true,
            "filterKey": "",
            "category": "angel",
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": false,

        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "category": "incubation",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
    ],
}

const vcangelInvestmentExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "Angel Investments",
            "fileName": "Angel Investments",
            "isChecked": true,
            "filterKey": "",
            "category": "angel",
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": false,

        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "category": "incubation",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
    ],
}
const incubationExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false
        },
        {
            "id": "2",
            "name": "Incubation/Acceleration",
            "fileName": "Incubation_Acceleration",
            "isChecked": true,
            "filterKey": "",
            "category": "incubation",
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": true
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "incubation",
        },
    ],
}

const vcincubationExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false
        },
        {
            "id": "2",
            "name": "Incubation/Acceleration",
            "fileName": "Incubation_Acceleration",
            "isChecked": true,
            "filterKey": "",
            "category": "incubation",
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": true
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "fileName": "Company Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "incubation",
        },
    ],
}
const investorProfileExportData = {

    ...data, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "Investor Wise Data",
            "fileName": "PE-VC Investments",
            "isChecked": true,
            "category": "investment",
            "isTransaction": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "ipos",

        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],

}
const vcinvestorProfileExportData = {

    ...data, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "Investor Wise Data",
            "fileName": "VC Investments",
            "isChecked": true,
            "category": "investment",
            "isTransaction": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "ipos",

        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],

}
const investorexitExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "Investor Wise Data",
            "fileName": "PE-VC Exits",
            "isChecked": true,
            "hasMore": false,
            "parent": "initial",
            "category": "exits",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],
}
const vcinvestorexitExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "Investor Wise Data",
            "fileName": "VC Exits",
            "isChecked": true,
            "hasMore": false,
            "parent": "initial",
            "category": "exits",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "value": "ALL",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "incubation"
        },
    ],
}

const investorbackedIPOExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-VC Backed IPOs",
            "fileName": "PE-VC Backed IPOs",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "ipos",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "incubation",
        },
    ],
}
const vcinvestorbackedIPOExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "VC Backed IPOs",
            "fileName": "VC Backed IPOs",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "ipos",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "incubation",
        },
    ],
}
const investorAngelInvestmentExportData = {
    ...data, initial: [

        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "Angel Investments",
            "fileName": "Angel Investments",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "angel",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "incubation",
        },
    ],
}
const vcinvestorAngelInvestmentExportData = {
    ...data, initial: [

        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "Angel Investments",
            "fileName": "Angel Investments",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "angel",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "incubation",
        },
    ],
}
const investorincubationExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false
        },
        {
            "id": "2",
            "name": "Incubation/Acceleration",
            "fileName": "Incubation_Acceleration",
            "isChecked": true,
            "filterKey": "",
            "category": "incubation",
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": true
        }

    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "PE-VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "incubation",
        },
    ],
}
const vcinvestorincubationExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false
        },
        {
            "id": "2",
            "name": "Incubation/Acceleration",
            "fileName": "Incubation_Acceleration",
            "isChecked": true,
            "filterKey": "",
            "category": "incubation",
            "hasMore": false,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
            "isTransaction": true
        }

    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName": "Investor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 89,
            "name": "VC Backed IPOs",
            "isChecked": true,
            "category": "ipos",
            "value": "ALL",
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": 34,
            "name": "Angel Investments",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "angel",
        },
        {
            "id": 35,
            "name": "Incubation/Acceleration",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "value": "ALL",
            "category": "incubation",
        },
    ],
}

const advisorProfileExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Advisor Profile",
            "fileName": "Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-VC Investments",
            "fileName": "PE-VC Investments",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Advisor Profile",
            "fileName": "Advisor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },

    ],

}

const vcadvisorProfileExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Advisor Profile",
            "fileName": "Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "VC Investments",
            "fileName": "VC Investments",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Advisor Profile",
            "fileName": "Advisor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
        },

    ],

}

const advisorexitExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Advisor Profile",
            "fileName": "Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "PE-VC Exits",
            "fileName": "Advisor Profile_PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "initial",
            "category": "exits",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Advisor Profile",
            "fileName": "Advisor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",

        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "ALL",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },

    ],

}
const vcadvisorexitExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Advisor Profile",
            "fileName": "Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "VC Exits",
            "fileName": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "initial",
            "category": "exits",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Advisor Profile",
            "fileName": "Advisor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "ALL",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },

    ],

}
const transactionAdvisorProfileExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName": "Transaction Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "PE-VC Investments",
            "fileName": "PE-VC Investments",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName": "Transaction Advisor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },

    ],

}
const vctransactionAdvisorProfileExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName": "Transaction Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "VC Investments",
            "fileName": "VC Investments",
            "isChecked": true,
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName": "Transaction Advisor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },

    ],

}

const transactionAdvisorexitExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName": "Transaction Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "PE-VC Exits",
            "fileName": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "initial",
            "category": "exits",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        },
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName": "Transaction Advisor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "PE-VC Investments",
            "value": "PE-VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "PE-VC Exits",
            "value": "PE-VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },

    ],

}

const vctransactionAdvisorexitExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName": "Transaction Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "VC Exits",
            "fileName": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "initial",
            "category": "exits",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        },
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName": "Transaction Advisor Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile",
        },
        {
            "id": 26547,
            "name": "VC Investments",
            "value": "VC Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pevc",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "VC Exits",
            "value": "VC Exits",
            "isChecked": true,
            "filterKey": "exit",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },

    ],

}

const fundsExportData = {
    ...data, initial: [
        {
            "id": "1",
            "name": "Funds Profile",
            "fileName": "Funds Profile",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "funds",
            "category": "funds",
            "parent": "initial",
            "withDetails": false,
            "isTransaction": false,
        },
        {
            "id": "2",
            "name": "Funds Profile  + PE-VC Exits",
            "fileName": "Funds Profile_PE-VC Exits",
            "isChecked": false,
            "filterKey": "funds",
            "hasMore": true,
            "parent": "initial",
            "category": "funds",
            "withDetails": true,
            "isTransaction": false
        },
        {
            "id": "3",
            "name": "Funds Profile  + All Transactions",
            "fileName": "All",
            "isChecked": false,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "isTransaction": true,
            "withDetails": true,
        },
    ],


}
const limitedPartnerProfileExportData = {

    ...data, initial: [
        {
            "id": "1",
            "name": "Limited Partner Profile",
            "fileName": "Limited Partner Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "initial",
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "Limited Partner Profile  + PE-VC Investments",
            "fileName": "Limited Partner Profile_PE-VC Investments",
            "isChecked": false,
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "Limited Partner Profile  + All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],

}
const limitedPartnerexitExportData = {

    ...data, initial: [
        {
            "id": "1",
            "name": "Limited Partner Profile",
            "fileName": "Limited Partner Profile",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "exit",
            "category": "exits",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "Limited Partner Profile  + PE-VC Investments",
            "fileName": "Limited Partner Profile_PE-VC Investments",
            "isChecked": false,
            "filterKey": "exit",
            "category": "exits",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "Limited Partner Profile  + All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],


}
const vclimitedPartnerProfileExportData = {

    ...data, initial: [
        {
            "id": "1",
            "name": "Limited Partner Profile",
            "fileName": "Limited Partner Profile",
            "isChecked": true,
            "hasMore": false,
            "parent": "initial",
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "Limited Partner Profile  + VC Investments",
            "fileName": "Limited Partner Profile_VC Investments",
            "isChecked": false,
            "filterKey": "pevc",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "Limited Partner Profile  + All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],


}
const vclimitedPartnerexitExportData = {

    ...data, initial: [
        {
            "id": "1",
            "name": "Limited Partner Profile",
            "fileName": "Limited Partner Profile",
            "isChecked": true,
            "hasMore": false,
            "filterKey": "exit",
            "category": "exits",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "Limited Partner Profile  + VC Investments",
            "fileName": "Limited Partner Profile_VC Investments",
            "isChecked": false,
            "filterKey": "exit",
            "category": "exits",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "Limited Partner Profile  + All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
            "withDetails": true,
        }
    ],


}


const reInvestmentExportData = {

    category: {
        profile: "profile",
        investments: "re-investments",
        exits: "re-exits",
        ipo: "re-ipo",
        otherma: "other-manda",

    },
    categoryKeys: {
       
        investments: "inv_deal_columns",
        exits: "exit_deal_columns",
        ipo: "ipo_deal_columns",
        otherma: "other_ma_deal_columns",
    },
    
    initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": false,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,
        },
        {
            "id": "2",
            "name": "PE-RE Investments",
            "isChecked": true,
            "fileName": "Company Profile_PE-RE Investments",
            "filterKey": "pere",
            "category": "investments",
            "isTransaction": false,
            "withDetails": true,
            "hasMore": true,
            "parent": "initial",
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "withDetails": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
        }
    ],
    allTransaction: [
       

        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 26547,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pere",
            "parent": "allTransaction",
            "category": "investments"
        },

        {
            "id": 988,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "filterKey": "reComapnyExitProfile",
            "hasMore": true,
            "parent": "allTransaction",
            "category":"exits"
        },
        
        {
            "id": 989,
            "name": "PE-RE Backed IPOs",
            "value": "PE-RE backed IPOs",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "category": "ipo",
            "filterKey": "reIpoProfile"
        },
        {
            "id": 987,
            "name": "PE-RE Other M&A",
            "value": "PE-RE Other M&A",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "category": "otherma",
            "filterKey": "reOthermaProfile"
        },

    ],

    "pere": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pere",
            "category": "investments",
        },
        {
            "id": 21,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pereProfile",
            "category": "investments",
            "parent": "pere",
        },
        {
            "id": 22,
            "name": "Deal Info",
            "value":"Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pereDealinfo",
            "category": "investments",
            "parent": "pere",

        },

        {
            "id": 23,
            "name": "Investor Info",
            "value": "Investor Info",
            "category": "investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pereInvestorinfo",
            "parent": "pere",
        },


        {
            "id": 24,
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pereAdvisorinfo",
            "category": "investments",
            "parent": "pere",
        },
        {
            "id": 25,
            "name": "More Info ",
            "value": "More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pereMoreInfo",
            "category": "investments",
            "parent": "pere",
        }
    ],
    "pereProfile": [


        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereProfile",
            "category": "investments",
        },
        {
            "id": 415,
            "name": "Company",
            "value": "Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereProfile",
            "category": "investments",
        },

        {
            "id": 416,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereProfile",
            "category": "investments",
        },
        // {
        //     "id": 417,
        //     "name": "Type",
        //     "value": "Type",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereProfile",
        //     "category": "investments",
        // },
        // {
        //     "id": 418,
        //     "name": "Location (City, Country)",
        //     "value": "Location",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereProfile",
        //     "category": "investments",
        // },
        // {
        //     "id": 419,
        //     "name": "Address (Address, Zip)",
        //     "value": "Address",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereProfile",
        //     "category": "investments",
        // },
        // {
        //     "id": 420,
        //     "name": "Year Founded",
        //     "value": "Year Founded",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereProfile",
        //     "category": "investments",
        // },

        {
            "id": 421,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereProfile",
            "category": "investments",
        },


        // {
        //     "id": 422,
        //     "name": "Contact (Telephone, Email)",
        //     "value": "Contact",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereProfile",
        //     "category": "investments",
        // },
        // {
        //     "id": 423,
        //     "name": "More Information",
        //     "value": "More Information",
        //     "isChecked": true,
           
        //     "parent": "pereProfile",
        //     "category": "investments",
        // },
        // {
        //     "id": 424,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereProfile",
        //     "category": "investments",
        // },
        {
            "id": 425,
            "name": "Investor Board Member",
            "value": "Investor Board Member",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereProfile",
            "category": "investments",
        },
    ],

    "pereDealinfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "pereDealinfo",
        },
        {
            "id": 221,
            "name": "Deal Amount ($ M)",
            "value": "Deal Amount($M)",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 222,
            "name": "Project Name",
            "value": "Project Name",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 223,
            "name": "Company Type",
            "value": "Company Type",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },

        {
            "id": 223,
            "name": "Date",
            "value": "Date",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 223,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 224,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 225,
            "name": "Stake",
            "value": "Stake",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 226,
            "name": "City (Project Location)",
            "value": "City",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 227,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 228,
            "name": "Valuation",
            "value": "Valuation",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 229,
            "name": "Link for Financials",
            "value": "Link for Financials",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 249,
            "name": "Sub Sector",
            "value": "Sub Sector",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 230,
            "name": "Round",
            "value": "Round",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 231,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 232,
            "name": "Link",
            "value": "Link",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },
        {
            "id": 832,
            "name": "Project/Entity level investment",
            "value": "Project/Entity level investment",
            "isChecked": true,
            "parent": "pereDealinfo",
            "category": "investments",
        },

    ],

    "pereInvestorinfo": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "pereInvestorinfo",
            "category": "investments",
        },
        {
            id: 241,
            name: "Investor",
            value: "Investors",
            isChecked: true,
            parent: "pereInvestorinfo",
            "category": "investments",
        },
        {
            id: 242,
            name: "Investor Type",
            value: "Investor Type",
            isChecked: true,
            parent: "pereInvestorinfo",
            "category": "investments",
        }
    ],

    "pereAdvisorinfo": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "pereAdvisorinfo",
            "category": "investments",
        },
        {
            id: 261,
            name: "Advisor Company",
            value: "Advisor-Company",
            isChecked: true,
            parent: "pereAdvisorinfo",
            "category": "investments",
        },
        {
            id: 262,
            name: "Advisor Investors",
            value: "Advisor-Investors",
            isChecked: true,
            parent: "pereAdvisorinfo",
            "category": "investments",
        }
    ],
    "pereMoreInfo":[
        {
            "id": 255,
            "name": "More Information",
            "value": "More Information",
            "isChecked": true,
            "parent": "pereMoreInfo",
            "category": "investments",
        }

    ],

  
    "reMoreInfo": [
        {
            "id": 255,
            "name": "Addn Info",
            "value": "Addn Info",
            "isChecked": true,
            "parent": "reMoreInfo",
            "category": "investments",
        }

    ],
    "advInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "advInfo",
        },
        {
            "id": 256,
            "name": "Advisor Company",
            "value": "Advisor Company",
            "isChecked": true,
            "parent": "advInfo",
            "category": "investments",
        },
        {
            "id": 257,
            "name": "Advisor Investor",
            "value": "Advisor Investor",
            "isChecked": true,
            "parent": "advInfo",
            "category": "investments",
        }

    ],
    "invInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "invInfo",
        },
        {
            "id": 258,
            "name": "Investors",
            "value": "Investors",
            "isChecked": true,
            "parent": "invInfo",
            "category": "investments",
        },
        {
            "id": 258,
            "name": "Investor Type",
            "value": "Investor Type",
            "isChecked": true,
            "parent": "invInfo",
            "category": "investments",
        }

    ],

    reCompanyExit:[
    {
        "id": 100,
        "name": "All",
        "value": "all",
        "isChecked": true,
        "hasMore": false,
        "parent": "reCompanyExit",
        "category": "exits",
    },
    {
        "id": 410,
        "name": "Profile",
        "value": "profile",
        "isChecked": true,
        "hasMore": true,
        "filterKey": "reCompanyExitProfile",
        "category": "exits",
        "parent": "reCompanyExit",
    },
    {
        "id": 411,
        "name": "Deal Info",
        "value": "Deal Info",
        "isChecked": true,
        "hasMore": true,
        "filterKey": "reCompanyExitDealinfo",
        "category": "exits",
        "parent": "reCompanyExit",

    },

    {
        "id": 412,
        "name": "Investor Info",
        "value": "Investor Info",
        "category": "exits",
        "isChecked": true,
        "hasMore": true,
        "filterKey": "reCompanyExitInvestorinfo",
        "parent": "reCompanyExit",
    },


    {
        "id": 413,
        "name": "Advisor Info",
        "value": "Advisor Info",
        "isChecked": true,
        "hasMore": true,
        "filterKey": "reComapnyAdvisorinfo",
        "category": "exits",
        "parent": "reCompanyExit",
    },
    {
        "id": 414,
        "name": "More Info ",
        "value": "More Info",
        "isChecked": true,
        "hasMore": true,
        "filterKey": "reCompanyMoreInfo",
        "category": "exits",
        "parent": "reCompanyExit",
    }

],

"reCompanyExitDealinfo":[
    {
        "id": 100,
        "name": "All",
        "value": "all",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
    },
    {
        "id": 221,
        "name": "Deal Amount ($ M)",
        "value": "Deal Amount($M)",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
        "category": "exits",
    },
    {
        "id": 222,
        "name": "Project Name",
        "value": "Project Name",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
        "category": "exits",
    },
    {
        "id": 223,
        "name": "Deal Type",
        "value": "Deal Type",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
        "category": "exits",
    },

    {
        "id": 223,
        "name": "Date",
        "value": "Date",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
        "category": "exits",
    },
    {
        "id": 223,
        "name": "Sector",
        "value": "Sector",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
        "category": "exits",
    },
    {
        "id": 224,
        "name": "Exit Status",
        "value": "Exit Status",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
        "category": "exits",
    },
    // {
    //     "id": 225,
    //     "name": "Stake",
    //     "value": "Stake (%)",
    //     "isChecked": true,
    //     "parent": "reCompanyExitDealinfo",
    //     "category": "exits",
    // },
    {
        "id": 226,
        "name": "City (Project Location)",
        "value": "City",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
        "category": "exits",
    },
    {
        "id": 227,
        "name": "Region",
        "value": "Region",
        "isChecked": true,
        "parent": "reCompanyExitDealinfo",
        "category": "exits",
    },
    {
        "id": 228,
        "name": "News Link",
        "value": "News Link",
        "isChecked": true,
        "parent": "reCompanyDealinfo",
        "category": "exits",
    },

],
"reCompanyExitInvestorinfo":[
    {
        "id": 258,
        "name": "Investors",
        "value": "Investors",
        "isChecked": true,
        "parent": "reCompanyExitInvestorinfo",
        "category": "exits",
    },

],

"reComapnyAdvisorinfo":[
    {
        id: 100,
        name: "All",
        value: "all",
        isChecked: true,
        parent: "reComapnyAdvisorinfo",
        "category": "exits",
    },
    {
        id: 261,
        name: "Advisor Company",
        value: "Advisor-Company",
        isChecked: true,
        parent: "reComapnyAdvisorinfo",
        "category": "exits",
    },
    {
        id: 262,
        name: "Advisor Investors",
        value: "Advisor-Investors",
        isChecked: true,
        parent: "reComapnyAdvisorinfo",
        "category": "exits",
    }

],

"reCompanyMoreInfo":[
    {
        "id": 100,
        "name": "All",
        "value": "all",
        "isChecked": true,
        "parent": "reCompanyMoreInfo",
    },

    {
        "id": 302,
        "name": "Addn Info",
        "value": "Addn Info",
        "isChecked": true,
        "parent": "reCompanyMoreInfo",
        "category": "exits",

    },
   

],


    "reExitMoreInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reExitMoreInfo",
        },
        {
            "id": 302,
            "name": "Addn Info",
            "value": "Addn Info",
            "isChecked": true,
            "parent": "reCompanyMoreInfo",
            "category": "exits",
    
        },
        {
            "id": 303,
            "name": "Investment Details",
            "value": "Investment Details",
            "isChecked": true,
            "parent": "reExitMoreInfo",
            "category": "exits",

        },

        {
            "id": 304,
            "name": "Estimated Returns",
            "value": "Estimated Returns",
            "isChecked": true,
            "parent": "reExitMoreInfo",
            "category": "exits",

        },

        {
            "id": 305,
            "name": "More Info Returns",
            "value": "More Info Return",
            "isChecked": true,
            "parent": "reExitMoreInfo",
            "category": "exits",

        },

    ],
    "advExitInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "advExitInfo",
            "category": "exits",
        },
        {
            "id": 306,
            "name": "Advisor Company",
            "value": "Advisor-Company",
            "isChecked": true,
            "parent": "advExitInfo",
            "category": "exits",

        },
        {
            "id": 307,
            "name": "Advisor Acquirer",
            "value": "Advisor-Acquirer",
            "isChecked": true,
            "parent": "advExitInfo",
            "category": "exits",

        },
    ],
    "invExitInfo": [
        {
            "id": 306,
            "name": "Investors",
            "value": "Investors",
            "isChecked": true,
            "parent": "invExitInfo",
            "category": "exits",

        },

    ],

    reCompanyIpo:[

        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reCompanyIpo",
            "category": "ipo",
        },
        {
            "id": 410,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reCompanyExitProfile",
            "category": "ipo",
            "parent": "reCompanyIpo",
        },
        {
            "id": 411,
            "name": "Deal Info",
            "value":"Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reCompanyExitDealinfo",
            "category": "ipo",
            "parent": "reCompanyIpo",
    
        },
    
        {
            "id": 412,
            "name": "Investor Info",
            "category": "ipo",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reCompanyExitInvestorinfo",
            "parent": "reCompanyIpo",
        },
    
    
        {
            "id": 413,
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reComapnyAdvisorinfo",
            "category": "ipo",
            "parent": "reCompanyIpo",
        },
        {
            "id": 414,
            "name": "More Info ",
            "value": "More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reCompanyMoreInfo",
            "category": "ipo",
            "parent": "reCompanyIpo",
        }
    

    ],

    reIpo: [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reIpo",
        },
        {
            "id": 421,
            "name": "Ipo Size ($ M)",
            "value": "Ipo Size($M)",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
        },
        {
            "id": 422,
            "name": "Ipo Price (INR)",
            "value": "Ipo Price(INR)",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
        },
        {
            "id": 423,
            "name": "Ipo Valuation ($ M)",
            "value": "Ipo Valuation($M)",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
        },


        {
            "id": 223,
            "name": "Date",
            "value": "Date",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
        },

        {
            "id": 224,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
        },
        // {
        //     "id": 225,
        //     "name": "Stake",
        //     "value": "Stake (%)",
        //     "isChecked": true,
        //     "parent": "reIpo",
        //     "category": "ipo",
        // },
        {
            "id": 226,
            "name": "City (Project Location)",
            "value": "City",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
        },
        {
            "id": 227,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
        },
        {
            "id": 228,
            "name": "News Link",
            "value": "News Link",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
        },
        {
            "id": 229,
            "name": "Investors",
            "value": "Investors",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
            "hasMore": true,
            "filterKey": "invExitInfo",
        },
        {
            "id": 301,
            "name": "Advisors",
            "value": "Advisor",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
            "hasMore": true,
            "filterKey": "advExitInfo",
        },
        {
            "id": 302,
            "name": "More Info",
            "value": "More Info",
            "isChecked": true,
            "parent": "reIpo",
            "category": "ipo",
            "hasMore": true,
            "filterKey": "reIpoMoreInfo",
        },

    ],
    "reIpoMoreInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
        },
        {
            "id": 303,
            "name": "Investment Details",
            "value": "Investment Details",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
            "category": "ipo",

        },

        {
            "id": 304,
            "name": "Estimated Returns",
            "value": "Estimated Returns",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
            "category": "ipo",

        },

        {
            "id": 305,
            "name": "More Info Returns",
            "value": "More Info Return",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
            "category": "ipo",

        },


    ],

    reOtherma: [

        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reOtherma",
        },
        {
            "id": 421,
            "name": "Deal Amount ($ M)",
            "value": "Deal Amount($M)",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },
        {
            "id": 422,
            "name": "Deal Type",
            "value": "Deal Type",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },


        {
            "id": 223,
            "name": "Date",
            "value": "Date",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },

        {
            "id": 223,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },


        {
            "id": 224,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },
        {
            "id": 225,
            "name": "Stake",
            "value": "Stake",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },
        {
            "id": 226,
            "name": "City (Project Location)",
            "value": "City",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },
        {
            "id": 227,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },
        {
            "id": 227,
            "name": "Country",
            "value": "Country",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },
        {
            "id": 228,
            "name": "News Link",
            "value": "News Link",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
        },
        {
            "id": 229,
            "name": "Acquirer Info",
            "value": "Acquirer Info",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
            "hasMore": true,
            "filterKey": "othermaAcqInfo",
        },
        {
            "id": 301,
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
            "hasMore": true,
            "filterKey": "othermaAdvInfo",
        },
        {
            "id": 302,
            "name": "More Info",
            "value": "More Info",
            "isChecked": true,
            "parent": "reOtherma",
            "category": "otherma",
            "hasMore": true,
            "filterKey": "reOthermaMoreInfo",

        },
    ],
    "reOthermaMoreInfo": [
        {
            "id": 255,
            "name": "Addn Info",
            "value": "Addn Info",
            "isChecked": true,
            "parent": "reOthermaMoreInfo",
            "category": "otherma",
        }

    ],
    "othermaAdvInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "othermaAdvInfo",
            "category": "otherma",
        },
        {
            "id": 306,
            "name": "Advisor Company",
            "value": "Advisor-Company",
            "isChecked": true,
            "parent": "othermaAdvInfo",
            "category": "otherma",

        },
        {
            "id": 307,
            "name": "Advisor Acquirer",
            "value": "Advisor-Acquirer",
            "isChecked": true,
            "parent": "othermaAdvInfo",
            "category": "otherma",

        },
    ],
    "othermaAcqInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "othermaAcqInfo",
            "category": "otherma",
        },
        {
            "id": 306,
            "name": "Acquirer",
            "value": "Acquirer",
            "isChecked": true,
            "parent": "othermaAcqInfo",
            "category": "otherma",

        },
        {
            "id": 307,
            "name": "Acquirer City",
            "value": "Acquirer City",
            "isChecked": true,
            "parent": "othermaAcqInfo",
            "category": "otherma",

        },
        {
            "id": 308,
            "name": "Acquirer Country",
            "value": "Acquirer Country",
            "isChecked": true,
            "parent": "othermaAcqInfo",
            "category": "otherma",

        },

    ],

    "pereExitProfile": [


        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereExitProfile",
            "category": "exits",
        },
        {
            "id": 415,
            "name": "Portfolio Company",
            "value": "Portfolio Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereExitProfile",
            "category": "exits",
        },

        {
            "id": 416,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereExitProfile",
            "category": "exits",
        },
        // {
        //     "id": 417,
        //     "name": "Type",
        //     "value": "Type",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereExitProfile",
        //     "category": "exits",
        // },
        // {
        //     "id": 418,
        //     "name": "Location (City, Country)",
        //     "value": "Location",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereExitProfile",
        //     "category": "exits",
        // },
        // {
        //     "id": 419,
        //     "name": "Address (Address, Zip)",
        //     "value": "Address",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereExitProfile",
        //     "category": "exits",
        // },
        // {
        //     "id": 420,
        //     "name": "Year Founded",
        //     "value": "Year Founded",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereExitProfile",
        //     "category": "exits",
        // },

        {
            "id": 421,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereExitProfile",
            "category": "exits",
        },


        // {
        //     "id": 422,
        //     "name": "Contact (Telephone, Email)",
        //     "value": "Contact",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereExitProfile",
        //     "category": "exits",
        // },
        // {
        //     "id": 423,
        //     "name": "More Information",
        //     "value": "More Information",
        //     "isChecked": true,
           
        //     "parent": "pereExitProfile",
        //     "category": "exits",
        // },
        // {
        //     "id": 424,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereExitProfile",
        //     "category": "exits",
        // },
        {
            "id": 425,
            "name": "Investor Board Member",
            "value": "Investor Board Member",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereExitProfile",
            "category": "exits",
        },
        {
            "id": 925,
            "name": "Project/Entity level investment",
            "value": "Project/Entity level investment",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereExitProfile",
            "category": "exits",
        },
        {
            "id": 929,
            "name": "Sub Sector",
            "value": "Sub Sector",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereExitProfile",
            "category": "exits",
        },
    ],
    reComapnyExitProfile: [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reComapnyExitProfile",
            "category": "exits",
        },
        {
            "id": 410,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pereExitProfile",
            "category": "exits",
            "parent": "reComapnyExitProfile",
        },
        {
            "id": 411,
            "name": "Deal Info",
            "value":"Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reCompanyExitDealinfo",
            "category": "exits",
            "parent": "reComapnyExitProfile",

        },

        // {
        //     "id": 412,
        //     "name": "Investor Info ",
        //     "value":"Investor Info",
        //     "category": "exits",
        //     "isChecked": true,
        //     "hasMore": true,
        //      "filterKey":"reexitInvestor",
        //     "parent": "reComapnyExitProfile",
        // },

       


        {
            "id": 414,
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reCompanyExitAdvisorinfo",
            "category": "exits",
            "parent": "reComapnyExitProfile",
        },
        {
            "id": 415,  
            "name": "More Info ",
            "value": "More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reCompanyExitMoreInfo",
            "category": "exits",
            "parent": "reComapnyExitProfile",
        }

    ],

    "reexitInvestor":[
        {
            "id": 412,
            "name": "Investor ",
            "value":"Investors",
            "category": "exits",
            "isChecked": true,
          
            "parent": "reComapnyExitProfile",
        },
    ],

    "reCompanyExitDealinfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
        },

        
        {
            "id": 221,
            "name": "Deal Amount ($ M)",
            "value": "Deal Amount($M)",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        {
            "id": 222,
            "name": "Project Name",
            "value": "Project Name",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        {
            "id": 223,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },

        {
            "id": 223,
            "name": "Deal Date",
            "value": "Deal Date",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        {
            "id": 223,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        {
            "id": 224,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        {
            "id": 225,
            "name": "PE Firm(s)",
            "value": "PE Firm(s)",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        {
            "id": 229,
            "name": "Acquirer",
            "value": "Acquirer",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        // {
        //     "id": 225,
        //     "name": "Stake",
        //     "value": "Stake (%)",
        //     "isChecked": true,
        //     "parent": "reCompanyExitDealinfo",
        //     "category": "exits",
        // },
        {
            "id": 226,
            "name": "City (Project Location)",
            "value": "City",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        {
            "id": 227,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },
        {
            "id": 228,
            "name": "Link",
            "value": "Link",
            "isChecked": true,
            "parent": "reCompanyExitDealinfo",
            "category": "exits",
        },

        

    ],
    "reCompanyExitAdvisorinfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reCompanyExitAdvisorinfo",
            "category": "exits",
        },
        {
            "id": 406,
            "name": "Advisor Company",
            "value": "Advisor-Company",
            "isChecked": true,
            "parent": "reCompanyExitAdvisorinfo",
            "category": "exits",

        },
        {
            "id": 407,
            "name": "Advisor Acquirer",
            "value": "Advisor-Acquirer",
            "isChecked": true,
            "parent": "reCompanyExitAdvisorinfo",
            "category": "exits",

        },

    ],
    "reCompanyExitMoreInfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reCompanyExitMoreInfo",
        },
        {
            "id": 302,
            "name": "AddIn Info",
            "value": "AddIn Info",
            "isChecked": true,
            "parent": "reCompanyExitMoreInfo",
            "category": "exits",

        },
        {
            "id": 303,
            "name": "Investment Details",
            "value": "Investment Details",
            "isChecked": true,
            "parent": "reCompanyExitMoreInfo",
            "category": "exits",

        },

        {
            "id": 304,
            "name": "Estimated Returns",
            "value": "Estimated Returns",
            "isChecked": true,
            "parent": "reCompanyExitMoreInfo",
            "category": "exits",

        },

        {
            "id": 305,
            "name": "More Info(Returns)",
            "value": "More Info(Returns)",
            "isChecked": true,
            "parent": "reCompanyExitMoreInfo",
            "category": "exits",

        },

    ],

    "pereOtherMaProfile": [


        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereOtherMaProfile",
            "category": "otherma",
        },
        {
            "id": 415,
            "name": "Target Company",
            "value": "Target_Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereOtherMaProfile",
            "category": "otherma",
        },

        {
            "id": 416,
            "name": "Industry Target",
            "value": "Industry_Target",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereOtherMaProfile",
            "category": "otherma",
        },
        // {
        //     "id": 417,
        //     "name": "Type",
        //     "value": "Type",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereOtherMaProfile",
        //     "category": "otherma",
        // },
        // {
        //     "id": 418,
        //     "name": "Location (City, Country)",
        //     "value": "Location",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereOtherMaProfile",
        //     "category": "otherma",
        // },
        // {
        //     "id": 419,
        //     "name": "Address (Address, Zip)",
        //     "value": "Address",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereOtherMaProfile",
        //     "category": "otherma",
        // },
        // {
        //     "id": 420,
        //     "name": "Year Founded",
        //     "value": "Year Founded",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereOtherMaProfile",
        //     "category": "otherma",
        // },

        {
            "id": 421,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereOtherMaProfile",
            "category": "otherma",
        },


        // {
        //     "id": 422,
        //     "name": "Contact (Telephone, Email)",
        //     "value": "Contact",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereOtherMaProfile",
        //     "category": "otherma",
        // },
        // {
        //     "id": 423,
        //     "name": "More Information",
        //     "value": "More Information",
        //     "isChecked": true,
           
        //     "parent": "pereOtherMaProfile",
        //     "category": "otherma",
        // },
        // {
        //     "id": 424,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereOtherMaProfile",
        //     "category": "otherma",
        // },
        {
            "id": 425,
            "name": "Investor Board Member",
            "value": "Investor Board Member",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereOtherMaProfile",
            "category": "otherma",
        },
    ],

    reOthermaProfile:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOthermaProfile",
            "category": "otherma",
        },
        {
            "id": 410,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pereOtherMaProfile",
            "category": "otherma",
            "parent": "reOthermaProfile",
        },
        {
            "id": 411,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reOtherMaDealinfo",
            "category": "otherma",
            "parent": "reOthermaProfile",

        },

      

        {
            "id": 413,
            "name": "Acquirer Info",
            "value":"Acquirer Info",
            "category": "otherma",
            "isChecked": true,
             "hasMore":true,
            "parent": "reOthermaProfile",
            "filterKey":"reOthermaAcqInfo"
        },


        {
            "id": 414,
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reOtherMaAdvInfo",
            "category": "otherma",
            "parent": "reOthermaProfile",
        },
        {
            "id": 415,  
            "name": "More Info ",
            "value": "More Info",
            "isChecked": true,
           
            "hasMore": true,
            "filterKey": "reOtherMaMoreInfo",
            "category": "otherma",
            "parent": "reOthermaProfile",
        }

    ],
    "reOtherMaMoreInfo":[
        {
            "id": 414,
            "name": "More Infor",
            "value": "MoreInfor",
            "isChecked": true,
            "hasMore": false,
            "category": "otherma",
            "parent": "reOtherMaMoreInfo",
        },

    ],
    "reOtherMaDealinfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
        },
        {
            "id": 221,
            "name": "Deal Amount ($ M)",
            "value": "Deal Amount($M)",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },
       
        {
            "id": 223,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },

        {
            "id": 223,
            "name": "Deal Date",
            "value": "Deal Date",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },
        {
            "id": 223,
            "name": "Sector Target",
            "value": "Sector_Target",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },
        // {
        //     "id": 224,
        //     "name": "Exit Status",
        //     "value": "Exit Status",
        //     "isChecked": true,
        //     "parent": "reOtherMaDealinfo",
        //     "category": "otherma",
        // },
        {
            "id": 225,
            "name": "Stake",
            "value": "Stake",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },
        {
            "id": 226,
            "name": "City (Project Location)",
            "value": "City",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },
        {
            "id": 227,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },
        {
            "id": 228,
            "name": "Country Target",
            "value": "Country_Target",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },
        {
            "id": 229,
            "name": "Link",
            "value": "Link",
            "isChecked": true,
            "parent": "reOtherMaDealinfo",
            "category": "otherma",
        },

    ],
    "reOthermaAcqInfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOthermaAcqInfo",
            "category": "otherma",
        },
        {
            "id": 306,
            "name": "Acquirer",
            "value": "Acquirer",
            "isChecked": true,
            "parent": "reOthermaAcqInfo",
            "category": "otherma",

        },
        {
            "id": 307,
            "name": "City Acquirer",
            "value": "City_Acquirer",
            "isChecked": true,
            "parent": "reOthermaAcqInfo",
            "category": "otherma",

        },
        {
            "id": 308,
            "name": "Country Acquirer",
            "value": "Country_Acquirer",
            "isChecked": true,
            "parent": "reOthermaAcqInfo",
            "category": "otherma",

        },
    ],
    "reOtherMaAdvInfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMaAdvInfo",
            "category": "otherma",
        },
        {
            "id": 306,
            "name": "Advisor Target",
            "value": "Advisor_Target",
            "isChecked": true,
            "parent": "reOtherMaAdvInfo",
            "category": "otherma",

        },
        {
            "id": 307,
            "name": "Advisor Acquirer",
            "value": "Advisor_Acquirer",
            "isChecked": true,
            "parent": "reOtherMaAdvInfo",
            "category": "otherma",

        },

    ],

    "pereIpoProfile": [


        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereIpoProfile",
            "category": "ipo",
        },
        {
            "id": 415,
            "name": "Portfolio Company",
            "value": "Portfolio Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereIpoProfile",
            "category": "ipo",
        },

        {
            "id": 416,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereIpoProfile",
            "category": "ipo",
        },
        // {
        //     "id": 417,
        //     "name": "PE Firm(s)",
        //     "value": "PE Firm(s)",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereIpoProfile",
        //     "category": "ipo",
        // },
        // {
        //     "id": 417,
        //     "name": "Type",
        //     "value": "Type",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereIpoProfile",
        //     "category": "ipo",
        // },
        // {
        //     "id": 418,
        //     "name": "Location (City, Country)",
        //     "value": "Location",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereIpoProfile",
        //     "category": "ipo",
        // },
        // {
        //     "id": 419,
        //     "name": "Address (Address, Zip)",
        //     "value": "Address",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereIpoProfile",
        //     "category": "ipo",
        // },
        // {
        //     "id": 420,
        //     "name": "Year Founded",
        //     "value": "Year Founded",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereIpoProfile",
        //     "category": "ipo",
        // },

        {
            "id": 421,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereIpoProfile",
            "category": "ipo",
        },


        // {
        //     "id": 422,
        //     "name": "Contact (Telephone, Email)",
        //     "value": "Contact",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereIpoProfile",
        //     "category": "ipo",
        // },
        // {
        //     "id": 423,
        //     "name": "More Information",
        //     "value": "More Information",
        //     "isChecked": true,
           
        //     "parent": "pereIpoProfile",
        //     "category": "ipo",
        // },
        // {
        //     "id": 424,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "pereIpoProfile",
        //     "category": "ipo",
        // },
        {
            "id": 425,
            "name": "Investor Board Member",
            "value": "Investor Board Member",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereIpoProfile",
            "category": "ipo",
        },
    ],

    reIpoProfile :[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reIpoProfile",
            "category": "ipo",
        },
        {
            "id": 410,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pereIpoProfile",
            "category": "ipo",
            "parent": "reIpoProfile",
        },
        {
            "id": 411,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reIpoDealinfo",
            "category": "ipo",
            "parent": "reIpoProfile",

        },

      

        // {
        //     "id": 413,
        //     "name": "Investor",
        //     "value":"Investors",
        //     "category": "ipo",
        //     "isChecked": true,
           
        //     "parent": "reIpoProfile",
        // },


        // {
        //     "id": 414,
        //     "name": "Advisor Info",
        //     "value": "Advisor Info",
        //     "isChecked": true,
        //     "hasMore": true,
        //     "filterKey": "reIpoAdvisorInfo",
        //     "category": "ipo",
        //     "parent": "reIpoProfile",
        // },
        {
            "id": 415,  
            "name": "More Info ",
            "value": "More Info",
            "isChecked": true,
           
            "hasMore": true,
            "filterKey": "reIpoMoreInfo",
            "category": "ipo",
            "parent": "reIpoProfile",
        }
    ],
    "reIpoDealinfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reIpoDealinfo",
        },
        {
            "id": 221,
            "name": "Ipo Size ($ M)",
            "value": "IPO Size($M)",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },
        {
            "id": 231,
            "name": "Ipo Price (INR)",
            "value": "IPO Price(INR)",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },
       
        {
            "id": 225,
            "name": "Ipo Valuation ($ M)",
            "value": "Ipo Valuation($M)",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },

        {
            "id": 223,
            "name": "Date",
            "value": "Date",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },
        
        {
            "id": 224,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },
       
        {
            "id": 226,
            "name": "City (Project Location)",
            "value": "City",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },
        {
            "id": 227,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },
        {
            "id": 228,
            "name": "PE Firm(s)",
            "value": "PE Firm(s)",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },
        {
            "id": 229,
            "name": "Sector Business",
            "value": "Sector_Business",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },
       
        {
            "id": 230,
            "name": "Link",
            "value": "Link",
            "isChecked": true,
            "parent": "reIpoDealinfo",
            "category": "ipo",
        },


    ],
    "reIpoMoreInfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
        },
        {
            "id": 301,
            "name": "AddIn Info",
            "value": "AddIn Info",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
            "category": "ipo",

        },
        {
            "id": 303,
            "name": "Investment Details",
            "value": "Investment details",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
            "category": "ipo",

        },

        {
            "id": 304,
            "name": "Estimated Returns",
            "value": "Estimated Returns",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
            "category": "ipo",

        },

        {
            "id": 305,
            "name": "More Info(Returns)",
            "value": "More Info(Returns)",
            "isChecked": true,
            "parent": "reIpoMoreInfo",
            "category": "ipo",

        },



    ],
    "reIpoAdvisorInfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reIpoAdvisorInfo",
            "category": "ipo",
        },
        {
            "id": 306,
            "name": "Advisor Company",
            "value": "Advisor-Company",
            "isChecked": true,
            "parent": "reIpoAdvisorInfo",
            "category": "ipo",

        },
        {
            "id": 307,
            "name": "Advisor Acquirer",
            "value": "Advisor-Acquirer",
            "isChecked": true,
            "parent": "reIpoAdvisorInfo",
            "category": "ipo",

        },


    ],


}

const reExitExportData = {
    ...reInvestmentExportData ,
    initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": false,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,
        },
        {
            "id": "2",
            "name": "PE-RE Exits",
            "isChecked": true,
            "fileName": "PE-RE Exits",
            "filterKey": "reComapnyExitProfile",
            "category": "exits",
            "isTransaction": false,
            "withDetails": true,
            "hasMore": true,
            "parent": "initial",
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "withDetails": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 26547,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pere",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "filterKey": "reComapnyExitProfile",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "PE-RE Backed IPOs",
            "value": "ALL",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "category": "ipo",
            "filterKey": "reIpoProfile"
        },
        {
            "id": 34,
            "name": "PE-RE Other M&A",
            "value": "ALL",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "category": "othermanda",
            "filterKey": "reOthermaProfile"
        },

    ],

}

const reOtherMaExportData = {
    ...reInvestmentExportData ,
    initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": false,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,
        },
        {
            "id": "2",
            "name": "PE-RE Other M&A",
            "isChecked": true,
            "fileName": "PE-RE Other M&A",
            "filterKey": "reOthermaProfile",
            "category": "otherma",
            "isTransaction": false,
            "withDetails": true,
            "hasMore": true,
            "parent": "initial",
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "withDetails": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 26547,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pere",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "filterKey": "reComapnyExitProfile",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "PE-RE Backed IPOs",
            "value": "ALL",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "category": "ipo",
            "filterKey": "reIpoProfile"
        },
        {
            "id": 34,
            "name": "PE-RE Other M&A",
            "value": "ALL",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "category": "otherma",
            "filterKey": "reOthermaProfile"
        },

    ],

}
const reBackedIpoExportData ={
    ...reInvestmentExportData ,
    initial: [
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": false,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,
        },
        {
            "id": "2",
            "name": "PE-RE Backed Ipos",
            "isChecked": true,
            "fileName": "PE-RE Backed Ipos",
            "filterKey": "reIpoProfile",
            "category": "ipo",
            "isTransaction": false,
            "withDetails": true,
            "hasMore": true,
            "parent": "initial",
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName": "All",
            "isChecked": false,
            "isTransaction": true,
            "withDetails": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 26547,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "pere",
            "parent": "allTransaction",
            "category": "investments"
        },
        {
            "id": 988,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "filterKey": "reComapnyExitProfile",
            "hasMore": true,
            "parent": "allTransaction",
            "category": "exits"
        },
        {
            "id": 89,
            "name": "PE-RE Backed IPOs",
            "value": "PE-RE Backed IPOs",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "category": "ipo",
            "filterKey": "reIpoProfile"
        },
        {
            "id": 34,
            "name": "PE-RE Other M&A",
            "value": "PE-RE Other M&A",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "category": "otherma",
            "filterKey": "reOthermaProfile"
        },

    ],
}

const reAdvisorInvestmentsExportData = {
    category: {
        profile: "profile",
        investment: "re-investments",
        exits: "re-exits",
        otherMA:"other-manda"
    },
    categoryKeys: {
        investment: "inv_deal_columns",
        exits:"exit_deal_columns",
        otherMA:"other_ma_deal_columns",
    },
    initial: [
        {
            "id": "1",
            "name": "Legal Advisors Profile",
            "fileName":"Legal Advisors Profile",
            "isChecked": false,
            "hasMore": false,
            "filterKey": "investment",
            "category": "investment",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Investments",
            "fileName":"PE-RE Investments",
            "isChecked": true,
            "filterKey": "reInvestment",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
    allTransaction:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 26547,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestment",
            "parent": "allTransaction",
            "category":"investment"
        },
        {
            "id": 988,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "filterKey": "reExit",
            "hasMore": true,
            "parent": "allTransaction",
            "category":"exits"
        },
        {
            "id": 989,
            "name": "PE-RE Other M&A",
            "value": "PE-RE Other M&A",
            "isChecked": true,
            "filterKey": "reOtherMA",
            "hasMore": true,
            "parent": "allTransaction",
            "category":"otherMA"
        },
    ],
    reInvestment:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestment",
            "category": "investment",
        },
        {
            "id": 21,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestmentProfile",
            "category": "investment",
            "parent": "reInvestment",
        },
        {
            "id": 22,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestmentDealInfo",
            "category": "investment",
            "parent": "reInvestment",
        },
        {
            "id": 23,
            "name": "Investor Info",
            "value": "Investor Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestmentInvestorInfo",
            "category": "investment",
            "parent": "reInvestment",
        },
        {
            "id": 24,
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestmentAdvisorInfo",
            "category": "investment",
            "parent": "reInvestment",
        },
        {
            "id": 25,
            "name": "More Info",
            "value":"More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestmentAdvisorMoreInfo",
            "category": "investment",
            "parent": "reInvestment",
        }
    ],
    "reInvestmentAdvisorMoreInfo":[
        {
            "id": 25,
            "name": "More Information",
            "value":"More Information",
            "isChecked": true,
            "hasMore": false,
            "category": "investment",
            "parent": "reInvestmentAdvisorMoreInfo",
        }

    ],
    reInvestmentProfile:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentProfile",
            "category": "investment",
        },
         {
            "id": 217,
            "name": "Company",
            "value": "Company",
            "isChecked": true,
            "hasMore": false,
            "parent": " reInvestmentProfile",
            "category": "investment",
        },
        {
            "id": 218,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentProfile",
            "category": "investment",
        },
        // {
        //     "id": 219,
        //     "name": "Sector",
        //     "value": "Sector",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
        // {
        //     "id": 220,
        //     "name": "CIN No",
        //     "value": "CIN No",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
        {
            "id": 221,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentProfile",
            "category": "investment",
        },
        // {
        //     "id": 222,
        //     "name": "Investor Board Members",
        //     "value": "Investor Board Members",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
        // {
        //     "id": 223,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
        // {
        //     "id": 224,
        //     "name": "Website",
        //     "value": "Website",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
        // {
        //     "id": 225,
        //     "name": "Contact Person",
        //     "value": "Contact Person",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
        // {
        //     "id": 226,
        //     "name": "Designation",
        //     "value": "Designation",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
        // {
        //     "id": 227,
        //     "name": "Email ID",
        //     "value": "Email ID",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
        // {
        //     "id": 228,
        //     "name": "Co Linkedin Profile",
        //     "value": "Co Linkedin Profile",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "investment",
        // },
    ],
    reInvestmentDealInfo:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
         {
            "id": 317,
            "name": "Deal Amount ($M)",
            "value": "Deal Amount($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 318,
            "name": "Project Name",
            "value": "Project Name",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 319,
            "name": "Company Type",
            "value": "Company Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 320,
            "name": "Date",
            "value": "Date",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        // {
        //     "id": 321,
        //     "name": "Sector",
        //     "value": "Sector",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentDealInfo",
        //     "category": "investment",
        // },
        {
            "id": 322,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 323,
            "name": "Stake",
            "value": "Stake",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 324,
            "name": "City",
            "value": "City",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 325,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 326,
            "name": "Round",
            "value": "Round",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 327,
            "name": "Link for Financials",
            "value": "Link for Financials",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 328,
            "name": "Valuation",
            "value": "Valuation",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 329,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        {
            "id": 330,
            "name": "Link",
            "value": "Link",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentDealInfo",
            "category": "investment",
        },
        
    ],
    reInvestmentInvestorInfo:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentInvestorInfo",
            "category": "investment",
        },
        //  {
        //     "id": 417,
        //     "name": "Investors",
        //     "value": "Investors",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentInvestorInfo",
        //     "category": "investment",
        // },
        {
            "id": 418,
            "name": "Investor Type",
            "value": "Investor Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentInvestorInfo",
            "category": "investment",
        },
    ],
    reInvestmentAdvisorInfo:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentAdvisorInfo",
            "category": "investment",
        },
         {
            "id": 517,
            "name": "Advisor-Company",
            "value": "Advisor-Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentAdvisorInfo",
            "category": "investment",
        },
        {
            "id": 518,
            "name": "Advisor-Investors",
            "value": "Advisor-Investors",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentAdvisorInfo",
            "category": "investment",
        },
    ],
    reExit:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExit",
            "category": "exits",
        },
        {
            "id": 71,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reExitProfile",
            "category": "exits",
            "parent": "reExit",
        },
        {
            "id": 22,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reExitDealInfo",
            "category": "exits",
            "parent": "reExit",
        },
        // {
        //     "id": 23,
        //     "name": "Investors",
        //     "value": "Investor Info",
        //     "isChecked": true,
        //     "hasMore": true,
        //     "filterKey": "reExitInvestorInfo",
        //     "category": "exits",
        //     "parent": "reExit",
        // },
        {
            "id": 24,
            
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reExitAdvisorInfo",
            "category": "exits",
            "parent": "reExit",
        },
        {
            "id": 25,
            "name": "More Info",
            "value":"More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reExitMoreInfo",
            "category": "exits",
            "parent": "reExit",
        }
    ],
    reExitProfile:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentProfile",
            "category": "exits",
        },
         {
            "id": 217,
            "name": "Portfolio Company",
            "value": "Portfolio Company",
            "isChecked": true,
            "hasMore": false,
            "parent": " reInvestmentProfile",
            "category": "exits",
        },
        {
            "id": 218,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentProfile",
            "category": "exits",
        },
        // {
        //     "id": 219,
        //     "name": "Sector",
        //     "value": "Sector",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "exits",
        // },
        // {
        //     "id": 220,
        //     "name": "CIN No",
        //     "value": "CIN No",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "exits",
        // },
        {
            "id": 221,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestmentProfile",
            "category": "exits",
        },
        // {
        //     "id": 222,
        //     "name": "Investor Board Members",
        //     "value": "Investor Board Members",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "exits",
        // },
        // {
        //     "id": 223,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestmentProfile",
        //     "category": "exits",
        // },
    ],
    reExitDealInfo:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
         {
            "id": 317,
            "name": "Deal Amount ($M)",
            "value": "Deal Amount($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 318,
            "name": "Project Name",
            "value": "Project Name",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 319,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 320,
            "name": "Deal Date",
            "value": "Deal Date",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 321,
            "name": "Acquirer",
            "value": "Acquirer",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 321,
            "name": "PE Firm(s)",
            "value": "PE Firm(s)",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 322,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 324,
            "name": "City",
            "value": "City",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 325,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        {
            "id": 326,
            "name": "Link",
            "value": "Link",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitDealInfo",
            "category": "exits",
        },
        
    ],
    // reExitInvestorInfo:[
    //     {
    //         "id": 100,
    //         "name": "All",
    //         "value": "all",
    //         "isChecked": true,
    //         "hasMore": false,
    //         "parent": "reExitInvestorInfo",
    //         "category": "exits",
    //     },
    //      {
    //         "id": 417,
    //         "name": "Investors",
    //         "value": "Investors",
    //         "isChecked": true,
    //         "hasMore": false,
    //         "parent": "reExitInvestorInfo",
    //         "category": "exits",
    //     },
    // ],
    reExitAdvisorInfo:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitAdvisorInfo",
            "category": "exits",
        },
         {
            "id": 517,
            "name": "Advisor-Company",
            "value": "Advisor-Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitAdvisorInfo",
            "category": "exits",
        },
        {
            "id": 518,
            "name": "Advisor-Acquirer",
            "value": "Advisor-Acquirer",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitAdvisorInfo",
            "category": "exits",
        },
    ],
    reExitMoreInfo:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitMoreInfo",
            "category": "exits",
        },
         {
            "id": 517,
            "name": "AddIn Info",
            "value": "AddIn Info",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitMoreInfo",
            "category": "exits",
        },
        {
            "id": 518,
            "name": "Investment Details",
            "value": "Investment Details",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitMoreInfo",
            "category": "exits",
        },
        {
            "id": 519,
            "name": "Estimated Returns",
            "value": "Estimated Returns",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitMoreInfo",
            "category": "exits",
        },
        {
            "id": 520,
            "name": "More Info(Returns)",
            "value": "More Info(Returns)",
            "isChecked": true,
            "hasMore": false,
            "parent": "reExitMoreInfo",
            "category": "exits",
        },
            ],
    reOtherMA:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMA",
            "category": "otherMA",
        },
        {
            "id": 71,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reOtherMAProfile",
            "category": "otherMA",
            "parent": "reOtherMA",
        },
        {
            "id": 22,
            "name": "Deal Info",
            "value": "Deal Info",

            "isChecked": true,
            "hasMore": true,
            "filterKey": "reOtherMADealInfo",
            "category": "otherMA",
            "parent": "reOtherMA",
        },
        {
            "id": 23,
            "name": "Acquirer",
            "value": "Acquirer",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "otherMAAcquirer",
            "category": "otherMA",
            "parent": "reOtherMA",
        },
        {
            "id": 24,
            "name": "Advisor Info",
            "value": "Advisor Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "otherMAAdvisor",
            "category": "otherMA",
            "parent": "reOtherMA",
        },
        {
            "id": 25,
            "name": "More Info",
            "value":"More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "otherMAMoreinfo",
            "category": "otherMA",
            "parent": "reOtherMA",
        }
    ],
    reOtherMAProfile:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMAProfile",
            "category": "otherMA",
        },
         {
            "id": 217,
            "name": "Target Company",
            "value": "Target_Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMAProfile",
            "category": "otherMA",
        },
        {
            "id": 218,
            "name": "Industry Target",
            "value": "Industry_Target",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMAProfile",
            "category": "otherMA",
        },
        // {
        //     "id": 219,
        //     "name": "Sector",
        //     "value": "Sector",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reOtherMAProfile",
        //     "category": "otherMA",
        // },
        // {
        //     "id": 220,
        //     "name": "CIN No",
        //     "value": "CIN No",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reOtherMAProfile",
        //     "category": "otherMA",
        // },
        {
            "id": 221,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMAProfile",
            "category": "otherMA",
        },
        // {
        //     "id": 222,
        //     "name": "Investor Board Members",
        //     "value": "Investor Board Members",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reOtherMAProfile",
        //     "category": "otherMA",
        // },
        // {
        //     "id": 223,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reOtherMAProfile",
        //     "category": "otherMA",
        // },
        {
            "id": 224,
            "name": "Investor",
            "value": "Investor",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMAProfile",
            "category": "otherMA",
        },
        // {
        //     "id": 225,
        //     "name": "Contact Person",
        //     "value": "Contact Person",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reOtherMAProfile",
        //     "category": "otherMA",
        // },
        // {
        //     "id": 226,
        //     "name": "Designation",
        //     "value": "Designation",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reOtherMAProfile",
        //     "category": "otherMA",
        // },
        // {
        //     "id": 227,
        //     "name": "Email ID",
        //     "value": "Email ID",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reOtherMAProfile",
        //     "category": "otherMA",
        // },
        // {
        //     "id": 228,
        //     "name": "Co Linkedin Profile",
        //     "value": "Co Linkedin Profile",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reOtherMAProfile",
        //     "category": "otherMA",
        // },
    ],
    reOtherMADealInfo:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
         {
            "id": 317,
            "name": "Deal Amount ($M)",
            "value": "Deal Amount($M)",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 318,
            "name": "Project Name",
            "value": "Project Name",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 319,
            "name": "Type",
            "value": "Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 320,
            "name": "Deal Date",
            "value": "Deal Date",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 321,
            "name": "Sector_Target",
            "value": "Sector_Target",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 322,
            "name": "Exit Status",
            "value": "Exit Status",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 323,
            "name": "Stake",
            "value": "Stake",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 324,
            "name": "City",
            "value": "City",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 325,
            "name": "Region",
            "value": "Region",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 326,
            "name": "Country Target",
            "value": "Country_Target",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        {
            "id": 327,
            "name": "Link",
            "value": "Link",
            "isChecked": true,
            "hasMore": false,
            "parent": "reOtherMADealInfo",
            "category": "otherMA",
        },
        
    ],
    otherMAAcquirer:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "otherMAAcquirer",
            "category": "otherMA",
        },
         {
            "id": 317,
            "name": "Acquirer",
            "value": "Acquirer",
            "isChecked": true,
            "hasMore": false,
            "parent": "otherMAAcquirer",
            "category": "otherMA",
        },
        {
            "id": 318,
            "name": "City Acquirer",
            "value": "City_Acquirer",
            "isChecked": true,
            "hasMore": false,
            "parent": "otherMAAcquirer",
            "category": "otherMA",
        },
        {
            "id": 319,
            "name": "Country Acquirer",
            "value": "Country_Acquirer",
            "isChecked": true,
            "hasMore": false,
            "parent": "otherMAAcquirer",
            "category": "otherMA",
        },
    ],
    otherMAAdvisor:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "otherMAAdvisor",
            "category": "otherMA",
        },
         {
            "id": 1317,
            "name": "Advisor Target",
            "value": "Advisor_Target",
            "isChecked": true,
            "hasMore": false,
            "parent": "otherMAAdvisor",
            "category": "otherMA",
        },
        {
            "id": 1318,
            "name": "Advisor Acquirer",
            "value": "Advisor_Acquirer",
            "isChecked": true,
            "hasMore": false,
            "parent": "otherMAAdvisor",
            "category": "otherMA",
        },
    ],
    otherMAMoreinfo:[
        // {
        //     "id": 100,
        //     "name": "All",
        //     "value": "all",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "otherMAMoreinfo",
        //     "category": "otherMA",
        // },
         {
            "id": 1417,
            "name": "More Infor",
            "value": "MoreInfor",
            "isChecked": true,
            "hasMore": false,
            "parent": "otherMAMoreinfo",
            "category": "otherMA",
        },
    ]

}

const reAdvisorExitExportData = {
    ...reAdvisorInvestmentsExportData,
    initial: [
        {
            "id": "1",
            "name": "Legal Advisors Profile",
            "fileName":"Legal Advisors Profile",
            "isChecked": false,
            "hasMore": false,
            "category": "exits",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Exits",
            "fileName":"PE-RE Exits",
            "isChecked": true,
            "filterKey": "reExit",
            "category": "exits",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
    allTransaction:[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 26547,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestment",
            "parent": "allTransaction",
        },
        {
            "id": 988,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "filterKey": "reExit",
            "hasMore": true,
            "parent": "allTransaction",
        },
        {
            "id": 989,
            "name": "PE-RE Other M&A",
            "value": "PE-RE Other M&A",
            "isChecked": true,
            "filterKey": "reOtherMA",
            "hasMore": true,
            "parent": "allTransaction",
        },
    ],
}

const reAdvisorOtherMAExportData = {
    ...reAdvisorInvestmentsExportData,
    initial: [
        {
            "id": "1",
            "name": "Legal Advisors Profile",
            "fileName":"Legal Advisors Profile",
            "isChecked": false,
            "hasMore": false,
            "category": "otherMA",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Other M&A",
            "fileName":"PE-RE Other M&A",
            "isChecked": true,
            "filterKey": "reOtherMA",
            "category": "otherMA",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "Legal Advisors Profile + All transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
}

const reTransactionAdvisorInvestmentsExportData = {
    ...reAdvisorInvestmentsExportData,
    initial: [
        {
            "id": "1",
            "name": "Transaction Advisors Profile",
            "fileName":"Transaction Advisors Profile",
            "isChecked": false,
            "hasMore": false,
            "filterKey": "investment",
            "category": "investment",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Investments",
            "fileName":"PE-RE Investments",
            "isChecked": true,
            "filterKey": "reInvestment",
            "category": "investment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
}

const reTransactionAdvisorExitExportData = {
    ...reAdvisorInvestmentsExportData,
    initial: [
        {
            "id": "1",
            "name": "Transaction Advisors Profile",
            "fileName":"Transaction Advisors Profile",
            "isChecked": false,
            "hasMore": false,
            "category": "exits",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Exits",
            "fileName":"PE-RE Exits",
            "isChecked": true,
            "filterKey": "reExit",
            "category": "exits",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
}

const reTransactionAdvisorOtherMAExportData = {
    ...reAdvisorInvestmentsExportData,
    initial: [
        {
            "id": "1",
            "name": "Transaction Advisors Profile",
            "fileName":"Transaction Advisors Profile",
            "isChecked": false,
            "hasMore": false,
            "category": "otherMA",
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Other M&A",
            "fileName":"PE-RE Other M&A",
            "isChecked": true,
            "filterKey": "reOtherMA",
            "category": "otherMA",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
}

const investorInvestmentExportData = {
   
    category: {
        profile: "profile",
        investorInvestment:"re-investments",
        investorExits:"re-exits",
        investoripo:"re-ipo"
    },
    categoryKeys: {
        investorInvestment: "inv_deal_columns",
        investorExits:"exit_deal_columns",
        investoripo:"ipo_deal_columns",
        
    },


    initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName":"Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Investments",
            "fileName":"PE-RE Investments",
            "isChecked": true,
            "filterKey": "pereinvestor",
            "category": "investorInvestment",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
    allTransaction: [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 310,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereinvestor",
            "category": "investorInvestment",
        },
        {
            "id": 311,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereexits",
            "category": "investorExits",
        },
        {
            "id": 312,
            "name": "PE-RE Backed IPOs",
            "value": "PE-RE Backed IPOs",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereipo",
            "category": "investoripo",
        }
        
    ],
     //RE-investments
     "pereinvestor": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereinvestor",
            "category": "investorInvestment",
        },
        {
            "id": 314,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorProfileInfo",
            "parent": "pereinvestor",
            "category": "investorInvestment",
        },
        {
            "id": 315,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorDealInfo",
            "parent": "pereinvestor",
            "category": "investorInvestment",
        },
        // {
        //     "id": 316,
        //     "name": "Investors",
        //     "value": "Investors",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "filterKey": "investorInvestorInfo",
        //     "parent": "pereinvestor",
        //     "category": "investorInvestment",
        // },
        {
            "id": 317,
            "name": "Advisors Info",
            "value": "Advisors Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorAdvisorInfo",
            "parent": "pereinvestor",
            "category": "investorInvestment",
        },
        {
            "id": 318,
            "name": "More Info",
            "value": "More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorMoreInfo",
            "parent": "pereinvestor",
            "category": "investorInvestment",
        },
       
    ],
    "investorProfileInfo": [
        // {
        //     "id": 100,
        //     "name": "All",
        //     "value": "all",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        //  {
        //     "id": 319,
        //     "name": "Investor",
        //     "value": "Investor",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 320,
        //     "name": "Industry",
        //     "value": "Industry",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 321,
        //     "name": "Firm Type",
        //     "value": "Firm Type",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 322,
        //     "name": "City",
        //     "value": "City",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 323,
        //     "name": "Address",
        //     "value": "Address",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 324,
        //     "name": "Year Founded",
        //     "value": "Year Founded",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 325,
        //     "name": "Website",
        //     "value": "Website",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 326,
        //     "name": "Telephone",
        //     "value": "Telephone",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 327,
        //     "name": "Email",
        //     "value": "Email",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 328,
        //     "name": "More Information",
        //     "value": "More Information",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 329,
        //     "name": " Management",
        //     "value": " Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 330,
        //     "name": "Assets Under Management",
        //     "value": "Assets Under Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 331,
        //     "name": "Description",
        //     "value": "Description",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorProfileInfo",
            "category": "investorInvestment",
        },
         {
            "id": 905,
            "name": "Company",
            "value": "Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorProfileInfo",
            "category": "investorInvestment",
        },
        {
            "id": 906,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorProfileInfo",
            "category": "investorInvestment",
        },
        {
            "id": 907,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorProfileInfo",
            "category": "investorInvestment",
        },
        // {
        //     "id": 908,
        //     "name": "CIN No",
        //     "value": "CIN No",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        {
            "id": 909,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorProfileInfo",
            "category": "investorInvestment",
        },
        // {
        //     "id": 910,
        //     "name": "Investor Board Members",
        //     "value": "Investor Board Members",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        // {
        //     "id": 911,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "investorProfileInfo",
        //     "category": "investorInvestment",
        // },
        {
            "id": 922,
            "name": "Investor",
            "value": "Investor",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorProfileInfo",
            "category": "investorInvestment",
        },
    ],
    "investorDealInfo": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 330,
            name: " Deal Amount ($M)",
            value: "Deal Amount($M)",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 331,
            name: "Project Name",
            value: "Project Name",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 332,
            name: "Company Type",
            value: "Company Type",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 333,
            name: "Date",
            value: "Date",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        // {
        //     id: 334,
        //     name: "Sector Name",
        //     value: "Sector Name",
        //     isChecked: true,
        //     parent: "investorDealInfo",
        //     "category": "investorInvestment",
        // },
	    {
            id: 335,
            name: "Exit Status",
            value: "Exit Status",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
         {
            id: 336,
            name: "Stake",
            value: "Stake",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 337,
            name: "City",
            value: "City",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 338,
            name: "Region",
            value: "Region",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 339,
            name: "Round",
            value: "Round",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 340,
            name: "Link for Financials",
            value: "Link for Financials",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 341,
            name: "Valuation",
            value: "Valuation",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 342,
            name: "Type",
            value: "Type",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
        {
            id: 343,
            name: "Link",
            value: "Link",
            isChecked: true,
            parent: "investorDealInfo",
            "category": "investorInvestment",
        },
       
        
    ],
    // "investorInvestorInfo": [
    //     {
    //         id: 100,
    //         name: "All",
    //         value: "all",
    //         isChecked: true,
    //         parent: "investorInvestorInfo",
    //         "category": "investorInvestment",
    //     },
    //     {
    //         id: 340,
    //         name: "Investor",
    //         value: "Investor",
    //         isChecked: true,
    //         parent: "investorInvestorInfo",
    //         "category": "investorInvestment",
    //     },
    //     {
    //         id: 341,
    //         name: "Investor Type",
    //         value: "Investor Type",
    //         isChecked: true,
    //         parent: "investorInvestorInfo",
    //         "category": "investorInvestment",
    //     }
    // ],
    "investorAdvisorInfo": [
        {
            id: 100,
            name: "All",
            value: "all",
            isChecked: true,
            parent: "investorAdvisorInfo",
            "category": "investorInvestment",
        },
        {
            id: 342,
            name: "Advisor-Company",
            value: "Advisor-Company",
            isChecked: true,
            parent: "investorAdvisorInfo",
            "category": "investorInvestment",
        },
        {
            id: 343,
            name: "Advisor-Investors",
            value: "Advisor-Investors",
            isChecked: true,
            parent: "investorAdvisorInfo",
            "category": "investorInvestment",
        },
    ],
    "investorMoreInfo": [
        {
            id: 344,
            name: "More Information",
            value: "More Information",
            isChecked: true,
            parent: "investorMoreInfo",
            "category": "investorInvestment",
        }
    ],
    //RE exits
    "pereexits": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereexits",
            "category": "investorExits",
        },
        {
            "id": 900,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestorexitProfileInfo",
            "parent": "pereexits",
            "category": "investorExits",
        },
        {
            "id": 901,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorexitDealInfo",
            "parent": "pereexits",
            "category": "investorExits",
        },
        // {
        //     "id": 902,
        //     "name": "Investors",
        //     "value": "Investors",
        //     "isChecked": true,
        //     "hasMore": false ,
        //     "filterKey": "investorexitInvestorInfo",
        //     "parent": "pereexits",
        //     "category": "investorExits",
        // },
        {
            "id": 903,
            "name": "Advisors Info",
            "value": "Advisors Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorexitAdvisorInfo",
            "parent": "pereexits",
            "category": "investorExits",
        },
        {
            "id": 904,
            "name": "More Info",
            "value": "More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorexitMoreInfo",
            "parent": "pereexits",
            "category": "investorExits",
        },
       
    ],

    "reInvestorexitProfileInfo":[
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorexitProfileInfo",
            "category": "investorExits",
        },
         {
            "id": 905,
            "name": "Portfolio Company",
            "value": "Portfolio Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 906,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 907,
            "name": "Sector",
            "value": "Sector",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorexitProfileInfo",
            "category": "investorExits",
        },
        // {
        //     "id": 908,
        //     "name": "CIN No",
        //     "value": "CIN No",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestorexitProfileInfo",
        //     "category": "investorExits",
        // },
        {
            "id": 909,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorexitProfileInfo",
            "category": "investorExits",
        },
        // {
        //     "id": 910,
        //     "name": "Investor Board Members",
        //     "value": "Investor Board Members",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestorexitProfileInfo",
        //     "category": "investorExits",
        // },
        // {
        //     "id": 911,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestorexitProfileInfo",
        //     "category": "investorExits",
        // },
        {
            "id": 922,
            "name": "Investor",
            "value": "Investor",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorexitProfileInfo",
            "category": "investorExits",
        },
        
    ],
    "investorexitProfileInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
         {
            "id": 905,
            "name": "Investor",
            "value": "Investor",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 906,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 907,
            "name": "Firm Type",
            "value": "Firm Type",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 908,
            "name": "City",
            "value": "City",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 909,
            "name": "Address",
            "value": "Address",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 910,
            "name": "Year Founded",
            "value": "Year Founded",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 911,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 922,
            "name": "Telephone",
            "value": "Telephone",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 923,
            "name": "Email",
            "value": "Email",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 924,
            "name": "More Information",
            "value": "More Information",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 925,
            "name": "Management",
            "value": "Management",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 926,
            "name": "Assets Under Management",
            "value": "Assets Under Management",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
        {
            "id": 927,
            "name": "Description",
            "value": "Description",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitProfileInfo",
            "category": "investorExits",
        },
    ],
    "investorexitDealInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 926,
            name: " Deal Amount ($M)",
            value: "Deal Amount($M)",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 927,
            name: "Project Name",
            value: "Project Name",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 928,
            name: "Type",
            value: "Type",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 929,
            name: "Deal Date",
            value: "Deal Date",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 930,
            name: "PE Firm(s)",
            value: "PE Firm(s)",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
	    {
            id: 931,
            name: "Exit Status",
            value: "Exit Status",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 932,
            name: "City",
            value: "City",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 933,
            name: "Region",
            value: "Region",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 934,
            name: "Acquirer",
            value: "Acquirer",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
        {
            id: 934,
            name: "Link",
            value: "Link",
            isChecked: true,
            parent: "investorexitDealInfo",
            "category": "investorExits",
        },
       
        
    ],
    "investorexitAdvisorInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitAdvisorInfo",
            "category": "investorExits",
        },
        {
            id: 935,
            name: "Advisor-Company",
            value: "Advisor-Company",
            isChecked: true,
            parent: "investorexitAdvisorInfo",
            "category": "investorExits",
        },
        {
            id: 936,
            name: "Advisor-Acquirer",
            value: "Advisor-Acquirer",
            isChecked: true,
            parent: "investorexitAdvisorInfo",
            "category": "investorExits",
        },
    ],
    "investorexitMoreInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorexitMoreInfo",
            "category": "investorExits",
        },
        {
            id: 937,
            name: "AddIn Info",
            value: "AddIn Info",
            isChecked: true,
            parent: "investorexitMoreInfo",
            "category": "investorExits",
        },
        {
            id: 938,
            name: "Investment Details",
            value: "Investment Details",
            isChecked: true,
            parent: "investorexitMoreInfo",
            "category": "investorExits",
        },
        {
            id: 939,
            name: "Estimated Returns",
            value: "Estimated Returns",
            isChecked: true,
            parent: "investorexitMoreInfo",
            "category": "investorExits",
        },
       
        {
            id: 940,
            name: "More Info(Returns)",
            value: "More Info(Returns)",
            isChecked: true,
            parent: "investorexitMoreInfo",
            "category": "investorExits",
        },
    ],
  
    "pereipo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "pereipo",
            "category": "investoripo",
        },
        {
            "id": 942,
            "name": "Profile",
            "value": "profile",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "reInvestorIpoProfileInfo",
            "parent": "pereipo",
            "category": "investoripo",
        },
        {
            "id": 943,
            "name": "Deal Info",
            "value": "Deal Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorIpoDealInfo",
            "parent": "pereipo",
            "category": "investoripo",
        },
        // {
        //     "id": 944,
        //     "name": "Investors",
        //     "value": "Investors",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "filterKey": "investorIpoInvestorInfo",
        //     "parent": "pereipo",
        //     "category": "investoripo",
        // },
        {
            "id": 945,
            "name": "Advisors Info",
            "value": "Advisors Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorIpoAdvisorInfo",
            "parent": "pereipo",
            "category": "investoripo",
        },
        {
            "id": 946,
            "name": "More Info",
            "value": "More Info",
            "isChecked": true,
            "hasMore": true,
            "filterKey": "investorIpoMoreInfo",
            "parent": "pereipo",
            "category": "investoripo",
        },
       
    ],
    "reInvestorIpoProfileInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorIpoProfileInfo",
            "category": "investoripo",
        },
         {
            "id": 947,
            "name": "Portfolio Company",
            "value": "Portfolio Company",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorIpoProfileInfo",
            "category": "investoripo",
        },
        {
            "id": 948,
            "name": "Industry",
            "value": "Industry",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorIpoProfileInfo",
            "category": "investoripo",
        },
        {
            "id": 949,
            "name": "Sector Business",
            "value": "Sector_Business",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorIpoProfileInfo",
            "category": "investoripo",
        },
        // {
        //     "id": 950,
        //     "name": "CIN No",
        //     "value": "CIN No",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestorIpoProfileInfo",
        //     "category": "investoripo",
        // },
        {
            "id": 951,
            "name": "Website",
            "value": "Website",
            "isChecked": true,
            "hasMore": false,
            "parent": "reInvestorIpoProfileInfo",
            "category": "investoripo",
        },
        // {
        //     "id": 952,
        //     "name": "Investor Board Members",
        //     "value": "Investor Board Members",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestorIpoProfileInfo",
        //     "category": "investoripo",
        // },
        // {
        //     "id": 953,
        //     "name": "Top Management",
        //     "value": "Top Management",
        //     "isChecked": true,
        //     "hasMore": false,
        //     "parent": "reInvestorIpoProfileInfo",
        //     "category": "investoripo",
        // },
       
    ],
    "investorIpoDealInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorIpoDealInfo",
            "category": "investoripo",
        },
        {
            id: 958,
            name: " IPO Size ($M)",
            value: "IPO Size($M)",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
        {
            id: 959,
            name: "IPO Price (INR)",
            value: "IPO Price(INR)",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
        {
            id: 960,
            name: "IPO Valuation ($M)",
            value: "IPO Valuation($M)",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
        {
            id: 961,
            name: "Date",
            value: "Date",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
	    {
            id: 962,
            name: "Exit Status",
            value: "Exit Status",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
        {
            id: 963,
            name: "City",
            value: "City",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
        {
            id: 964,
            name: "Region",
            value: "Region",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
        {
            id: 965,
            name: "PE Firm(s)",
            value: "PE Firm(s)",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
        {
            id: 966,
            name: "Link",
            value: "Link",
            isChecked: true,
            parent: "investorIpoDealInfo",
            "category": "investoripo",
        },
       
        
    ],
    "investorIpoAdvisorInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorIpoAdvisorInfo",
            "category": "investoripo",
        },
        {
            id: 1040,
            name: "Advisor-Company",
            value: "Advisor-Company",
            isChecked: true,
            parent: "investorIpoAdvisorInfo",
            "category": "investoripo",
        },
        {
            id: 966,
            name: "Advisor-Investors",
            value: "Advisor-Investors",
            isChecked: true,
            parent: "investorIpoAdvisorInfo",
            "category": "investoripo",
        },
    ],
    // "investorIpoInvestorInfo": [
    // {
    //     id: 967,
    //     name: "Investors",
    //     value: "Investors",
    //     isChecked: true,
    //     parent: "investorIpoInvestorInfo",
    //     "category": "investoripo",
    // }
    
    // ],
    "investorIpoMoreInfo": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "investorIpoMoreInfo",
            "category": "investoripo",
        },
    {
        id: 1030,
        name: "AddIn Info",
        value: "AddIn Info",
        isChecked: true,
        parent: "investorIpoMoreInfo",
        "category": "investoripo",
    },
    {
        id: 968,
        name: "Investment Details",
        value: "Investment details",
        isChecked: true,
        parent: "investorIpoMoreInfo",
        "category": "investoripo",
    },
    {
        id: 969,
        name: "Estimated Returns",
        value: "Estimated Returns",
        isChecked: true,
        parent: "investorIpoMoreInfo",
        "category": "investoripo",
    },
    {
        id: 970,
        name: "More Info(Returns)",
        value: "More Info(Returns)",
        isChecked: true,
        parent: "investorIpoMoreInfo",
        "category": "investoripo",
    },
    ],
   
}
const investorExitExportData = {
   
    ...investorInvestmentExportData, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName":"Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Exits",
            "fileName":"PE-RE Exits",
            "isChecked": true,
            "filterKey": "pereexits",
            "category": "investorExits",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 2657,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereinvestor",
            "category": "investorInvestment",
        },
        {
            "id": 9868,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereexits",
            "category": "investorExits",
        },
        {
            "id": 8559,
            "name": "PE-RE Backed IPOs",
            "value": "PE-RE Backed IPOs",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereipo",
            "category": "investoripo",
        }
    ],
   
}
const investorIpoExportData = {
   
    ...investorInvestmentExportData, initial: [
        {
            "id": "1",
            "name": "Investor Profile",
            "fileName":"Investor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
        {
            "id": "2",
            "name": "PE-RE Backed IPOs",
            "fileName":"PE-RE Backed IPOs",
            "isChecked": true,
            "filterKey": "pereipo",
            "category": "investoripo",
            "isTransaction": false,
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        },
        {
            "id": "3",
            "name": "All Transactions",
            "fileName":"All",
            "isChecked": false,
            "isTransaction": true,
            "filterKey": "allTransaction",
            "hasMore": true,
            "parent": "initial",           
            "withDetails": true,
        }
    ],
    "allTransaction": [
        {
            "id": 100,
            "name": "All",
            "value": "all",
            "isChecked": true,
            "hasMore": false,
            "parent": "allTransaction",
        },
        {
            "id": "1",
            "name": "Company Profile",
            "isChecked": true,
            "fileName": "Company Profile",
            "hasMore": false,
            "parent": "allTransaction",
            "category": "profile"
        },
        {
            "id": 26547,
            "name": "PE-RE Investments",
            "value": "PE-RE Investments",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereinvestor",
            "category": "investorInvestment",
        },
        {
            "id": 988,
            "name": "PE-RE Exits",
            "value": "PE-RE Exits",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereexits",
            "category": "investorExits",
        },
        {
            "id": 89,
            "name": "PE-RE Backed IPOs",
            "value": "PE-RE Backed IPOs",
            "isChecked": true,
            "hasMore": true,
            "parent": "allTransaction",
            "filterKey": "pereipo",
            "category": "investoripo",
        }
    ],
   
}

const maAcquirerProfileExportData = {

     
    ...data, initial: [
        {
            "id": "1",
            "name": "Acquirer Profile",
            "fileName":"Acquirer Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
       
        {
            "id": "2",
            "name": "Acquirer Profile  + M&A Transactions",
            "fileName":"All",
            "isChecked": true, 
            "isTransaction": true,         
            "parent": "initial",           
            "withDetails": true,
        }
    ],


}

const maAdvisorProfileExportData = {

     
    ...data, initial: [
        {
            "id": "1",
            "name": "Legal Advisor Profile",
            "fileName":"legal Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
       
        {
            "id": "2",
            "name": "Legal Advisor Profile  + M&A Transactions",
            "fileName":"All",
            "isChecked": true, 
            "isTransaction": true,         
            "parent": "initial",           
            "withDetails": true,
        }
    ],


}

const maTAdvisorProfileExportData = {

     
    ...data, initial: [
        {
            "id": "1",
            "name": "Transaction Advisor Profile",
            "fileName":"Transaction Advisor Profile",
            "isChecked": false,
            "hasMore": false,
            "parent": "initial",
            "isTransaction": false,
            "withDetails": false,

        },
       
        {
            "id": "2",
            "name": "Transaction Advisor Profile  + M&A Transactions",
            "fileName":"All",
            "isChecked": true,
            "isTransaction": true,
           
            "parent": "initial",           
            "withDetails": true,
        }
    ],


}

export {
    data,
    exitExportData,
    backedIPOExportData,
    angelInvestmentExportData,
    incubationExportData,
    vcInvestmentExportData,
    vcExitExportData,
    vcbackedIPOExportData,
    vcangelInvestmentExportData,
    vcincubationExportData,
    investorProfileExportData,
    investorbackedIPOExportData,
    investorincubationExportData,
    investorexitExportData,
    investorAngelInvestmentExportData,
    vcinvestorProfileExportData,
    vcinvestorexitExportData,
    vcinvestorbackedIPOExportData,
    vcinvestorAngelInvestmentExportData,
    vcinvestorincubationExportData,
    advisorProfileExportData,
    advisorexitExportData,
    transactionAdvisorProfileExportData,
    transactionAdvisorexitExportData,
    vcadvisorexitExportData,
    vcadvisorProfileExportData,
    vctransactionAdvisorexitExportData,
    vctransactionAdvisorProfileExportData,
    fundsExportData,
    limitedPartnerProfileExportData,
    vclimitedPartnerProfileExportData,
    limitedPartnerexitExportData,
    vclimitedPartnerexitExportData,
    reInvestmentExportData,
    reExitExportData,
    reOtherMaExportData,
    reBackedIpoExportData,
    reAdvisorInvestmentsExportData,
    reAdvisorExitExportData,
    reAdvisorOtherMAExportData,
    reTransactionAdvisorInvestmentsExportData,
    reTransactionAdvisorExitExportData,
    reTransactionAdvisorOtherMAExportData,
    investorInvestmentExportData,
    investorExitExportData,
    investorIpoExportData,
    maAdvisorProfileExportData,
    maTAdvisorProfileExportData,
    maAcquirerProfileExportData
}