import React, { useContext } from "react";
import Loader from "../../Loader";
import { TableHeaderContext } from "../../../Contexts/TableHeaderContext";

/**
 * The function `InvestorIncubationProfileHighlight` renders a profile highlight section for an
 * investor incubation profile, displaying profile information and handling loading states.
 * @returns The code is returning a JSX element, which represents the structure and content of a React
 * component.
 */
const InvestorIncubationProfileHighlight = ({ profile,isLoading }) => {

  const { incubationProfileKeys } = useContext(TableHeaderContext); 
  
  return (
    <div>
      <div className="space-y-6 px-4 bg-[#EDEDED]">
        <div className="">
          <div
            className="lg:mt-4 lg:bg-white  rounded-[6px]"
            x-show="tab === 1"
          >
            <div className="w-full lg:grid lg:grid-cols-6  shadow-[0_2px_6px_0_rgba(0,0,0,0.08)]">
              <div className=" mt-3 lg:mt-0 rounded-lg lg:rounded lg:col-span-6 lg:border-r bg-white ">
                <div className="px-5 py-4 ">
                  <p className=" text-[14pt] leading-[19pt] lg:text-[17px] text-[#333333] font-semibold lg:leading-[22px]">
                    Profile
                  </p>
                  {!isLoading && profile ? (
                    <div className="grid grid-cols-4 ">
                      {incubationProfileKeys?.map((keyObj, index) => {
                        const key = keyObj.accessor;
                        const label = keyObj.label;
                        let valueToRender = null;
                        
                        if(key === "email" || key === "website"){
                            valueToRender = profile[key] ? profile[key] : "--"
                        } else valueToRender = profile[key] && profile[key].trim() !== ""
                            ? profile[key]
                            : null;
                        if (valueToRender !== null) {
                          return (
                            <div key={index} className={`profiletable`}>
                              <>
                                <div className="leading-[21px] font-sans_book_body mt-1">
                                  <div class=" block lg:overflow-x-clip lg:overflow-ellipsis lg:whitespace-nowrap md:max-w-[14rem]">
                                    <span
                                      className="py-1 text-[10pt] lg:whitespace-nowrap leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto"
                                      readOnly
                                    >
                                      {label}
                                    </span>
                                  </div>
                                  {label === "Website" ? (
                                    <a
                                      href={valueToRender}
                                      className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-pointer font-semibold"
                                      target="_blank"
                                    >
                                      {valueToRender}
                                    </a>
                                  ) : (
                                    <p
                                      className="mt-1 mb-2 text-[10pt] leading-[18pt] lg:text-[13px] text-[#333333] cursor-auto font-semibold"
                                      readOnly
                                    >
                                      {valueToRender}
                                    </p>
                                  )}
                                </div>
                              </>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  ) : (
                    <div className="h-[10rem] w-full bg-[#FFFFFF] flex items-center justify-center ">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorIncubationProfileHighlight;
