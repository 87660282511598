import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../Contexts/UserContext";
import { getNotification } from "../Config/config";
import Constants from "../Constants";
import Loader from "./Loader";
import { useLocation } from "react-router";

const AppInitializationWrapper = ({ children }) => {
  const { getToken, logout: originalLogout , isInitialized, setIsInitialized } = useContext(UserContext);

  // Wrap the original logout function to also set isInitialized
  const logout = () => {
    originalLogout();
    
  };

  const location = useLocation();

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const response = await fetch(`${getNotification}`, {
          method: "POST",
          body: JSON.stringify({}),
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
        });

        if (!response.ok) {
          // Check if the response status requires logout
          const errorStatus = response.status;
          if (
            errorStatus === Constants.sessionTerminatedStatus ||
            errorStatus === Constants.InvalidTokenStatus
          ) {
            logout();
            return;
          }
          throw new Error("Initialization failed");
        }

        setIsInitialized(true);
      } catch (error) {
        console.error("Unexpected error during initialization:", error);
        logout();
      }
    };
  if(!location.pathname.includes("/password/reset")){
    initializeApp();
  }
  }, []);

  useEffect(()=>{
    if(location.pathname.includes("/login") || location.pathname.includes("/password/reset") && !isInitialized){
      setIsInitialized(true);
    }
  },[location.pathname]);

  if (!isInitialized) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "1.5rem",
          color: "#555",
        }}
      >
        <Loader />
      </div>
    );
  }

  return <>{children}</>;
};

export default AppInitializationWrapper;

