import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { TableDataContext } from '../../Contexts/TableDataContext';
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { routeNames } from "../../routeSegments";
import { Tab } from "./Investor/components/Tabbar";

function MainMenu(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setActiveTabDeal, setCurrentPage, setActiveTabs } = useContext(TableDataContext);
  const { refineSearchFilters } = useContext(PeFilterContext);
  const [openTab, setOpenTab] = React.useState(1);  

  // Dynamic page title setting
  if(props.menu === "re"){
    document.title = props.subMenu === "re-investment"
      ? "RE Companies PE-RE Investments - Venture Intelligence"
      : props.subMenu === "re-exits"
      ? "RE Companies PE-RE Exits via M&A - Venture Intelligence"
      : props.subMenu === "re-otherma"
      ? "RE Companies Other M&A- Venture Intelligence"
      : props.subMenu === "re-ipos"
      ? "RE Companies PE-RE Backed IPOs - Venture Intelligence"
      : "RE Companies - Venture Intelligence";
  }

  // Tab activation logic
  const activateTab = (tabName) => {
    setActiveTabDeal(tabName);
    setActiveTabs(tabName);
  };

  useEffect(() => {
    switch(props.active) {
      case 1:
        activateTab('re-investments');
        break;
      case 2:
        activateTab('re-exits');
        break;
      case 3:
        activateTab('other-ma');
        break;
      case 4:
        activateTab('re-ipo');
        break;
      default:
        break;
    }
    setOpenTab(props.active);
  }, [props.active]);

  // Navigation handler to prevent full page reload
  const onTabChange = (to) => {
    const isCurrentUrl = matchPath(to, location.pathname);
    
    if (isCurrentUrl == null) {
      setCurrentPage(1);
      navigate(to, { replace: false }); // Use navigate instead of changing URL directly
    }
  };

  const isFromGlobalSearch = () => 
    refineSearchFilters[location.pathname]?.globalSearch?.length > 0;

  // Render tabs based on menu type
  const renderTabs = () => {
    switch(props.menu) {
      case "l-advisor":
        return (
          <div className='flex items-center space-x-2'>
            <Tab onChange={onTabChange} label="PE-RE Investments" to={routeNames.re_advisors_re_investments} />
            <Tab onChange={onTabChange} label="PE-RE Exits via M&A" to={routeNames.re_advisors_re_exits} />
            <Tab onChange={onTabChange} label="PE-RE Other M&A" to={routeNames.re_advisors_other_ma} />
          </div>
        );
      
      case "t-advisor":
        return (
          <div className='flex items-center space-x-2'>
            <Tab onChange={onTabChange} label="PE-RE Investments" to={routeNames.re_transaction_advisors_re_investments} />
            <Tab onChange={onTabChange} label="PE-RE Exits via M&A" to={routeNames.re_transaction_advisors_re_exits} />
            <Tab onChange={onTabChange} label="PE-RE Other M&A" to={routeNames.re_transaction_advisors_other_ma} />
          </div>
        );
      
      case "re":
        return (
          <div className='flex items-center space-x-2'>
            <Tab onChange={onTabChange} label="PE-RE Investments" to={routeNames.re_companies_re_investments} />
            <Tab onChange={onTabChange} label="PE-RE Exits via M&A" to={routeNames.re_companies_re_exits} />
            {!isFromGlobalSearch() && (
              <Tab 
                onChange={onTabChange} 
                label="Other M&A" 
                to={routeNames.re_companies_other_ma} 
              />
            )}
            <Tab onChange={onTabChange} label="PE-RE Backed IPOs" to={routeNames.re_companies_re_ipo} />
          </div>
        );
      
      default:
        return null;
    }
  };

  return (
    <div className="overflow-x-auto overflow-y-hidden">
      <div className="scrollbar">
        <div className="px-2 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between">
          {renderTabs()}
        </div>          
      </div>
    </div>
  );
}

export default MainMenu;