import { Badge } from 'antd';
import React, { useContext, useEffect, useState,useRef } from 'react'
import { useLocation } from 'react-router';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import MultiSelectEntity from '../RefineSearch/TgMultiSelectEntity'
import FilterWrapper from './FilterWrapper';
import { filterInvestor, FilterReInvestor, FilterIncubator } from "../../Config/config"
import useScrollIntoView from './useScrollIntoView';

function Investor({ open, accordionClick, options, setOptions, customPathname, label }) {

    const [companyVal, setCompanyVal] = useState("");
    const [fetchedData, setFetchedData] = useState([]);
    const shouldScroll = open || fetchedData.length > 0;
    const listItemRef = useScrollIntoView(shouldScroll);

    useEffect(() => {
        if (companyVal !== "") {
            fetchData();
        }
    }, []);

    
    let categoryVal;

    if (
    customPathname?.includes("investors/pe-vc-investments") ||
    customPathname?.includes("companies/pe-vc-investments") ||
    customPathname?.includes("advisors/pe-vc-advisors") ||
    customPathname?.includes("transaction-advisors/pe-vc-advisors") ||
    customPathname?.includes("companies/vc-investments") ||
    customPathname?.includes("investors/vc-investments") ||
    customPathname?.includes("advisors/vc-advisors") ||
    customPathname?.includes("transaction-advisors/vc-advisors")
    ) {
    categoryVal = "investments";
    } else if (
    customPathname?.includes("exits/pe-vc-investments") ||
    customPathname?.includes("exits/vc-investments") ||
    customPathname?.includes("advisors/ma-exits/pe-vc-advisors") ||
    customPathname?.includes("transaction-advisors/ma-exits/pe-vc-advisors") ||
    customPathname?.includes("companies/exits/vc-investments") ||
    customPathname?.includes("investors/exits/vc-investments") ||
    customPathname?.includes("advisors/ma-exits/vc-advisors") ||
    customPathname?.includes("transaction-advisors/vc-advisors")
    ) {
    categoryVal = "exits";
    } else if (customPathname?.includes("backed-ipos")) {
    categoryVal = "ipo";
    } else if (customPathname?.includes("angel-invesments")) {
    categoryVal = "angel";
    }


console.log(customPathname , "hkjhkghkh" ,categoryVal)

const fetchData = () => {
    const payload = {
        search_key: companyVal,
        category : categoryVal
    };
    
    const filter = customPathname.includes("/re/") ? FilterReInvestor : customPathname.includes("/incubation") ? FilterIncubator : filterInvestor;
    
    fetch(filter, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data?.results) {
                    const modifiedData = data.results.map(item => ({
                        ...item,
                        isChecked: false
                    }));
                    // const filteredData = modifiedData.filter(item => !options.some(option => option.id === item.id));
                    setFetchedData(data?.results); 
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        const uniqueFetchedData = fetchedData;
                setOptions(prevOptions => [...prevOptions.filter(item => item.isChecked === true), ...uniqueFetchedData]);
    }, [fetchedData]);
    

    const handleIndustryChange = ((e) => {
        const { name, checked, id } = e.target;
        let currentIndustry = [];

        if (name === "allSelect") {
            currentIndustry = options.map((industry) =>
                ({ ...industry, isChecked: checked })
            );
            setOptions(currentIndustry);
        } else {
            currentIndustry = options.map((industry) =>
                industry.id === parseInt(id) ? { ...industry, isChecked: checked } : industry
            );
            setOptions(currentIndustry);
        }
    });




    const selectedIndustryLength = options?.filter(option => option.isChecked)?.length ?? 0
    return (
        <div className="border-b-[3px] border-[#E8E6E1]">
            <li className={open ? "bg-white relative px-3 py-1" : "relative px-3 py-1"}>
                <FilterWrapper
                    count={selectedIndustryLength}
                    isSelect={false}
                    label={label}
                    onClick={accordionClick}
                    open={open}
                    customPathname={customPathname}
                />
                <div
                    className="relative overflow-hidden transition-all max-h-0 duration-700  overflow-y-auto no-scrollbar"
                    style={{ maxHeight: open ? "10rem" : "" }}
                >
                    {open === true ? <MultiSelectEntity
                        fetchData={fetchData}
                        handleEntityChange={handleIndustryChange}
                        primaryEntity={options}
                        setCompanyVal={setCompanyVal}
                        city="city"
                    /> : <></>}
                </div>
            </li>
        </div>
    )
}

export default Investor;