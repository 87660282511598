import axios from 'axios';
import { React, useState, createContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUrl, logoutUrl, forgotPasswordUrl, resetPasswordUrl } from '../Config/config';
import Constants from '../Constants';
import { routeNames } from '../routeSegments';


/**
 * The UserContext is a context that provides user authentication and authorization
 * functionality to its child components.
 */
export const UserContext = createContext({ name: '', isAuth: false });

/**
 * The UserProvider function is a component that provides user authentication and authorization
 * functionality to its child components.
 * @returns The UserProvider component is being returned.
 */
const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ name: 'aa', isAuth: true });
  const [isLogging, setIsLogging] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const redirectTo = useNavigate();
  const location = useLocation();
  const [isStudent,setIsStudent] = useState();
  const [isTrialExport,setIsTrialExport] = useState();
  const [isDirectoryUser, setIsDirectoryUser] = useState(false);


/**
 * The `login` function is an asynchronous function that takes in an email, password, and
 * selectedProduct as parameters and sends a POST request to a login URL with the provided data,
 * handling the response accordingly.
 * @param email - The email parameter is the email address entered by the user for login.
 * @param password - The password parameter is the user's password that they entered during the login
 * process.
 * @param selectedProduct - The `selectedProduct` parameter is a string that represents the product
 * from which the login is being performed. It is used to specify the source of the login request.
 * @returns The function `login` returns a Promise that resolves to the response data from the server.
 */
  const login = async (email, password, selectedProduct) => {
    try {
      setIsLogging(true);
      const response = await axios.post(loginUrl, {
        "email": email,
        "password": password,
        "loginFrom": selectedProduct
      });
      setIsLogging(false);

      if (response.status === 200) {
        if (response.data.success === true && response.data?.data?.show === true) {
          return response.data;
        }
        if (response.data.success === true) {
          if(response.data?.data?.showAuthorize != true){
            localStorage.setItem(Constants.localStorageKeys.loggedInEmail, email)
            localStorage.setItem(Constants.localStorageKeys.accessToken, response.data.data.access_token)
            localStorage.setItem(Constants.localStorageKeys.userId, response.data.data.user_id)
            localStorage.setItem('csrf_token', response.data.data.csrf_token)
            localStorage.setItem(Constants.localStorageKeys.allowedModules, JSON.stringify(response.data.data.allowed_modules))
            localStorage.setItem(Constants.localStorageKeys.studentLogin, response.data.data.studentLogin)
            localStorage.setItem(Constants.localStorageKeys.isExport, response.data.data.isExport)
            localStorage.setItem(Constants.localStorageKeys.isDirectoryUser, response.data.data.isDirectoryUser);
            setUser((user) => ({
              name: email,
              isAuth: true,
            }));
          }
          return response.data
        } else {
          return response.data;
        }
      } else {
        return response.data;
      }
    } catch (error) {
      setIsLogging(false);
      console.log("login-response", error);
      return error.response.data;
    }

  };

 /**
  * The `forgotPassword` function is an asynchronous function that sends a POST request to a specified
  * URL with an email parameter, and returns the response data or an error message.
  * @param email - The email parameter is the email address of the user who wants to reset their
  * password.
  * @returns The function `forgotPassword` returns either the response data from the API call (if the
  * response status is true) or the response data from the API call (if the response status is false).
  * If there is an error, it returns the error response data.
  */
  const forgotPassword = async (email) => {
    try {
      setIsLogging(true);
      const response = await axios.post(forgotPasswordUrl, {
        "email": email,        
      });
      setIsLogging(false);

      if (response.status === true) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      setIsLogging(false);
      return error.response.data;
    }

  };

 /**
  * The function `resetPassword` is an asynchronous function that takes in an email, new password,
  * confirm password, and token as parameters, and makes a POST request to a reset password URL with
  * the provided data.
  * @param email - The email parameter is the email address of the user who wants to reset their
  * password.
  * @param newPassword - The `newPassword` parameter is the new password that the user wants to set for
  * their account.
  * @param confirmPassword - The `confirmPassword` parameter is the user's input for confirming their
  * new password. It is used to ensure that the user has entered the same password in both the
  * `newPassword` and `confirmPassword` fields.
  * @param token - The `token` parameter is a unique token that is generated and sent to the user's
  * email when they request a password reset. This token is used to verify the user's identity and
  * ensure that the password reset request is legitimate.
  * @returns The function `resetPassword` returns either the response data from the API call (if the
  * response status is true) or the response data itself. If there is an error, it returns the error
  * response data.
  */
  const resetPassword = async (email, newPassword,confirmPassword, token) => {
    try {
      setIsLogging(true);
      const response = await axios.post(resetPasswordUrl, {
        "email": email,    
        "password":newPassword,
        "password_confirmation":confirmPassword,
        "token":token    
      });
      setIsLogging(false);

      if (response.status === true) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      setIsLogging(false);
      return error.response.data;
    }

  };

  const setAccessData = ({ email, accessToken, userId, allowedModules }) => {
    localStorage.setItem(Constants.localStorageKeys.loggedInEmail, email)
    localStorage.setItem(Constants.localStorageKeys.accessToken, accessToken)
    localStorage.setItem(Constants.localStorageKeys.userId, userId)
    localStorage.setItem(Constants.localStorageKeys.allowedModules, JSON.stringify(allowedModules))
  }

 /**
  * The above function is an asynchronous function that logs out the user by sending a POST request to
  * a logout URL with the user's token in the authorization header, and then clears the local storage
  * and redirects the user to the login page.
  */
  const logout = async () => {
    // console.log("getTokengetTokengetToken", getToken());
    // localStorage.clear();
    axios.post(logoutUrl, {}, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      }
    }).then(response => {
      if (response.status === 200) {
        localStorage.clear();
        setUser((user) => ({
          name: '',
          isAuth: false,
        }));
        redirectTo(routeNames.login, { state: { from: location.pathname } })
        setIsInitialized(true);
      } else {
        toast.error(response.data?.message)
      }
    }).catch((error) => {
      localStorage.clear();
      setUser((user) => ({
        name: '',
        isAuth: false,
      }));
      redirectTo(routeNames.login, { state: { from: location.pathname } })
      return error?.response?.data
    });
  };

/**
 * The function checks if a user is logged in by retrieving a token from local storage and returning
 * true if the token exists, otherwise it returns false.
 * @returns The function `isLoggedIn` returns a boolean value. It returns `true` if there is a token
 * stored in the local storage, and `false` if there is no token.
 */
  const isLoggedIn = () => {
    const token = localStorage.getItem(Constants.localStorageKeys.accessToken);
    if (token != null) {
      return true;
    } else {
      return false;
    }
  }

 /**
  * The function `getAllowedModules` retrieves and parses a value from the local storage, representing
  * a list of allowed modules.
  * @returns The function `getAllowedModules` returns the parsed value of the `allowedModules` item
  * from the localStorage.
  */
  const getAllowedModules = () => {
    const allowedModules = localStorage.getItem(Constants.localStorageKeys.allowedModules)
    return JSON.parse(allowedModules);
  }

 /**
  * The function `getToken` retrieves an access token from local storage and returns it in the format
  * "Bearer {token}".
  * @returns The function `getToken` returns the access token stored in the local storage, prefixed
  * with "Bearer". If the token is not found in the local storage, it returns null.
  */
  const getToken = () => {
    const token = localStorage.getItem(Constants.localStorageKeys.accessToken);
    // console.log(token,'jvbcducvuicf');
    if (token == null) {
      return null;
    }
    return `Bearer ${token}`;
  }

/**
 * The function `getUser` retrieves the email and id of a logged-in user from local storage.
 * @returns The `getUser` function is returning an object with two properties: `email` and `id`. The
 * values of these properties are retrieved from the local storage using the keys
 * `Constants.localStorageKeys.loggedInEmail` and `Constants.localStorageKeys.userId` respectively.
 */
  const getUser = () => {
    return {
      email: localStorage.getItem(Constants.localStorageKeys.loggedInEmail),
      id:localStorage.getItem(Constants.localStorageKeys.userId)
    }
  }
  // const isStudentLogged = () => {
  //   const Student = localStorage.getItem(Constants.localStorageKeys.studentLogin)
  //   // if (isStudent == null) {
  //   //   return false;
  //   // } 
  //   return Student; 
  // }

  // useEffect(()=>{
  //   const Student = localStorage.getItem(Constants.localStorageKeys.studentLogin)
  //   setIsStudent(Student)
  // },[isStudent])


  const userDetail = { user, login, logout, forgotPassword,resetPassword, isLoggedIn,isInitialized, setIsInitialized, isLogging, getAllowedModules, getToken, setAccessData, getUser,isStudent,setIsStudent,isTrialExport,setIsTrialExport, isDirectoryUser, setIsDirectoryUser }

  return (
    <UserContext.Provider value={userDetail}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
